import React from 'react'
import {
  BaseComponent,
  IComponent,
  IComponentTemplate,
  IToast,
  Icon,
  IconType,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import { h4TextPrefab } from '../Texts/Header4TextPrefab'
import { MS_StatusToastTemplate, MS_StatusWrapperToastTemplate } from '../Templates'

export interface IStatusToastPrefab extends IComponent {
  text?: string
  desktopView?: boolean
  status: 'positive' | 'neutral' | 'negative'
}

export const StatusToastPrefab = (props: IStatusToastPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { theme = 'Light', brand, text, status, desktopView = true } = props

  // ************************************
  // Helper Functionality
  // ************************************

  const _getStatusTemplate = (): IComponentTemplate => {
    return MS_StatusWrapperToastTemplate(theme, status, desktopView)
  }

  const _getStatusIcon = (): IconType => {
    switch (status) {
      case 'negative':
        return IconType.WarningBadge
      case 'neutral':
        return IconType.BellIcon
      case 'positive':
        return IconType.CheckmarkThick
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderText = () => {
    if (text) {
      return (
        <Text
          {...h4TextPrefab()}
          brand={brand}
          theme={theme}
          palette={_getStatusTemplate().palette}
        >
          {text}
        </Text>
      )
    }
  }

  const _renderIcon = () => {
    return (
      <Icon
        type={_getStatusIcon()}
        palette={_getStatusTemplate().palette}
        width={1}
        height={1}
        brand={brand}
      />
    )
  }

  const _renderTopSection = () => {
    if (text && status) {
      return (
        <BaseComponent template={MS_StatusWrapperToastTemplate(theme, status, desktopView)}>
          {_renderIcon()}
          {_renderText()}
        </BaseComponent>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    ...props,
    ...{
      template: MS_StatusToastTemplate(theme, desktopView),
      content: {
        top: _renderTopSection()
      }
    }
  } as IToast
}
