import React, { useState } from 'react'
import { IPlankHouse, PlankHouseBlock } from '../../../blocks'
import { getPageContent } from './GuestAdminPageData'
import { Constants } from '../../../data'
import { GuestAdminPopup, IGuestAdminPopupData } from '../../../modals'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import { useSearchParams } from 'react-router-dom'
import {
  Accordion,
  BrandColors,
  ContentGrid,
  getCounterTheme,
  Icon,
  IconType,
  StyleGrid,
  Text,
  Theme
} from '@fjordkraft/fjordkraft.component.library'
import { h4TextPrefab, MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs'
import { ActionButton, ICustomization } from '../../../components'
import { ICustomer, IGuestRelationship } from '../../../models'
import { useApplicationDefaultContext, useApplicationOverlayWrapperContext } from '../../../contexts'
import { getTextV2 } from '../../../services'
import './GuestAdminPage.scss'
import { useNavigate } from 'react-router'

export interface IGuestAdminPage extends IDefaultViewProps {
  house: IPlankHouse
  hosts: IGuestRelationship[]
}

export const GuestAdminPage = () => {
  // ************************************
  // Lifecycle
  // ************************************

  const [popupData, setPopupData] = useState<IGuestAdminPopupData>()
  const [queryParams, setQueryParams] = useSearchParams()

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.guests.type,
      usesSubPage: true,
      pageClamp: 'clamp'
    },
    dependenciesOptional: [{ popupData }, { queryParams }],
    dependencies: [{ setPopupData }],
    handleData: getPageContent,
    render: (config: IGuestAdminPage) => {
      const { house, activeBrand: brand, activeTheme: theme, user, translations, hosts } = config
      const { mainUser } = user

      return (
        <>
          <PlankHouseBlock
            {...house}
            theme={theme}
            brand={brand}
          />
          {hosts.length > 0 && (
            <StyleGrid
              direction={'column'}
              alignment={'center-left'}
              gap={1}
              className={'wrapper'}
            >
              <Text
                {...h4TextPrefab()}
                brand={brand}
                theme={getCounterTheme(theme)}
                {...{ weight: 500, faded: true }}
              >
                {getTextV2({ translations, key: 'plankWallGuestOfTitle' })}
              </Text>
              <Accordion
                theme={theme}
                brand={brand}
                className={'accordion-plank'}
                elements={
                  hosts?.map(host => ({
                    theme: theme,
                    brand: brand,
                    leftTitleContent: (
                      <StyleGrid
                        direction={'column'}
                        alignment={'center-left'}
                      >
                        <Text
                          {...paragraphTextPrefab()}
                          theme={getCounterTheme(theme)}
                          brand={brand}
                          weight={500}
                        >
                          {host.firstName} {host.lastName}
                        </Text>
                        <StyleGrid
                          direction={'column'}
                          alignment={'center-left'}
                        >
                          {host.addresses.map(adress => (
                            <Text
                              {...paragraphTextPrefab()}
                              theme={getCounterTheme(theme)}
                              brand={brand}
                            >
                              {adress.streetAddress}
                            </Text>
                          ))}
                        </StyleGrid>
                      </StyleGrid>
                    ),
                    content: (
                      <GuestWithContent
                        userData={host}
                        mainUser={mainUser}
                        translations={translations}
                        activeTheme={theme}
                        setPopupData={setPopupData}
                      />
                    )
                  })) ?? []
                }
              />
            </StyleGrid>
          )}
          {popupData && (
            <GuestAdminPopup
              {...popupData}
              onClose={() => {
                setQueryParams(undefined)
                setPopupData(undefined)
              }}
              theme={theme}
              brand={brand}
            />
          )}
        </>
      )
    }
  })
}

interface IGuestWithContent {
  mainUser: ICustomer
  translations: any
  activeTheme: Theme
  userData: IGuestRelationship
  setPopupData: (value: IGuestAdminPopupData) => void
}

const GuestWithContent = ({ mainUser, translations, activeTheme, userData, setPopupData }: IGuestWithContent) => {
  const buttonCustomization = {
    text: {
      weight: 400,
      underline: true
    },
    icon: {
      width: 2,
      height: 2
    }
  } as ICustomization
  const { setGlobalLoading } = useApplicationOverlayWrapperContext()

  const { defaultProps } = useApplicationDefaultContext()
  const { setHostIdForCustomerDataRequests, setChosenHost } = defaultProps.relationship
  const navigate = useNavigate()

  return (
    <ContentGrid
      tagType={'section'}
      direction={'row'}
      alignment={'center'}
      className={'accordioncontent'}
    >
      <ActionButton
        customization={buttonCustomization}
        template={MS_ButtonTemplate(activeTheme, 'guestLink')}
        iconPlacement={'Right'}
        action={{
          text: getTextV2({ translations, key: 'logInAsGuest' }),
          icon: IconType.Profile,
          onClick: () => {
            const chosenUser = userData
            setGlobalLoading(true)
            if (mainUser && mainUser.customerId !== chosenUser.customerId) {
              setChosenHost(chosenUser)
              setHostIdForCustomerDataRequests(chosenUser.customerId)
            } else {
              setChosenHost(undefined)
              setHostIdForCustomerDataRequests(undefined)
            }
            navigate('/')
          }
        }}
      />
      <Icon
        className={'rotate-icon'}
        type={IconType.SolidLine}
      />
      <ActionButton
        customization={{
          ...buttonCustomization,
          icon: { width: 1.5, height: 1.5, color: BrandColors['status-shade-light-3'] }
        }}
        template={MS_ButtonTemplate(activeTheme, 'guestLink')}
        iconPlacement={'Right'}
        action={{
          text: getTextV2({ translations, key: 'deleteGuestRelationship' }),
          icon: IconType.TrashFilled,
          onClick: () => {
            setPopupData({
              purpose: 'remove-host',
              translations,
              selectedUser: userData
            })
          }
        }}
      />
    </ContentGrid>
  )
}
