import { BrandColors, IMasterPlankTemplate, LocationType, Theme } from '@fjordkraft/fjordkraft.component.library'
import { PointPlankTemplate } from './PointPlankTemplate'

export const PointPlankTemplateNegative = (theme: Theme, location: LocationType) => {
  let temp: IMasterPlankTemplate = structuredClone(PointPlankTemplate(theme, location))

  if (temp.palette.text) {
    temp.palette.text.color = BrandColors['text-shade-dark-3']
  }

  return temp
}

export const PointPlankTemplatePositive = (theme: Theme, location: LocationType) => {
  let temp: IMasterPlankTemplate = structuredClone(PointPlankTemplate(theme, location))

  if (temp.palette.text) {
    temp.palette.text.color = BrandColors['text-shade-dark-3']
  }

  return temp
}
