import {
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  width: '14rem',
  height: 'auto',
  padding: '0.5rem',
  grid: {
    boxSizing: 'border-box',
    direction: 'column',
    alignment: 'top-left',
    wrap: false
  }
} as IComponentTransform

const _transformButton = {
  width: 'auto',
  height: 'auto',
  outline: {
    type: 'dashed',
    thickness: 2
  },
  grid: {
    direction: 'row',
    alignment: 'center',
    gap: 1,
    boxSizing: 'border-box'
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  Light: {
    background: { color: BrandColors['background-shade-light-1'] },
    icon: { color: BrandColors['action-shade-light-1'] },
    text: { color: BrandColors['text-shade-dark-3'] }
  },
  Dark: {
    background: { color: BrandColors['background-shade-dark-3'] },
    icon: { color: BrandColors['action-shade-light-1'] },
    text: { color: BrandColors['text-shade-light-1'] }
  }
} as IThemedColors

const _paletteButton = {
  Light: {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['text-shade-dark-3'] },
    icon: { color: BrandColors['action-shade-light-1'] },
    hover: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-dark-1'], opacity: 0.8 },
      icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 }
    },
    active: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      icon: { color: BrandColors['action-shade-light-1'] }
    },
    activeHover: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
      icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 }
    },
    focus: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
      icon: { color: BrandColors['action-shade-light-1'], opacity: 1 },
      outline: { color: BrandColors['primary-shade-light-1'] }
    }
  },
  Dark: {
    background: { color: BrandColors['transparent'] },
    text: { color: BrandColors['text-shade-light-1'] },
    icon: { color: BrandColors['primary-shade-light-2'] },
    hover: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
      icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 }
    },
    active: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-light-1'] },
      icon: { color: BrandColors['primary-shade-light-2'] }
    },
    activeHover: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-light-1'], opacity: 0.8 },
      icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 }
    },
    focus: {
      background: { color: BrandColors['transparent'] },
      text: { color: BrandColors['text-shade-light-1'], opacity: 1 },
      icon: { color: BrandColors['primary-shade-light-2'], opacity: 1 },
      outline: { color: BrandColors['primary-shade-light-1'] }
    }
  }
} as IThemedColors

// ************************************
// Export
// ************************************

export const MSTooltipTemplate = (theme: Theme) => {
  return {
    palette: _palette[theme],
    transform: _transform
  } as IComponentTemplate
}

export const MSTooltipButtonTemplate = (theme: Theme) => {
  return {
    palette: _paletteButton[theme],
    transform: _transformButton
  } as IComponentTemplate
}
