import { BrandColors } from '@fjordkraft/fjordkraft.component.library'

export const BUTTON_TEMPLATE_CTA_SECONDARY = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['border-shade-light-2'] },
      icon: { color: BrandColors['text-shade-dark-3'] },
      hover: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      focus: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.3
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        border: { color: BrandColors['border-shade-light-1'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 }
      }
    },
    Dark: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['border-shade-light-2'] },
      icon: { color: BrandColors['text-shade-dark-3'] },
      hover: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      focus: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.3
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        border: { color: BrandColors['border-shade-light-1'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 }
      }
    }
  },
  'brand-trondelagkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['border-shade-light-2'] },
      icon: { color: BrandColors['text-shade-dark-3'] },
      hover: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      focus: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.3
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        border: { color: BrandColors['border-shade-light-1'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 }
      }
    },
    Dark: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['border-shade-light-2'] },
      icon: { color: BrandColors['text-shade-dark-3'] },
      hover: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      focus: {
        background: { color: BrandColors['border-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['border-shade-light-2'] },
        icon: { color: BrandColors['text-shade-light-1'] },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.3
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        border: { color: BrandColors['border-shade-light-1'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 }
      }
    }
  }
}
