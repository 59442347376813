import React, { useState, useMemo } from 'react'
import {
  AreaText,
  AreaTextTemplate,
  CallState,
  ContentGrid,
  getCounterTheme,
  IAction,
  IComponent,
  IconType
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { MS_ButtonTemplate } from '../../Prefabs'
import { ActionButton, MSRichText } from '../../components'
import { SidePopupCard } from '../SidePopupCard'
import { PopupCard } from '../PopupCard/PopupCard'
import { useApplicationServicehandlerContext } from '../../contexts'
import { ITranslationItem } from '../../models'
import { getTextV2 } from '../../services'
import './CustomerEffortScoreModal.scss'

export interface ICustomerEffortScore extends IComponent {
  featureId?: string
  desktopView: boolean
  additionalTexts: ITranslationItem[]
  bottomText: string
  topText: string
  title: string
  onClose: () => void
  onReviewComplete: () => void
}

type RateChoice = 'none' | 'sad' | 'neutral' | 'happy'

export const CustomerEffortScoreModal = (props: ICustomerEffortScore) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    featureId,
    className,
    theme = 'Light',
    brand,
    desktopView,
    additionalTexts,
    bottomText,
    topText,
    title,
    onClose,
    onReviewComplete
  } = props
  const classPrefix = 'userData-effort-score'
  const maxFieldLength = 500
  const { POST } = useApplicationServicehandlerContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [selectedChoice, setSelectedChoice] = useState<RateChoice>('none')
  const [postState, setPostState] = useState<CallState>('idle')

  // ************************************
  // Helper functionality
  // ************************************

  const _getText = (key: string) => {
    return getTextV2({
      key,
      textsKey: 'additionalTexts',
      translations: { additionalTexts }
    })
  }

  const _getPrimaryButtonData = useMemo(() => {
    if (postState === 'idle') {
      return {
        onClick: () => {
          _onPostReview()
        },
        text: _getText('sendButton'),
        disabled: selectedChoice === 'none'
      } as IAction
    }
  }, [selectedChoice, postState])

  const _getSecondaryButtonData = useMemo(() => {
    return {
      text: _getText('closeButton'),
      onClick: () => {
        if (postState === 'success') {
          onReviewComplete()
        } else {
          onClose()
        }
      }
    }
  }, [postState])

  const _getTitle = () => {
    if (postState === 'success') {
      return _getText('successTitle')
    } else if (postState === 'error') {
      return _getText('errorTitle')
    }

    return title
  }

  const _getTopText = () => {
    if (postState === 'success') {
      return _getText('successDescription')
    } else if (postState === 'error') {
      return _getText('errorDescription')
    }

    return topText
  }

  const _getScoreIndex = (): number => {
    switch (selectedChoice) {
      case 'happy':
        return 2
      case 'neutral':
        return 1
      case 'sad':
        return 0
      default:
        return -1
    }
  }

  // ************************************
  // Async Functionality
  // ************************************

  const _onPostReview = async () => {
    if (postState === 'idle' && _getScoreIndex() > -1) {
      let comment: any = document.getElementById(`${classPrefix}__area-text`)

      if (comment) {
        setPostState('pending')

        let resp = await POST(`CustomerEffortScore/${featureId}/${_getScoreIndex()}`, {
          comment: comment.value
        })

        setPostState(resp.callState)
      }
    }
  }

  // ************************************
  // Template customization
  // ************************************

  const _customizeTextAreaTemplate = () => {
    let base = AreaTextTemplate(theme)
    base.transform.height = '5rem'
    return base
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderRatingAction = (rating: RateChoice, icon: IconType) => {
    return (
      <ActionButton
        template={MS_ButtonTemplate(theme, 'rate')}
        action={{
          active: selectedChoice === rating && rating !== 'none',
          icon,
          onClick: () => {
            setSelectedChoice(rating)
          }
        }}
        customization={{
          icon: {
            width: 2,
            height: 2
          }
        }}
      />
    )
  }

  const _renderChoices = useMemo(() => {
    if (postState === 'idle') {
      return (
        <ContentGrid
          className={`${classPrefix}__rating-nav`}
          direction='row'
          alignment='center'
          boxSizing='border-box'
          tagType='nav'
          gap={1}
          wrap={false}
        >
          {_renderRatingAction('sad', IconType.SmileySad)}
          {_renderRatingAction('neutral', IconType.SmileyNeutral)}
          {_renderRatingAction('happy', IconType.SmileyHappy)}
        </ContentGrid>
      )
    }
  }, [selectedChoice, theme, postState])

  const _renderFeedback = () => {
    if (postState === 'idle') {
      if (selectedChoice !== 'none' && bottomText) {
        return (
          <>
            <MSRichText
              className={`${classPrefix}__text`}
              text={bottomText}
              alignment='lawful'
              brand={brand}
              theme={getCounterTheme(theme)}
            />
            <AreaText
              id={`${classPrefix}__area-text`}
              brand={brand}
              theme={theme}
              maxlength={maxFieldLength}
              rows={4}
              template={_customizeTextAreaTemplate()}
            />
          </>
        )
      }
    }
  }

  const _renderContent = () => {
    return (
      <>
        {_getTopText() && (
          <MSRichText
            className={`${classPrefix}__text`}
            text={_getTopText()}
            alignment='lawful'
            brand={brand}
            theme={getCounterTheme(theme)}
          />
        )}
        {_renderChoices}
        {_renderFeedback()}
      </>
    )
  }

  const _renderDesktopCard = () => {
    return (
      <SidePopupCard
        id={id}
        className={classNames(classPrefix, {
          [`${className}`]: className
        })}
        brand={brand}
        title={_getTitle()}
        primaryAction={_getPrimaryButtonData}
        secondaryAction={_getSecondaryButtonData}
        onClose={onClose}
        desktopView={desktopView}
      >
        {_renderContent()}
      </SidePopupCard>
    )
  }

  const _renderMobileCard = () => {
    return (
      <PopupCard
        id={id}
        className={classNames(classPrefix, {
          [`${className}`]: className
        })}
        brand={brand}
        contentTitle={_getTitle()}
        primaryAction={_getPrimaryButtonData}
        secondaryAction={_getSecondaryButtonData}
        onClose={onClose}
        contentGap={2}
        desktopView={desktopView}
      >
        {_renderContent()}
      </PopupCard>
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {desktopView && _renderDesktopCard()}
      {!desktopView && _renderMobileCard()}
    </>
  )
}
