import {
  ContentGrid,
  getCounterTheme,
  IconType,
  StyleGrid,
  Text,
  Theme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { useApplicationAddonServicesContext, useApplicationContext } from '../../contexts'
import { IHomePageView } from '../../pages'
import { SliderSelector } from '../../components/SliderSelector/SliderSelector'
import { paragraphTextPrefab } from '../../Prefabs'
import { ActionButton } from '../../components'
import { ICustomerInstallation } from '../../models'
import { createString, getTextV2 } from '../../services'
import './HomePageBannerBlock.scss'
import { ISliderItemData } from '../../components/SliderSelector/SliderItem'
import FKASgraphic from '../../assets/art/homebanners/FKAS.svg?react'
import TKASgraphic from '../../assets/art/homebanners/TKAS.svg?react'
import NGEgraphic from '../../assets/art/homebanners/NGE.svg?react'
import { ChristmasTree } from '../../assets/christmas/ChristmasTree'
import { AppStatusButtonTemplate } from '../../Prefabs/Templates/buttons/AppStatusButtonTemplate'
import { MainTitle } from '../MainTitle/MainTitle'

export interface IHomePageBannerBlock extends IHomePageView {
  className: string
}

export const HomePageBannerBlock = (props: IHomePageBannerBlock) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'home-page-banner-block'
  const { activeBrand, activeTheme, desktopView, className, translations, user, relationship } = props
  const { hosts } = relationship
  const { setInstallation, userData, installation } = user
  const { seasonalMode } = useApplicationContext()
  const { availableServicePages } = useApplicationAddonServicesContext()

  // ************************************
  // Helper Functionality
  // ************************************

  // ************************************
  // Render Functionality
  // ************************************

  const _getInstallationSelection = (activeInstallation?: ICustomerInstallation | null) => {
    let selection: any[] = []

    if (userData) {
      userData.installations.forEach((installation: ICustomerInstallation) => {
        selection.push({
          children: (
            <ContentGrid
              alignment={'center'}
              direction={'row'}
              wrap={false}
              gap={2}
              tagType='section'
            >
              <Text
                className={`${classPrefix}__slider-selector-text`}
                type={'label'}
                weight={500}
                size={'regular'}
                brand={activeBrand}
                theme={getCounterTheme(activeTheme)}
                wrap={'nowrap'}
                faded={installation !== activeInstallation}
              >
                {installation?.residenceInfo?.residenceName ?? installation.address.streetAddress}
              </Text>
            </ContentGrid>
          ),
          value: installation
        } as ISliderItemData)
      })
    }

    return selection
  }

  const _renderAddressSlider = () => {
    if (userData?.installations && userData.installations.length > 1) {
      return (
        <SliderSelector
          uniqueId={`${classPrefix}__slider-selector`}
          data={_getInstallationSelection(installation)}
          defaultOption={installation}
          slideToSelect={false}
          width={550}
          mobileWidth={document.body.clientWidth * 0.9}
          onValueChange={value => {
            if (value) {
              setInstallation(value)
            }
          }}
        />
      )
    } else if (userData?.installations[0]) {
      return (
        <Text
          className={`${classPrefix}__top__address`}
          {...paragraphTextPrefab()}
          weight={500}
          theme={getCounterTheme(activeTheme)}
          wrap={'nowrap'}
          brand={activeBrand}
        >
          {userData.installations[0].address.streetAddress}
        </Text>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  const _getBannerServiceStatus = () => {
    const customerHasStromsmart = availableServicePages?.find(
      e => e.servicePageId === 'stromsmart' && e?.serviceStatus?.state === 'ACTIVE'
    )
    const customerHasStromsmartpluss = availableServicePages?.find(
      e => e.servicePageId === 'stromsmartplus' && e?.serviceStatus?.state === 'ACTIVE'
    )
    const serviceToUse = customerHasStromsmartpluss ?? customerHasStromsmart

    let text = getTextV2({ translations, key: 'appStatusOrderInApp' })
    let link = getTextV2({ translations, key: 'appStatusOrderLink' })
    if (serviceToUse) {
      text = createString(getTextV2({ translations, key: 'appStatusCustomerHasInApp' }) ?? '', {
        serviceType: serviceToUse?.pitch?.title
      })
      link = createString(getTextV2({ translations, key: 'appStatusReadMoreLink' }) ?? '', {
        serviceType: serviceToUse?.servicePageId
      })
    }
    return (
      <BannerServiceStatus
        text={text}
        link={link}
        activeTheme={activeTheme}
        activeBrand={activeBrand}
      />
    )
  }

  return (
    <ContentGrid
      className={classNames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      direction='column'
      alignment={!desktopView && hosts?.length === 0 ? 'top-left' : 'top-center'}
      gap={2}
      tagType={'section'}
    >
      {props && <MainTitle />}
      {userData?.installations && _renderAddressSlider()}
      {_getBannerServiceStatus()}
      <StyleGrid
        className={classNames(classPrefix, {
          [`${className}`]: className
        })}
        direction='column'
        alignment='center'
        boxSizing='border-box'
        wrap={false}
      >
        {activeBrand === 'brand-fjordkraft' && <FKASgraphic className={`${classPrefix}__graphic`} />}
        {activeBrand === 'brand-trondelagkraft' && <TKASgraphic className={`${classPrefix}__graphic`} />}
        {activeBrand === 'brand-nges' && <NGEgraphic className={`${classPrefix}__graphic`} />}
        {seasonalMode === 'Christmas' && <ChristmasTree className={`${classPrefix}__christmas-tree`} />}
      </StyleGrid>
    </ContentGrid>
  )
}

const BannerServiceStatus = ({
  text,
  link,
  activeBrand,
  activeTheme
}: {
  text?: string
  link?: string
  activeTheme: Theme
  activeBrand: string
}) => {
  return (
    <StyleGrid
      direction={'row'}
      alignment={'center'}
    >
      <ActionButton
        template={AppStatusButtonTemplate(activeTheme, activeBrand)}
        action={{ link, icon: IconType.ArrowRight, text }}
        iconPlacement={'Right'}
        padding={'medium'}
      />
    </StyleGrid>
  )
}
