import React from 'react'
import { PopupCard } from '../PopupCard/PopupCard'
import { v4 as uuid4 } from 'uuid'
import { getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { MSRichText } from '../../components'
import { getText } from '../../services'
import { useApplicationContext } from '../../contexts'
import classNames from 'classnames'
import { DescriptionBlock, IDescriptionBlock, IPlankHouse, PlankHouseBlock } from '../../blocks'
import {
  ICustomerElectricityAgreement,
  ICustomerElectricityAgreementDetails,
  ICustomerFutureElectricityAgreementDetails
} from '../../models'
import { IEnergyAgreementPage } from '../../pages'

export interface IMoreAboutEnergyAgreementModal extends IEnergyAgreementPage {
  futureAgreementId?: string
  onClose: () => void
}

export interface IMoreAboutEnergyAgreementModalData {
  introduction: IDescriptionBlock
  campaignDescription: string
  house: IPlankHouse
  details: string[]
}

export const MoreAboutEnergyAgreementModal = (props: IMoreAboutEnergyAgreementModal) => {
  // ************************************
  // Properties
  // ************************************

  const { activeTheme, activeBrand, translations, agreement, details, futureAgreementId, onClose } = props
  const { desktopView } = useApplicationContext()
  const classPrefix = 'more-about-energy-agreement-modal'

  // ************************************
  // Data handling
  // ************************************

  const _getData = () => {
    let agreementToRead = agreement
    let detailsToRead: string[] = details.activeAgreementTexts

    let house = props.reducedAgreementOptions
    if (futureAgreementId) {
      let futureAgreementData: any = _getFutureAgreementDescription(
        agreementToRead.futureProducts[0].id,
        agreement,
        details
      )

      agreementToRead = futureAgreementData.agreement
      detailsToRead = futureAgreementData.details
      house = props?.futureAgreementOptions[futureAgreementId]
    }

    return {
      introduction: {
        title: agreementToRead.name,
        description: agreementToRead.productDescription,
        translations
      },
      campaignDescription: agreementToRead.campaignDescription ?? '',
      house,
      details: detailsToRead
    }
  }

  const _getFutureAgreementDescription = (
    id: number,
    agreement: ICustomerElectricityAgreement,
    details: ICustomerElectricityAgreementDetails
  ) => {
    let futureAgreement: ICustomerElectricityAgreement | undefined = undefined
    let futureDescription: string[] = []

    if (agreement.futureProducts && agreement.futureProducts.length > 0) {
      agreement.futureProducts.forEach((agreement: ICustomerElectricityAgreement) => {
        if (id === agreement.id) {
          futureAgreement = agreement
        }
      })
    }

    if (details.futureAgreementTexts && details.futureAgreementTexts.length > 0) {
      details.futureAgreementTexts.forEach((detail: ICustomerFutureElectricityAgreementDetails) => {
        if (id === detail.id) {
          futureDescription = detail.agreementTexts
        }
      })
    }

    return { agreement: futureAgreement, details: futureDescription }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderProductDescription = (activeAgreementTexts: string[]) => {
    if (activeAgreementTexts && activeAgreementTexts.length > 0) {
      let richTextElements: any[] = []

      activeAgreementTexts.forEach((text: string, index: number) => {
        if (index !== 0 && text) {
          richTextElements.push(
            <MSRichText
              key={uuid4()}
              text={text}
              theme={getCounterTheme(activeTheme)}
            />
          )
        }
      })

      return richTextElements
    }
  }

  // ************************************
  // Render
  // ************************************

  let parsedData = _getData()

  return (
    <PopupCard
      id={uuid4()}
      className={`${classPrefix}__future-products-modal`}
      brand={activeBrand}
      theme={activeTheme}
      contentGap={4}
      onClose={onClose}
      primaryAction={{
        text: getText('close', translations),
        onClick: onClose
      }}
      desktopView={desktopView}
    >
      <DescriptionBlock
        {...parsedData.introduction}
        translations={translations}
        className={classNames('', {
          [`${classPrefix}__popup-description-block`]: true
        })}
        brand={activeBrand}
        theme={activeTheme}
      />
      {parsedData.campaignDescription && (
        <MSRichText
          text={parsedData.campaignDescription}
          theme={getCounterTheme(activeTheme)}
        />
      )}
      {parsedData.house && (
        <PlankHouseBlock
          {...parsedData.house}
          className={`${classPrefix}__house`}
          titleGap={0}
          theme={activeTheme}
          brand={activeBrand}
        />
      )}
      {parsedData.details && _renderProductDescription(parsedData.details)}
    </PopupCard>
  )
}
