import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { v4 as uuid4 } from 'uuid'
import {
  IComponent,
  Text,
  getCounterTheme,
  BrandColors,
  CheckInput,
  TextInput,
  SelectDropdown,
  IOption,
  StyleGrid,
  ContentGrid
} from '@fjordkraft/fjordkraft.component.library'
import {
  IMoveInformation,
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext,
  useMoveContext
} from '../../../contexts'
import { User } from 'oidc-client-ts'
import { ActionButton, ArrowButton, Card } from '../../../components'
import { useAuth } from 'react-oidc-context'
import { isImpersonating } from '../../../services'
import {
  MSCheckInputTemplate,
  MS_ButtonTemplate,
  h3TextPrefab,
  h4TextPrefab,
  paragraphTextPrefab
} from '../../../Prefabs'
import './MoveFormPages.scss'
import { LoadingModal } from '../../../modals'
import classNames from 'classnames'
import { Constants } from '../../../data'

export interface IMoveFormPageInformation extends IComponent {
  nextPage: () => void
  prevPage: () => void
  setPage: (page: number) => void
}

export const MoveFormPageInformation = (props: IMoveFormPageInformation) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { translation } = useApplicationCoreDataContext()
  const { customerServiceFeature, POST } = useApplicationServicehandlerContext()
  const { address, terminationDate, information, setInformation, requestResult, setRequestResult, setResponseContent } =
    useMoveContext()
  const { user } = useAuth()
  const { id, nextPage, prevPage, setPage } = props
  const classPrefix = 'move-form-page-information'

  const [enableNext, setEnableNext] = useState<boolean>(false)

  let options: IOption[] = []
  let roles = translation.informationPage.movingInRoles
  if (roles && roles.length > 0) {
    roles.forEach((role: string) => {
      options.push({
        id: uuid4(),
        text: role,
        value: role
      })
    })
  }

  // ************************************
  // LifeCycle
  // ************************************
  useEffect(() => {
    if (_.isEmpty(address) || terminationDate == null) setPage(1)
  }, [address, terminationDate])

  useEffect(() => {
    if (information && information?.hasInfo) {
      if (
        information.movingInName !== undefined &&
        information.movingInName !== '' &&
        information.movingInPhone !== undefined &&
        information.movingInPhone !== '' &&
        information.movingInPhone.length == 8
      ) {
        setEnableNext(true)
      } else {
        setEnableNext(false)
      }
    } else if (information?.hasInfo === false) {
      setEnableNext(true)
    } else if (information.hasInfo === undefined) {
      setEnableNext(false)
    }
  }, [information.hasInfo, information.movingInName, information.movingInPhone])

  // ************************************
  // Send Request
  // ************************************
  const sendMoveOutRequest = async () => {
    let data: any

    if (terminationDate && address) {
      data = {
        address: address.address,
        terminationDate: moment(terminationDate).local().format('YYYY-MM-DD'),
        meterPointId: address.meterId,
        meteringPointType: address.meteringPointType
      }
      data = appendMovingInInfo(data, information)

      if (user) {
        data = appendSellerInfo(data, user)
      }

      if (user) {
        setRequestResult('pending')
        let result = await POST('MoveOut', data)
        if (result.callState == 'success' && result.data) {
          setRequestResult('success')
          setResponseContent(result.data)
          nextPage()
        } else {
          setRequestResult('error')
          nextPage()
        }
      }
    }
  }

  // ************************************
  // Helper Functions
  // ************************************
  const appendMovingInInfo = (data: any, information: IMoveInformation) => {
    if (information.hasInfo) {
      if (information.movingInName && information.movingInPhone) {
        data = {
          ...data,
          innflytterNavn: information.movingInName,
          innflytterTlf: information.movingInPhone
        }
      }

      if (information.movingInRole) {
        data = {
          ...data,
          discountNote: information.movingInRole
        }
      }
    }

    return data
  }

  const appendSellerInfo = (data: any, user: User) => {
    if (isImpersonating(user)) {
      let userName = user.profile['name']

      if (userName) {
        data = {
          ...data,
          seller: {
            ...data.seller,
            userName: userName
          }
        }
      }
    }

    return data
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderTitles = () => {
    return (
      <ContentGrid
        tagType='section'
        gap={2}
        direction='column'
        alignment='top-center'
      >
        <Text
          {...h3TextPrefab()}
          weight={700}
          color={BrandColors['primary-shade-light-2']}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
        >
          {address?.address}
        </Text>
        <Text
          {...h4TextPrefab()}
          weight={600}
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
        >
          {customerServiceFeature
            ? translation.informationPage.titleCustomerService
            : translation.informationPage.title}
        </Text>
      </ContentGrid>
    )
  }

  const _renderCheckInputs = () => {
    return (
      <StyleGrid
        direction='row'
        alignment='center'
        boxSizing='border-box'
        gap={1}
      >
        <ContentGrid
          className={`${classPrefix}__card__inputs__check-input`}
          alignment={'center'}
          direction={'row'}
          tagType={'section'}
          gap={1}
          action={{
            onClick: () => {
              setInformation({
                ...information,
                hasInfo: true
              })
            }
          }}
        >
          <CheckInput
            className={`${classPrefix}__card__inputs__check-input__checkbox`}
            type={'radio'}
            brand={activeBrand}
            theme={activeTheme}
            active={information.hasInfo === undefined ? false : information.hasInfo}
            onChange={() => {}}
            template={MSCheckInputTemplate(activeTheme, 'radio', Constants.uiBrand)}
          />
          <Text
            {...paragraphTextPrefab()}
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            weight={500}
          >
            {translation.informationPage.yesMoveInInformationOptionText}
          </Text>
        </ContentGrid>
        <ContentGrid
          className={`${classPrefix}__card__inputs__check-input`}
          alignment={'center'}
          direction={'row'}
          tagType={'section'}
          gap={1}
          action={{
            onClick: () => {
              setInformation({
                ...information,
                hasInfo: false
              })
            }
          }}
        >
          <CheckInput
            className={`${classPrefix}__card__inputs__check-input__checkbox`}
            type={'radio'}
            brand={'brand-fjordkraft'}
            theme={activeTheme}
            active={information.hasInfo === undefined ? false : !information.hasInfo}
            onChange={() => {}}
            template={MSCheckInputTemplate(activeTheme, 'radio', Constants.uiBrand)}
          />
          <Text
            {...paragraphTextPrefab()}
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            weight={500}
          >
            {translation.informationPage.noMoveInInformationOptionText}
          </Text>
        </ContentGrid>
      </StyleGrid>
    )
  }

  const _renderInputs = () => {
    return (
      <StyleGrid
        className={`${classPrefix}__card__main__inputs`}
        direction='column'
        alignment='top-center'
        gap={2}
        boxSizing='border-box'
      >
        {information.hasInfo && (
          <>
            <TextInput
              className={`${classPrefix}__card__main__inputs__input`}
              brand={activeBrand}
              theme={activeTheme}
              type='text'
              title={translation.informationPage.namePlaceholder}
              value={information.movingInName ?? ''}
              onChange={e => {
                setInformation({
                  ...information,
                  movingInName: e.target.value ?? ''
                })
              }}
            />

            <TextInput
              className={classNames(`${classPrefix}__card__inputs__phone-input`, {
                [`${classPrefix}__card__main__inputs__input`]: true
              })}
              id={uuid4()}
              brand={activeBrand}
              theme={activeTheme}
              title={translation.informationPage.phonePlaceholder}
              type={'tel'}
              readOnly={false}
              value={information.movingInPhone ?? ''}
              onChange={e => {
                e.target.value = e.target.value.slice(0, 8)
                setInformation({
                  ...information,
                  movingInPhone: e.target.value ?? ''
                })
              }}
            />

            {options && (
              <SelectDropdown
                id={`${classPrefix}__role-dropdown`}
                className={`${classPrefix}__card__main__inputs__input`}
                brand={activeBrand}
                theme={activeTheme}
                value={
                  _.find(options, {
                    value: information.movingInRole
                  }) ?? null
                }
                placeholder={'Velg rolle'}
                options={options}
                onChange={option => {
                  setInformation({
                    ...information,
                    movingInRole: option.value ?? ''
                  })
                }}
              />
            )}
            <ContentGrid
              className={`${classPrefix}__obligatory-text`}
              alignment={'center-left'}
              direction={'row'}
              tagType={'section'}
            >
              <Text
                {...paragraphTextPrefab()}
                faded
                brand={activeBrand}
                theme={getCounterTheme(activeTheme)}
              >
                {translation.informationPage.obligatoryText}
              </Text>
            </ContentGrid>
          </>
        )}
      </StyleGrid>
    )
  }

  const _renderNavigation = () => {
    return (
      <ContentGrid
        direction='column'
        alignment='center'
        gap={2}
        tagType='nav'
      >
        <ActionButton
          template={MS_ButtonTemplate(activeTheme, 'primary')}
          padding='default'
          brand={activeBrand}
          action={{
            text: translation.informationPage.finishButtonTitle,
            onClick: () => {
              sendMoveOutRequest()
            },
            disabled: !enableNext
          }}
        />
        <ArrowButton
          action={{
            onClick: prevPage,
            text: translation.informationPage.backButtonTitle
          }}
          iconLocation='left'
        />
      </ContentGrid>
    )
  }

  // ************************************
  // Render
  // ************************************
  return (
    <>
      {requestResult == 'pending' && (
        <LoadingModal
          theme={activeTheme}
          brand={activeBrand}
          mode={'fit'}
        />
      )}
      {requestResult == 'idle' && (
        <StyleGrid
          id={id}
          className={`${classPrefix}`}
          brand={activeBrand}
          theme={activeTheme}
          alignment={'center-left'}
          direction={'row'}
        >
          {information && (
            <Card
              className={`${classPrefix}__card`}
              theme={activeTheme}
              brand={activeBrand}
              alignment={'center'}
            >
              <ContentGrid
                className={`${classPrefix}__card__main`}
                alignment={'center'}
                direction={'column'}
                gap={4}
                tagType={'section'}
              >
                {_renderTitles()}
                {_renderCheckInputs()}
                {_renderInputs()}
                {_renderNavigation()}
              </ContentGrid>
            </Card>
          )}
        </StyleGrid>
      )}
    </>
  )
}
