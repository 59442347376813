import { ContentLoader } from '@episerver/content-delivery'
import { Constants } from '../../../data'
import { IHomePage } from '../../../models'
import { fetchEpiContent } from './EpiService'

export const fetchHomePage = async (l: ContentLoader): Promise<IHomePage | null> => {
  let data = await fetchEpiContent(l, `${Constants.episerver.pageId}`)
  return data
}

export const HomePageData = (epiChildren: any): IHomePage => {
  return epiChildren?.HeadlessHomePageType?.data
}
