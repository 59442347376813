import React from 'react'
import { Text, IconType, BaseComponent, IBaseComponent } from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { AuthenticationLoadingModalTemplate } from './AuthenticationLoadingModalTemplate'
import { h1TextPrefab, h3TextPrefab } from '../../Prefabs'
import './AuthenticationLoadingModal.scss'

export interface IAuthenticationLoadingModal extends IBaseComponent {
  title?: string
  subTitle?: string
  icon?: IconType
  delayedText?: boolean
}

/**
 *   The AuthenticationLoadingModal
 *   Used as an individual loading graphic for the authentication stage
 *   @type {title} title (optional)
 *   @type {string} subTitle (optional)
 */
export const AuthenticationLoadingModal = (props: IAuthenticationLoadingModal) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    title,
    subTitle,
    theme = 'Light',
    brand = import.meta.env.REACT_APP_UI_BRAND,
    template = AuthenticationLoadingModalTemplate(theme)
  } = props

  const classPrefix = 'ms-authentication-loading-modal'

  // ************************************
  // Render
  // ************************************

  return (
    <BaseComponent
      id={id}
      className={classnames(classPrefix, {
        [`${brand}`]: brand,
        [`${classPrefix}--${theme}`]: theme,
        [`${className}`]: className
      })}
      template={template}
    >
      <Text
        {...h1TextPrefab()}
        brand={brand}
      >
        {title}
      </Text>
      <Text
        {...h3TextPrefab()}
        weight={400}
        brand={brand}
      >
        {subTitle}
      </Text>
      <div className={`${classPrefix}__loader`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </BaseComponent>
  )
}
