import { IDescriptionBlock } from '../../../../blocks'
import { IServicePage, IServiceStatusDescriptions, ServiceStatus } from '../../../../models'

export interface IServicePageDataDescriptions {
  top?: IDescriptionBlock[]
  middle?: IDescriptionBlock[]
  bottom?: IDescriptionBlock[]
}

// ************************************
// PUBLIC
// ************************************

export const getServicePageDataDescriptions = (
  servicePage: IServicePage,
  status?: ServiceStatus
): IServicePageDataDescriptions | undefined => {
  let stateDescription: IServiceStatusDescriptions | undefined = getServiceEpiDataBasedOnStatus(servicePage, status)

  if (stateDescription) {
    let top: IDescriptionBlock[] = []
    let middle: IDescriptionBlock[] = []
    let bottom: IDescriptionBlock[] = []

    if (stateDescription.infoBlocks?.length > 0) {
      stateDescription.infoBlocks.forEach((info: IDescriptionBlock) => {
        switch (info.placement) {
          case 'top-center':
          case 'top-left':
          case 'top-right':
            top.push(info)
            break
          case 'center':
          case 'center-left':
          case 'center-right':
            middle.push(info)
            break
          case 'bottom-center':
          case 'bottom-left':
          case 'bottom-right':
            bottom.push(info)
            break
        }
      })
    }

    return { top, middle, bottom }
  }
}

export const getServiceEpiDataBasedOnStatus = (
  servicePage: IServicePage,
  status?: ServiceStatus
): IServiceStatusDescriptions | undefined => {
  if (servicePage.serviceDescriptions?.length > 0) {
    let stateDescription: IServiceStatusDescriptions = servicePage.serviceDescriptions[0]

    if (status) {
      const foundDesc = servicePage.serviceDescriptions.find(desc => _pointOutStateDescription(desc, status))
      if (foundDesc) stateDescription = foundDesc
    }

    return stateDescription
  }
}

// ************************************
// PRIVATE
// ************************************

const _pointOutStateDescription = (desc: IServiceStatusDescriptions, status: ServiceStatus) => {
  switch (status) {
    case 'ACTIVE':
    case 'TERMINATING':
    case 'ACTIVE_FUTURE':
      if (desc.status === 'subscribed') {
        return desc
      }
      break
    case 'INACTIVE':
    case 'TERMINATED':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
      if (desc.status === 'unsubscribed') {
        return desc
      }
      break
    case 'ACTIVATING':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
      if (desc.status === 'pending') {
        return desc
      }
      break
  }
}
