import React, { useState } from 'react'
import { useApplicationContext, useApplicationCoreDataContext } from '../../../contexts'
import { getText } from '../../../services'
import { IPopupCard, PopupCard } from '../../PopupCard/PopupCard'
import { CustomerInfoEditor, ICustomerEditorInformation } from '..'
import { CallState, LoaderGraphic, StyleGrid, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { paragraphTextPrefab } from '../../../Prefabs'
import './CustomerInfoEditorModal.scss'

export interface ICustomerInfoEditorModal extends IPopupCard {
  title: string
  topText: string
  fields: ICustomerEditorInformation
  bottomText: string
  callState?: CallState
  onSubmitFormChange: (fields: ICustomerEditorInformation) => void
}

export const CustomerInfoEditorModal = (props: ICustomerInfoEditorModal) => {
  // ************************************
  // Properties
  // ************************************
  const {
    fields,
    topText,
    bottomText,
    title,
    theme = 'Light',
    brand,
    callState = 'idle',
    onSubmitFormChange,
    onClose
  } = props
  const classPrefix = 'userData-info-editor-modal'
  const { epiChildren } = useApplicationCoreDataContext()
  const { desktopView } = useApplicationContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [canSubmitCustomerData, setCanSubmitCustomerData] = useState<'yes' | 'no'>('no')
  const [editedInformation, setEditedInformation] = useState<ICustomerEditorInformation>()
  const [state, setState] = useState<CallState>(callState)

  let translation = epiChildren.MePage.data

  // ************************************
  // Helper Functionality
  // ************************************

  const _isActionButtonDisabled = () => {
    return canSubmitCustomerData === 'no' || state === 'pending' || !editedInformation
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderLoaderGraphic = () => {
    return (
      <StyleGrid
        className={`${classPrefix}__loader`}
        direction='column'
        alignment='center'
      >
        <LoaderGraphic
          theme={theme}
          brand={brand}
          thickness={'regular'}
          scaleThickness={0.5}
          size={4}
        />
      </StyleGrid>
    )
  }

  const _renderEditorialContent = () => {
    return (
      <>
        {topText && (
          <Text
            {...paragraphTextPrefab()}
            className={`${classPrefix}__popup__top-text`}
            theme={getCounterTheme(theme)}
            brand={brand}
          >
            {topText}
          </Text>
        )}
        <CustomerInfoEditor
          className={`${classPrefix}__editor`}
          brand={brand}
          theme={theme}
          customerInformation={fields}
          translation={translation}
          onChange={(value: ICustomerEditorInformation, valid: boolean) => {
            setCanSubmitCustomerData(valid ? 'yes' : 'no')
            setEditedInformation(value)
          }}
        />
        {bottomText && (
          <Text
            {...paragraphTextPrefab()}
            theme={getCounterTheme(theme)}
            brand={brand}
          >
            {bottomText}
          </Text>
        )}
      </>
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <PopupCard
      contentTitle={state === 'pending' ? getText('customerInfoEditorModalLoadingText', translation) : title}
      contentTitleAlignment={state === 'pending' ? 'align-center' : 'align-left'}
      contentGap={2}
      onClose={onClose}
      contentAlignment={state === 'pending' ? 'center' : undefined}
      primaryAction={{
        disabled: _isActionButtonDisabled(),
        text: getText('save', translation),
        onClick: () => {
          setState('pending')

          if (onSubmitFormChange && editedInformation) {
            onSubmitFormChange(editedInformation)
          }
        }
      }}
      secondaryAction={{
        text: getText('cancel', translation),
        onClick: () => {
          if (onClose) {
            onClose()
          }
        }
      }}
      desktopView={desktopView}
    >
      {state !== 'pending' && _renderEditorialContent()}
      {state === 'pending' && _renderLoaderGraphic()}
    </PopupCard>
  )
}
