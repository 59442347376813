import React from 'react'
import { PageV2 } from '../../PageV2'
import { Constants } from '../../../data'
import { getPageContent, IInstallationContent, IResidencePageData } from './ResidencesPageData'
import { PlankHouseBlock } from '../../../blocks'
import { useSearchParams } from 'react-router-dom'
import { InfoGrid } from '../../../components/InfoGrid/InfoGrid'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { get } from 'lodash'
import { useApplicationUserEditWrapperContext } from '../../../contexts'

export const ResidencesPage = () => {
  const [searchParams] = useSearchParams()
  const meterId = searchParams.get('meterId')
  const { isGuest } = useApplicationGuestsAndHostsContext()
  const { setCustomerDataToEdit } = useApplicationUserEditWrapperContext()

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.residences.type,
      pageClamp: 'clamp',
      usesSubPage: true
    },
    dependencies: [{ isGuest }],
    dependenciesOptional: [{ meterId }, { setCustomerDataToEdit }],
    handleData: getPageContent,
    render: (config: IResidencePageData) => {
      const { desktopView, house, activeBrand, activeTheme, installations } = config
      const { rows } = get(installations, `${meterId}`, {}) as IInstallationContent
      return (
        <>
          {rows ? (
            <InfoGrid
              rows={rows}
              view={desktopView ? 'desktop' : 'mobile'}
            />
          ) : (
            <PlankHouseBlock
              {...house}
              brand={activeBrand}
              theme={activeTheme}
            />
          )}
        </>
      )
    }
  })
}
