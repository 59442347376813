import {
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = () => {
  return {
    height: 'auto',
    width: '100%',
    border: {
      radiusType: 'curved',
      thickness: 3,
      type: 'solid'
    },
    padding: '0.5rem',
    margin: undefined,
    grid: {
      direction: 'row',
      alignment: 'center',
      gap: 2,
      tagType: 'article',
      boxSizing: 'border-box'
    }
  } as IComponentTransform
}

// ************************************
// Palette
// ************************************

const _paletteMessage = {
  Light: {
    background: { color: BrandColors['action-shade-light-1'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['action-shade-light-1'] },
    icon: { color: BrandColors['action-shade-light-1'] }
  },
  Dark: {
    background: { color: BrandColors['action-shade-light-1'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['action-shade-light-1'] },
    icon: { color: BrandColors['action-shade-light-1'] }
  }
} as IThemedColors

const _paletteAlert = {
  Light: {
    background: { color: BrandColors['status-shade-light-3'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['status-shade-light-3'] },
    icon: { color: BrandColors['status-shade-light-3'] }
  },
  Dark: {
    background: { color: BrandColors['status-shade-light-3'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['status-shade-light-3'] },
    icon: { color: BrandColors['status-shade-light-3'] }
  }
} as IThemedColors

const _paletteWarning = {
  Light: {
    background: { color: BrandColors['primary-shade-light-1'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['primary-shade-light-1'] },
    icon: { color: BrandColors['primary-shade-light-1'] }
  },
  Dark: {
    background: { color: BrandColors['primary-shade-light-1'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['primary-shade-light-1'] },
    icon: { color: BrandColors['primary-shade-light-1'] }
  }
} as IThemedColors

const _paletteSuccess = {
  Light: {
    background: { color: BrandColors['secondary-shade-dark-2'] },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['status-shade-light-1'] },
    icon: { color: BrandColors['status-shade-light-1'] }
  },
  Dark: {
    background: { color: BrandColors['primary-shade-light-1'], opacity: 0.2 },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['primary-shade-light-1'] },
    icon: { color: BrandColors['primary-shade-light-1'] }
  }
} as IThemedColors

// ************************************
// Export
// ************************************

export const AlertCardTemplate = (
  theme: Theme = 'Light',
  type: 'alert' | 'warning' | 'success' | 'message' = 'message'
) => {
  switch (type) {
    case 'message':
      return {
        palette: _paletteMessage[theme],
        transform: _transform()
      } as IComponentTemplate
    case 'alert':
      return {
        palette: _paletteAlert[theme],
        transform: _transform()
      } as IComponentTemplate
    case 'warning':
      return {
        palette: _paletteWarning[theme],
        transform: _transform()
      } as IComponentTemplate
    case 'success':
      return {
        palette: _paletteSuccess[theme],
        transform: _transform()
      } as IComponentTemplate
  }
}
