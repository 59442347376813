import { BrandColors, IconType } from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../../data'
import { AllowFeature, createString, getText } from '../../../services'
import { IHomePageData } from '../HomePageData'
import { ICustomerInvoice } from '../../../models'
import { _getTextPlankPrefabTemplate } from '.'
import { IStatePlank } from '../../../components'

export const InvoicePlank = (config: IHomePageData): IStatePlank | undefined => {
  const { translations, services } = config
  const { user, customerServiceFeature } = services

  if (AllowFeature(Constants.features.invoices, translations, user, customerServiceFeature)) {
    return {
      promise: fetchInvoicePlank(config)
    }
  }
}

const fetchInvoicePlank = async (props: IHomePageData) => {
  const { activeTheme, activeBrand, translations, services } = props
  const { GET } = services

  let description: string = getText('plankInvoiceDesc', translations) ?? ''
  let text: string = ''
  let resp = await GET('Invoices')
  let colorState: BrandColors | undefined = undefined
  let unpaidCount: number = 0
  let creditCount: number = 0

  if (resp && resp?.data?.invoices.length > 0) {
    resp.data.invoices.forEach((inv: ICustomerInvoice) => {
      switch (inv.paymentStatus) {
        case 'Overdue':
        case 'PartlyPaid':
        case 'Unpaid':
          unpaidCount++
          break
        case 'Credit':
          creditCount++
          break
      }
    })

    if (unpaidCount > 0) {
      text = createString(getText(unpaidCount > 1 ? 'invoiceMultipleUnpaid' : 'invoiceUnpaid', translations), {
        amount: unpaidCount
      })
      colorState = BrandColors['status-shade-light-3']
    } else if (creditCount > 0) {
      text = createString(getText('invoiceCredit', translations), {
        amount: creditCount
      })
      colorState = BrandColors['status-shade-light-2']
    } else if (unpaidCount === 0 && creditCount === 0) {
      text = getText('invoiceEverythingPaid', translations)
      colorState = BrandColors['status-shade-light-1']
    }
  }

  return _getTextPlankPrefabTemplate({
    theme: activeTheme,
    brand: activeBrand,
    title: getText('plankInvoiceTitle', translations),
    description,
    rightTitle: text,
    link: Constants.paths.invoicePage,
    iconRight: IconType.ChevronRight,
    rightCustomization: {
      description: {
        weight: 400,
        color: colorState
      }
    }
  })
}
