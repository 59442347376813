import React, { useEffect, useMemo, useState } from 'react'
import { ApplicationReviewContext, useApplicationDefaultContext } from '../../../contexts'
import {
  CustomerEffortScoreModal,
  ICustomerEffortScore
} from '../../../modals/CustomerEffortScoreModal/CustomerEffortScoreModal'
import { Constants } from '../../../data'
import useSimpleTimeout from '../../../hooks/useSimpleTimeout'
import { HomePageData } from '../../../services'

export interface ICustomerEffortScoreData extends Omit<ICustomerEffortScore, 'onClose' | 'desktopView'> {}

let REVIEW_POPUP_GLOBAL_TIMER: any = undefined

export const ApplicationReviewWrapper = (props: any) => {
  // ************************************
  // Properties
  // ************************************

  const { defaultProps } = useApplicationDefaultContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [showReview, setShowReview] = useState<boolean>()
  const [reviewData, setReviewData] = useState<any>()
  const [previousPage, setPreviousPage] = useState<any>()
  const [localTimerDuration, setLocalTimerDuration] = useState<number>(Constants.customerEffortScore.minimumTimeOnPage)

  useEffect(() => {
    localTimer.reset()
    setLocalTimerDuration(
      defaultProps.translations?.customerEffortScore?.timerToShowInSeconds ??
        Constants.customerEffortScore.minimumTimeOnPage
    )
    _setGlobalTimer()
  }, [defaultProps])

  useEffect(() => {
    _handleCustomerEffortScore(previousPage)
  }, [previousPage])

  // ************************************
  // Timer Handling
  // ************************************

  const localTimer = useSimpleTimeout(() => {
    _setPageToReview('local')
  }, localTimerDuration * 1000)

  const _setGlobalTimer = () => {
    let rootData = HomePageData(defaultProps.epiChildren)
    let time =
      rootData?.customerEffortScore?.timerToShowInSeconds ?? Constants.customerEffortScore.globalMinimumTimeOnPage

    if (REVIEW_POPUP_GLOBAL_TIMER) {
      clearTimeout(REVIEW_POPUP_GLOBAL_TIMER)
    }

    REVIEW_POPUP_GLOBAL_TIMER = setTimeout(() => {
      _setPageToReview('global')
    }, time * 1000)
  }

  const _setPageToReview = async (from: 'global' | 'local') => {
    const { epiChildren } = defaultProps

    if (!showReview) {
      let canShowGlobal: boolean = false
      let homePage: any = undefined

      if (epiChildren?.HeadlessHomePageType?.data?.customerEffortScore?.active) {
        homePage = epiChildren.HeadlessHomePageType.data
        canShowGlobal = await _canShowPageReview(homePage)
      }

      if (canShowGlobal && homePage && from === 'global') {
        setPreviousPage(homePage)
      } else if (from === 'local' && !canShowGlobal) {
        setPreviousPage(defaultProps.translations)
      }
    }
  }

  const resetTimer = () => {
    _setGlobalTimer()
    localTimer.reset()
  }

  const _hideReview = () => {
    if (previousPage?.customerEffortScore?.featureId && previousPage.customerEffortScore.active) {
      localStorage.setItem(`${previousPage.customerEffortScore.featureId}`, 'false')
    }

    setShowReview(false)
    resetTimer()
  }

  // ************************************
  // Effort score handling
  // ************************************

  const _canShowPageReview = async (page: any): Promise<boolean> => {
    const { GET } = defaultProps.services

    let canShow: boolean = false

    if (page?.customerEffortScore.active) {
      let data = page.customerEffortScore

      if (data.featureId) {
        let resp = await GET(`CustomerEffortScore/${data.featureId}`)
        let currDate = new Date()

        if (resp.callState === 'success' && resp?.data?.timeStamp) {
          let timeStampDate: Date = new Date(resp.data.timeStamp)
          timeStampDate.setDate(timeStampDate.getDate() + Constants.customerEffortScore.timeBetweenQuestions)
          canShow = currDate > timeStampDate
        } else {
          canShow = _getLocalStorageCustomerEffortScoreState(page)
        }
      }
    }

    return canShow
  }

  const _handleCustomerEffortScore = async (page: any) => {
    if (page?.customerEffortScore?.active) {
      let show: boolean = await _canShowPageReview(page)
      setShowReview(show)
      setReviewData(show ? (page.customerEffortScore as ICustomerEffortScore) : undefined)
    }
  }

  const _getLocalStorageCustomerEffortScoreState = (page: any): boolean => {
    if (page?.customerEffortScore?.featureId && page?.customerEffortScore.active) {
      let state: string | null = localStorage.getItem(`${page.customerEffortScore?.featureId}`)

      if (state && state === 'false') {
        return false
      }
    }

    return true
  }

  // ************************************
  // Context
  // ************************************

  const context = useMemo(() => {
    return {
      resetTimer
    }
  }, [])

  // ************************************
  // Render Functionality
  // ************************************

  const _renderCustomerEffortScorePopup = () => {
    if (showReview && reviewData) {
      return (
        <CustomerEffortScoreModal
          {...reviewData}
          id={reviewData.id}
          theme={defaultProps.activeTheme}
          brand={defaultProps.activeBrand}
          desktopView={defaultProps.desktopView}
          onReviewComplete={() => {
            setShowReview(false)
            resetTimer()
          }}
          onClose={() => {
            _hideReview()
          }}
        />
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ApplicationReviewContext.Provider value={context}>
      {props.children}
      {_renderCustomerEffortScorePopup()}
    </ApplicationReviewContext.Provider>
  )
}
