import React, { useMemo, useState } from 'react'
import { getPlankLocation, RadioPlank, IRadioPlank } from '@fjordkraft/fjordkraft.component.library'
import { v4 as uuid4 } from 'uuid'
import { MS_MasterPlankTemplate } from '../../../Prefabs/Templates/planks/MS_MasterPlankTemplate'
import { MSCheckInputTemplate } from '../../../Prefabs'
import { IOptimizedPlankWallBase, OptimizedPlankWallBase } from '../OptimizedPlankWallBase'
import { IResponse } from '../../../services'

export interface IRadioPlankWall extends IOptimizedPlankWallBase {
  initialActiveIndex?: number
  planks: IRadioPlank[]
  usePlankDisabled?: boolean
  onSelectItem?: (value: any) => void
  onAsyncSelectItem?: (value: any) => Promise<IResponse>
}

export const RadioPlankWall = (props: IRadioPlankWall) => {
  // ************************************
  // Properties
  // ************************************

  const {
    brand,
    theme = 'Light',
    planks,
    disabled,
    onSelectItem,
    onAsyncSelectItem,
    initialActiveIndex,
    usePlankDisabled = false
  } = props

  // ************************************
  // Helpers
  // ************************************

  const _getInitialActiveItem = (): IRadioPlank | undefined => {
    let foundActive: IRadioPlank | undefined = undefined

    planks.forEach((plank: IRadioPlank | undefined) => {
      if (plank?.active) {
        foundActive = plank
      }
    })

    return foundActive ?? planks[initialActiveIndex ?? 0]
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [selected, setSelected] = useState<IRadioPlank | undefined>(_getInitialActiveItem())

  // ************************************
  // On change functionality
  // ************************************

  const _handleOnChange = async (plank: IRadioPlank) => {
    if (onSelectItem) {
      onSelectItem(plank)
      setSelected(plank)
    } else if (onAsyncSelectItem) {
      let resp = await onAsyncSelectItem(plank)

      if (resp.callState === 'success') {
        setSelected(plank)
      }
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderPlanks = useMemo(() => {
    return planks.map((plank: IRadioPlank, i: number) => {
      let idRef: string = uuid4()

      return (
        <RadioPlank
          {...plank}
          active={selected ? plank.id === selected.id : false}
          key={idRef}
          location={getPlankLocation(i, planks.length, plank)}
          theme={theme}
          brand={brand}
          disabled={usePlankDisabled ? plank.disabled : disabled}
          radioTemplate={MSCheckInputTemplate(theme, 'radio')}
          value={plank}
          template={plank.template ?? MS_MasterPlankTemplate(theme, getPlankLocation(i, planks.length, plank))}
          onChange={() => {
            _handleOnChange(plank)
          }}
        />
      )
    })
  }, [theme, brand, usePlankDisabled, disabled, selected, planks])

  // ************************************
  // Render
  // ************************************

  return (
    <OptimizedPlankWallBase
      {...props}
      optimizationSettings={{
        plankAverageHeightInRem: 4,
        plankCount: planks.length
      }}
    >
      {_renderPlanks}
    </OptimizedPlankWallBase>
  )
}
