import { BrandColors, IComponentTemplate, Theme, ToastTemplate } from '@fjordkraft/fjordkraft.component.library'
import { logger } from '../../../services'

interface IMStaticStatusToastTemplateConfiguration {
  theme: Theme
  status: 'positive' | 'negative' | 'neutral'
}

export const MS_StaticStatusToastTemplate = (config: IMStaticStatusToastTemplateConfiguration) => {
  const { theme = 'Light', status = 'positive' } = config

  let base: IComponentTemplate = ToastTemplate(theme, status)

  if (theme) {
    if (status === 'neutral') {
      base.palette.background = { color: BrandColors['action-shade-dark-2'], opacity: 0.05 }
      base.palette.border = { color: BrandColors['action-shade-dark-2'] }
      base.palette.icon = { color: BrandColors['action-shade-dark-2'] }
    }

    if (base.transform.grid) {
      base.transform.grid.alignment = 'top-left'
    }

    base.transform.height = 'auto'
    base.transform.width = '100%'
    base.transform.padding = '1rem 2rem'

    base.transform.border = {
      radiusType: 'curved',
      type: 'solid',
      thickness: 3
    }
  } else {
    logger('Theme not defined for MS_StaticStatusToastTemplate', 'error')
  }

  return base
}
