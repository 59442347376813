import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { Alignment, IComponent, StyleGrid, StyleLoopLimit } from '@fjordkraft/fjordkraft.component.library'
import { IToast, Toast } from './Toast/Toast'
import './Toaster.scss'

export interface IToaster extends IComponent {
  placement: Alignment
  duration?: number
  lingerTime?: number
  toasts: IToast[]
  coverage?: 'window' | 'relative' | 'absolute'
  gap?: StyleLoopLimit
  spaced?: StyleLoopLimit
  onAnimationComplete?: () => void
}

export const Toaster = (props: IToaster) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    theme,
    brand,
    placement,
    duration = 1000,
    lingerTime = 1000,
    toasts,
    coverage,
    gap = 0,
    spaced = 0,
    style
  } = props
  const classPrefix = 'toaster-component'

  // ************************************
  // Render Functionality
  // ************************************

  const renderToasts = () => {
    if (toasts.length > 0) {
      let toastNodes: any[] = []

      toasts.forEach((t: IToast) => {
        toastNodes.push(
          <Toast
            {...t}
            key={uuid4()}
            id={t.id ?? uuid4()}
            duration={t.duration ?? duration}
            lingerTime={t.lingerTime ?? lingerTime}
            theme={theme}
            brand={brand}
            onAnimationComplete={id => {
              if (t.onAnimationComplete) t.onAnimationComplete(id)
            }}
          />
        )
      })

      return toastNodes
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id ?? uuid4()}
      className={classNames(classPrefix, {
        [`${classPrefix}--${coverage}`]: coverage,
        [`${classPrefix}--${coverage}--${placement}`]: coverage && placement,
        [`${classPrefix}--space-${spaced}`]: spaced,
        [`${className}`]: className
      })}
      brand={brand}
      direction='column'
      alignment={placement}
      gap={gap}
      boxSizing={'border-box'}
      style={style}
    >
      {renderToasts()}
    </StyleGrid>
  )
}
