import React from 'react'
import { ContentGrid } from '@fjordkraft/fjordkraft.component.library'
import { getPageContent } from './SpotPricePageData'
import { AreaChartCard, IAreaChartCard } from '../../components'
import { tNumber, getText } from '../../services'
import { Constants } from '../../data'
import { IDefaultViewProps, PageV2 } from '../PageV2'
import './SpotPricePage.scss'
import { CommercialBlock } from '../../blocks'

export interface ISpotPricePage extends IDefaultViewProps {
  chartData: IAreaChartCard
}

export const SpotPricePage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'spot-price-page'

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.spotPrice.type,
      usesSubPage: true
    },
    handleData: getPageContent,
    render: (config: ISpotPricePage) => {
      const { chartData, translations, activeBrand, activeTheme } = config

      return (
        <ContentGrid
          className={`${classPrefix}`}
          direction='column'
          alignment='top-center'
          tagType='section'
          gap={7}
        >
          <AreaChartCard
            {...chartData}
            chartTooltip={{
              show: true,
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                let x: string = ''
                let y: string = ''
                let tooltipString = ''

                if (chartData.categories) {
                  x = `${chartData.categories[dataPointIndex]}`
                  y = `${tNumber(series[seriesIndex][dataPointIndex], 'no-NO', 2)} ${getText('currency', translations)}`
                }

                if (!isNaN(series[seriesIndex][dataPointIndex])) {
                  tooltipString =
                    "<h4 style='font-family: var(--font-main); font-size: var(--font-small); font-weight: 400;'>" +
                    x +
                    '</h4>' +
                    '</br>' +
                    "<label style='font-family: var(--font-main); font-size: var(--font-regular); font-weight: 700;'>" +
                    y +
                    '</label>'
                } else {
                  tooltipString =
                    "<h4 style='font-family: var(--font-main); font-size: var(--font-small); font-weight: 400;'>" +
                    x +
                    '</h4>'
                }

                return tooltipString
              },
              x: {
                formatter: (value: any) => {
                  let adjVal = value - 1
                  return adjVal <= 9 ? `0${adjVal}:00` : `${adjVal}:00`
                }
              },
              y: {
                formatter: (value: any) => {
                  return `${tNumber(value, 'no-NO', 2)} ${getText('currency', translations)}`
                },
                suffix: ` ${getText('currency', translations)}`,
                showOnAxisLabels: true
              }
            }}
            className={`${classPrefix}__chart`}
            theme={activeTheme}
            brand={activeBrand}
          />
          <CommercialBlock />
        </ContentGrid>
      )
    }
  })
}
