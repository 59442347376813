import React from 'react'
import { getPageContent } from './InvoiceSelectedPageData'
import { useParams } from 'react-router-dom'
import { InvoiceCard, IInvoiceCard } from '../../../components'
import { ICustomerInvoice } from '../../../models'
import { Constants } from '../../../data'
import { IDefaultViewProps, PageV2 } from '../../PageV2'

export interface IInvoiceSelectedPage extends IDefaultViewProps {
  invoice: ICustomerInvoice
  selectedInvoice: IInvoiceCard
}

export const InvoiceSelectedPage = () => {
  // ************************************
  // Properties
  // ************************************

  let { invoiceId } = useParams<any>()

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.invoice.type,
      usesSubPage: true
    },
    dependencies: [{ invoiceId }],
    handleData: getPageContent,
    render: (config: IInvoiceSelectedPage) => {
      const { activeBrand, activeTheme, selectedInvoice } = config

      if (selectedInvoice) {
        return (
          <InvoiceCard
            {...selectedInvoice}
            simplified={false}
            theme={activeTheme}
            brand={activeBrand}
          />
        )
      }
    }
  })
}
