import React, { useEffect, useState } from 'react'
import { IAction, ITabSelectFilterItem, StyleGrid, Text } from '@fjordkraft/fjordkraft.component.library'
import { paragraphTextPrefab } from '../../../Prefabs'
import { ColumnChartBlock, IColumnChartBlock } from '../../../blocks'
import { useParams } from 'react-router-dom'
import { useSubPageLayoutContext, useDefaultPageContext, useApplicationDefaultContext } from '../../../contexts'
import {
  IChartParsedChartData,
  IServiceChartPageData,
  getPageContent,
  getParsedChartData
} from './ServiceChartPageData'
import { IServicePage } from '../../../models'
import { getEpiServicePage } from '../../../services'
import { Constants } from '../../../data'
import { GenericDateSelectFilter } from '../../../components'
import './ServiceChartPage.scss'

export interface IServiceChartPage {
  title: string
  back: IAction
  description: string
  chartData?: IColumnChartBlock
  translations: IServicePage
}

export const ServiceChartPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'service-chart-page'
  const { serviceId } = useParams()
  const { defaultProps } = useApplicationDefaultContext()
  const { setContentLoading } = useDefaultPageContext()
  const { setTitle, setBack } = useSubPageLayoutContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [pageContent, setPageContent] = useState<IServiceChartPage>()
  const [activeDate, setActiveDate] = useState<Date>()
  const [chartData, setChartData] = useState<IColumnChartBlock>()
  const [filter, setFilter] = useState<ITabSelectFilterItem[]>()
  const [localBrand, setLocalBrand] = useState<string>()

  useEffect(() => {
    const { user, epiChildren, services, activeTheme } = defaultProps
    const { GET } = services
    const { userData, installation } = user

    if (userData && epiChildren && serviceId && installation) {
      let page: IServicePage = getEpiServicePage(epiChildren?.HeadlessServicesPage, serviceId)
      let config: IServiceChartPageData = {
        page,
        userData,
        installation,
        serviceId,
        theme: activeTheme,
        GET
      }

      if (!pageContent) {
        _handlePageData(config)
      }
    }
  }, [defaultProps, serviceId])

  useEffect(() => {
    const { epiChildren, services } = defaultProps
    const { GET } = services

    if (serviceId) {
      let page: IServicePage = getEpiServicePage(epiChildren?.HeadlessServicesPage, serviceId)

      _handleChartData({
        GET,
        serviceId,
        date: activeDate ?? new Date(),
        page
      } as IChartParsedChartData)
    }
  }, [serviceId, activeDate, defaultProps])

  // ************************************
  // Handlers
  // ************************************

  const _handlePageData = async (config: IServiceChartPageData) => {
    setContentLoading(true)

    let pageData = await getPageContent(config)

    setTitle(pageData.title)
    setBack(pageData.back)
    setPageContent(pageData)
    setLocalBrand(`brand-${config.page.servicePageType?.toLocaleLowerCase() ?? Constants.uiBrand}`)

    setContentLoading(false)
  }

  const _handleChartData = async (config: IChartParsedChartData) => {
    let data = await getParsedChartData(config)

    if (!filter && data?.yearFilter?.items) {
      setFilter(data.yearFilter.items)
    }

    if (data?.chartData) {
      setChartData(data.chartData)
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      className={classPrefix}
      direction='column'
      alignment='top-center'
      gap={5}
    >
      {pageContent?.description && (
        <Text
          {...paragraphTextPrefab()}
          className={`${classPrefix}__description`}
          brand={defaultProps.activeBrand}
        >
          {pageContent.description}
        </Text>
      )}
      <StyleGrid
        className={`${classPrefix}__chart-wrapper`}
        direction='column'
        alignment='top-center'
        gap={2}
        gapType={defaultProps.desktopView ? 'rem' : 'px'}
      >
        {filter && (
          <GenericDateSelectFilter
            className={`${classPrefix}__filter`}
            brand={defaultProps.activeBrand}
            theme={defaultProps.activeTheme}
            initialDate={new Date()}
            yearsFilter={filter}
            onChange={(filterSettings: any) => {
              setActiveDate(new Date(filterSettings))
            }}
          />
        )}
        {chartData && localBrand && (
          <ColumnChartBlock
            {...chartData}
            className={`${classPrefix}__chart`}
            brand={localBrand}
          />
        )}
      </StyleGrid>
    </StyleGrid>
  )
}
