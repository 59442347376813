import { getCounterTheme, IComponent, IText } from '@fjordkraft/fjordkraft.component.library'
import { getBrand } from '../../services'

export const h1TextPrefab = (props?: IComponent) => {
  return {
    theme: getCounterTheme(props?.theme ?? 'Light'),
    brand: props?.brand ?? getBrand(),
    weight: 700,
    size: 'extra-large',
    family: 'main',
    type: 'h1'
  } as IText
}
