import React from 'react'
import { ContentGrid, IComponent, StyleGrid, Text, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { h2TextPrefab } from '../../Prefabs'
import { IArticleItem, ArticleItem } from '../../components'
import { v4 as uuid4 } from 'uuid'
import { useApplicationContext } from '../../contexts'
import './ArticlesBlock.scss'

export interface IArticlesBlock extends IComponent {
  title?: string
  items: IArticleItem[]
}

export const ArticlesBlock = (props: IArticlesBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand, title, items } = props
  const { desktopView } = useApplicationContext()
  const classPrefix = 'ms-articles-block'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderServiceBlockList = () => {
    return items.map((service: IArticleItem) => {
      return (
        <ArticleItem
          key={uuid4()}
          theme={theme}
          brand={brand}
          {...service}
        />
      )
    })
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ContentGrid
      id={id}
      className={`${classNames(classPrefix, {
        [`${className}`]: className
      })}`}
      brand={brand}
      tagType='section'
      direction='column'
      alignment='top-center'
      gap={3}
    >
      {title && items.length > 0 && (
        <Text
          {...h2TextPrefab()}
          theme={getCounterTheme(theme)}
          brand={brand}
          className={`${classPrefix}__title`}
          weight={700}
          align={desktopView ? 'align-center' : 'align-left'}
        >
          {title}
        </Text>
      )}
      <StyleGrid
        className={`${classPrefix}__content`}
        brand={brand}
        direction='column'
        alignment='top-center'
        gap={desktopView ? 10 : 4}
      >
        {_renderServiceBlockList()}
      </StyleGrid>
    </ContentGrid>
  )
}
