import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../data'
import { createString, getText } from '../../services'
import { IDefaultProps } from '../../models'

export interface IAccessDeniedPageData extends IDefaultProps {
  accessTo: string
}

export const getPageContent = async (config: IAccessDeniedPageData) => {
  const { translations, accessTo } = config

  if (translations) {
    return {
      ...config,
      title: createString(getText('title', translations), {
        accessTo
      }),
      description: createString(getText('description', translations), {
        accessTo
      }),
      action: {
        text: getText('back', translations),
        link: Constants.paths.energyPage,
        icon: IconType.HouseIcon
      }
    }
  }
}
