import {
  BrandColors,
  IThemedColors,
  LocationType,
  MasterPlankTemplate,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

const _palette = {
  Light: {
    background: { color: BrandColors['primary-shade-light-1'] },
    text: { color: BrandColors['text-shade-dark-3'] },
    border: { color: BrandColors['none'] },
    icon: { color: BrandColors['primary-shade-light-3'] },
    hover: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.8
      },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    focus: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.8
      },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    },
    disabled: {
      background: {
        color: BrandColors['primary-shade-light-1'],
        opacity: 0.5
      },
      text: { color: BrandColors['text-shade-light-1'], opacity: 0.5 },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-3'] }
    }
  },
  Dark: {}
} as IThemedColors

export const PrimaryPlankTemplate = (theme: Theme = 'Light', location: LocationType = 'single') => {
  let temp = { ...MasterPlankTemplate(theme, location) }

  temp.palette = _palette[theme]

  return temp
}
