import { BrandColors, IComponentTransform } from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  width: 'auto',
  height: 'auto',
  border: {
    radiusType: 'roundedOff'
  },
  padding: '0.5rem 1.5rem',
  grid: {
    direction: 'row',
    alignment: 'center',
    gap: 1
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['none'] },
      text: { color: BrandColors['primary-shade-light-2'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      hover: {
        background: { color: BrandColors['none'] },
        text: { color: BrandColors['primary-shade-light-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['primary-shade-light-3'] }
      },
      active: {
        background: { color: BrandColors['primary-shade-dark-1'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      activeHover: {
        background: { color: BrandColors['primary-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      }
    },
    Dark: {
      background: { color: BrandColors['none'] },
      text: { color: BrandColors['primary-shade-light-2'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      hover: {
        background: { color: BrandColors['none'] },
        text: { color: BrandColors['primary-shade-light-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['primary-shade-light-3'] }
      },
      active: {
        background: { color: BrandColors['primary-shade-dark-1'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      activeHover: {
        background: { color: BrandColors['primary-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      }
    }
  },
  'brand-trondelagkraft': {
    Light: {
      background: { color: BrandColors['none'] },
      text: { color: BrandColors['primary-shade-light-2'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      hover: {
        background: { color: BrandColors['none'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-dark-3'] }
      },
      active: {
        background: { color: BrandColors['primary-shade-light-1'] },
        text: { color: BrandColors['text-shade-dark-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      activeHover: {
        background: { color: BrandColors['primary-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      }
    },
    Dark: {
      background: { color: BrandColors['none'] },
      text: { color: BrandColors['primary-shade-light-2'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      hover: {
        background: { color: BrandColors['none'] },
        text: { color: BrandColors['primary-shade-light-3'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['primary-shade-light-3'] }
      },
      active: {
        background: { color: BrandColors['primary-shade-dark-1'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      },
      activeHover: {
        background: { color: BrandColors['primary-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        border: { color: BrandColors['none'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      }
    }
  }
}

// ************************************
// Export
// ************************************

export const BUTTON_TEMPLATE_MENU = {
  themedPalette: _palette,
  transform: _transform
}
