import React, { useEffect, useState } from 'react'
import {
  IComponent,
  FlexGridAligner,
  Text,
  getCounterTheme,
  IconType,
  BrandColors,
  Icon
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { useApplicationContext } from '../../../contexts'
import { MsButton } from '../../../components'
import { BenefitListItemModalContent } from '../shared/BenefitListItemModalContent'
import './BenefitListItemModalMobile.scss'
import { MS_ButtonTemplate } from '../../../Prefabs'
import { ListItemCategories, ListItemSponsor } from '../../../blocks'
import { IBenefit } from '../../../models'

export interface IBenefitListItemModalMobile extends IComponent {
  data: IBenefit | null
  recruitLink?: string
  open: boolean
  close: () => void
}
/**
 *    The BenefitListItemModalMobile is used to show benefit information on mobile. It has a drag interaction to close it.
 *    @type {string} text
 */
export const BenefitListItemModalMobile = (props: IBenefitListItemModalMobile) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { id, data, recruitLink, open, close } = props
  const classPrefix = 'benefit-list-item-modal-mobile'

  // ************************************
  // Lifecycle
  // ************************************

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalFadingOut, setModalFadingOut] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => setModalVisible(true), 50)

      let modal = document.querySelector(`.${classPrefix}__content`)
      if (modal) {
        modal.addEventListener('touchstart', startDrag)
        modal.addEventListener('touchmove', drag)
        modal.addEventListener('touchend', endDrag)
      }
    }
  }, [open])

  // ************************************
  // Touch Interaction (not safari compatible)
  // ************************************

  let startY = 0
  let dragDistance = 0
  let startTime = 0
  let dragging = false

  const startDrag = (event: any) => {
    // dragging starts the moment a touch is registered as moving across the screen.
    // Because the finger dragging across the screen doesn't mean we are dragging the modal,
    // we can use the startDrag event as a reset for the modal dragging.
    dragging = false
  }

  const drag = (event: any) => {
    let modal = document.querySelector(`.${classPrefix}__content`) as HTMLElement

    // we only want to start dragging the modal, if we're scrolled to top
    if (modal.scrollTop === 0) {
      // problem is we already have a bunch of dragging points from earlier,
      // so we need the latest one here, and to start "drag" afterwards
      if (!dragging) {
        startY = event.touches[event.touches.length - 1].clientY
        startTime = performance.now()
      }
      dragging = true
      let dragY = event.touches[event.touches.length - 1].clientY
      dragDistance = dragY - startY
      if (modal && dragDistance > 0) {
        modal.style.transform = `translateY(${dragDistance}px)`
        modal.style.removeProperty(`transition`)
        modal.style.overflowY = 'hidden'
      }
    }
  }

  const endDrag = (event: any) => {
    let modal = document.querySelector(`.${classPrefix}__content`) as HTMLElement
    modal.style.transition = `0.5s`
    modal.style.removeProperty(`transform`)
    modal.style.overflowY = 'auto'
    if (dragDistance > window.innerHeight * 0.4) {
      closeModal()
    }
    let endTime = performance.now()
    let acceleration = dragDistance / (endTime - startTime)
    if (acceleration > 1) {
      // 1 pixel pr ms seems a good limit
      closeModal()
    }
  }

  const closeModal = () => {
    close()
    setModalFadingOut(true)
    setModalVisible(false)
    setTimeout(() => {
      setModalFadingOut(false)
    }, 500)
  }

  // ************************************
  // Render
  // ************************************
  return (
    <FlexGridAligner
      id={id}
      className={classnames(`${classPrefix}`, {
        [`${classPrefix}--visible`]: open,
        [`${classPrefix}--fading-out`]: modalFadingOut
      })}
      brand={activeBrand}
      theme={activeTheme}
      alignment={'center'}
      direction={'row'}
    >
      {data && (
        <FlexGridAligner
          className={classnames(`${classPrefix}__content`, {
            [`${classPrefix}__content--visible`]: modalVisible
          })}
          alignment={'top-left'}
          direction={'column'}
          wrap={false}
        >
          <MsButton
            className={`${classPrefix}__content__close-button`}
            theme={activeTheme}
            brand={activeBrand}
            template={{
              ...MS_ButtonTemplate(activeTheme, 'icon'),
              palette: {
                background: {
                  color: BrandColors['background-shade-light-1']
                }
              }
            }}
            action={{
              onClick: () => {
                closeModal()
              }
            }}
          >
            <Icon type={IconType.Close} />
          </MsButton>

          <FlexGridAligner
            className={`${classPrefix}__content__image`}
            style={{
              backgroundImage: `linear-gradient(
                            to bottom,
                            transparent,
                            80%,
                            rgba(0, 0, 0, 1)
                        ), url('${data.imageUrl}')`
            }}
            alignment={'bottom-left'}
            direction={'column'}
          >
            {data.categories && <ListItemCategories categories={data.categories} />}

            <Text
              type={'h3'}
              weight={500}
              brand={activeBrand}
              theme={desktopView ? getCounterTheme(activeTheme) : activeTheme}
            >
              {data.heading}
            </Text>
          </FlexGridAligner>

          <FlexGridAligner
            className={`${classPrefix}__content__sponsor`}
            alignment={'center-left'}
            direction={'row'}
            wrap={false}
            spaceBetween
          >
            <ListItemSponsor
              name={data.sponsorName}
              text={data.sponsorText}
              logoUrl={data.sponsorLogoUrl}
            />
            <Text
              className={`${classPrefix}__content__sponsor__text`}
              type={'p'}
              size={'tiny'}
              brand={activeBrand}
              theme={getCounterTheme(activeTheme)}
              color={BrandColors['text-shade-light-1']}
            >
              {data.sponsorTag}
            </Text>
          </FlexGridAligner>

          <FlexGridAligner
            className={`${classPrefix}__content__text`}
            alignment={'top-left'}
            direction={'column'}
          >
            <BenefitListItemModalContent
              data={data}
              recruitLink={recruitLink}
            />
          </FlexGridAligner>
        </FlexGridAligner>
      )}
    </FlexGridAligner>
  )
}
