import { BrandColors, IComponentTemplate, IComponentTransform, Theme } from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = () => {
  return {
    padding: '0.4rem',
    border: {
      radiusType: 'curved'
    },
    grid: {
      gap: 1
    }
  } as IComponentTransform
}

// ************************************
// Palette
// ************************************

const _palette = (brand: string) => {
  const basePalette = {
    Light: {
      background: { color: BrandColors['primary-shade-light-3'] },
      text: { color: BrandColors['text-shade-light-1'] },
      icon: { color: BrandColors['text-shade-light-1'] },
      hover: {
        background: { color: BrandColors['primary-shade-light-2'] },
        text: { color: BrandColors['text-shade-light-1'] },
        icon: { color: BrandColors['text-shade-light-1'] }
      }
    },
    Dark: {
      background: { color: BrandColors['primary-shade-dark-2'] },
      text: { color: BrandColors['text-shade-dark-1'] },
      icon: { color: BrandColors['text-shade-dark-1'] },
      hover: {
        background: { color: BrandColors['primary-shade-dark-3'] },
        text: { color: BrandColors['action-shade-dark-1'] },
        icon: { color: BrandColors['action-shade-dark-1'] }
      }
    }
  }
  if (brand === 'brand-trondelagkraft') {
    basePalette.Light.text.color = BrandColors['tertiary-shade-light-1']
    basePalette.Light.icon.color = BrandColors['tertiary-shade-light-1']
    basePalette.Dark.text.color = BrandColors['tertiary-shade-light-1']
    basePalette.Dark.icon.color = BrandColors['tertiary-shade-light-1']
  }
  return basePalette
}

export const AppStatusButtonTemplate = (theme: Theme, brand: string) => {
  return {
    palette: _palette(brand)[theme],
    transform: _transform()
  } as IComponentTemplate
}
