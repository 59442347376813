import React from 'react'
import {
  ContentGrid,
  getCounterTheme,
  IContentGrid,
  IconType,
  IText,
  StyleGrid,
  Text,
  Theme
} from '@fjordkraft/fjordkraft.component.library'
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../Prefabs'
import './InfoGrid.scss'
import { v4 as uuid4 } from 'uuid'
import { ActionButton } from '../Buttons/ActionButton'
import { MSTooltip } from '../Buttons/MSTooltip'
import classnames from 'classnames'

export type IInfoGridRow = {
  title?: string
  value?: string
  buttonRight?: {
    icon?: IconType
    onClick?: () => void
    title?: string
    isDisabled: boolean
    buttonType: 'tooltip' | 'button'
  }
}

interface IInfoGrid {
  view?: 'desktop' | 'mobile'
  rows: IInfoGridRow[]
}

export const InfoGrid = ({ view = 'desktop', rows }: IInfoGrid) => {
  const classPrefix = 'info-grid'
  const isDesktopView = view === 'desktop'

  return (
    <StyleGrid
      direction={'column'}
      alignment={'top-center'}
      className={classPrefix}
      gap={8}
    >
      <StyleGrid
        className={`${classPrefix}__customer-info`}
        direction='column'
        alignment='top-left'
        gap={2}
        boxSizing='border-box'
      >
        {rows.map(row => {
          return (
            <DesktopCustomerInfoLine
              key={uuid4()}
              row={row}
              classPrefix={classPrefix}
              isDesktopView={isDesktopView}
            />
          )
        })}
      </StyleGrid>
    </StyleGrid>
  )
}

const DesktopCustomerInfoLine = ({
  row,
  classPrefix,
  activeTheme,
  activeBrand,
  isDesktopView
}: {
  row: IInfoGridRow
  classPrefix: string
  activeTheme?: Theme
  activeBrand?: string
  isDesktopView: boolean
}) => {
  const gridProps = {
    className: `${classPrefix}__customer-info-line__wrapper__text`,
    direction: 'column',
    alignment: 'center-left',
    boxSizing: 'border-box',
    wrap: false,
    tagType: 'section'
  } as IContentGrid

  const textLineProps = {
    ...paragraphTextPrefab(),
    theme: getCounterTheme(activeTheme),
    brand: activeBrand,
    weight: 400,
    align: 'align-left'
  } as IText

  return (
    <StyleGrid
      {...gridProps}
      className={`${classPrefix}__customer-info-line`}
      direction='row'
      spaceBetween
    >
      <StyleGrid
        className={classnames(`${classPrefix}__customer-info-line__wrapper`, {
          [`${classPrefix}__customer-info-line__mobile-wrapper`]: !isDesktopView
        })}
        direction={isDesktopView ? 'row' : 'column'}
        alignment={'center-left'}
      >
        <ContentGrid {...gridProps}>
          {' '}
          <Text {...textLineProps}> {row.title} </Text>{' '}
        </ContentGrid>
        <ContentGrid {...gridProps}>
          {' '}
          <Text
            {...textLineProps}
            weight={500}
          >
            {' '}
            {row.value}{' '}
          </Text>{' '}
        </ContentGrid>
      </StyleGrid>
      <ContentGrid
        {...gridProps}
        tagType={'nav'}
        className={`${classPrefix}__customer-info-line__action`}
        alignment={'center-right'}
      >
        {row.buttonRight?.buttonType === 'button' && (
          <InfoGridActionButton
            activeTheme={activeTheme}
            row={row}
            isDesktopView={isDesktopView}
          />
        )}
        {row.buttonRight?.buttonType === 'tooltip' && (
          <InfoGridTooltipButton
            activeBrand={activeBrand}
            activeTheme={activeTheme}
            desktopView={isDesktopView}
            row={row}
          />
        )}
      </ContentGrid>
    </StyleGrid>
  )
}

const InfoGridTooltipButton = ({
  activeBrand,
  activeTheme,
  desktopView,
  row
}: {
  activeBrand?: string
  activeTheme?: Theme
  desktopView: boolean
  row: IInfoGridRow
}) => {
  return (
    <MSTooltip
      brand={activeBrand}
      theme={getCounterTheme(activeTheme)}
      alignment={desktopView ? 'bottom-center' : 'center-left'}
      text={row?.buttonRight?.title ?? ''}
    />
  )
}

const InfoGridActionButton = ({
  activeTheme,
  row,
  isDesktopView
}: {
  activeTheme?: Theme
  row: IInfoGridRow
  isDesktopView?: boolean
}) => {
  const { icon = IconType.Edit, onClick, title, isDisabled = false } = row.buttonRight ?? {}
  return (
    <ActionButton
      template={MS_ButtonTemplate(activeTheme, 'link')}
      action={{
        icon,
        onClick,
        ...(isDesktopView && { text: title }),
        disabled: isDisabled
      }}
      padding={'small'}
      iconPlacement='Right'
    />
  )
}
