import React, { useState, useEffect, useMemo } from 'react'
import {
  ContentGrid,
  IAction,
  IMasterPlankTemplate,
  IconType,
  StyleGrid
} from '@fjordkraft/fjordkraft.component.library'
import { useSubPageLayoutContext, useDefaultPageContext, useApplicationDefaultContext } from '../../../contexts'
import { ICustomerAccountInformation, IServicePage, ISteddiResponse } from '../../../models'
import { ActionButton } from '../../../components'
import { IPlankHouse, PlankHouseBlock } from '../../../blocks'
import { MS_ButtonTemplate, getPlankPrefab, IPointPlankPrefab } from '../../../Prefabs'
import { IResponse, getEpiServicePage, getText } from '../../../services'
import { ITrumfTransactions } from '../ServicePage/Datahandling/UniqueServices'
import {
  getParsedTrumfTransactions,
  getPredictablePaymentTransactionsPageContent,
  getPredictablePaymentTransactionsHouse,
  getTrumfTransactionsPageContent
} from './ServiceTransactionsPageData'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import './ServiceTransactionsPage.scss'

interface ILoadingState {
  loadedMax: boolean
  loadedAmount: number
}

export interface IServiceTransactionPage {
  translation: IServicePage
  title: string
  back: IAction
  overviewData: {
    title: string
    description: string
    extra: string
    amount: number
    suffix: string
    template?: IMasterPlankTemplate
  }
}

export const ServiceTransactionsPage = (props: { serviceId: string }) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'service-transactions-page'
  const { defaultProps } = useApplicationDefaultContext()
  const { setTitle, setBack } = useSubPageLayoutContext()
  const { setContentLoading } = useDefaultPageContext()
  const { predictablePaymentAccountNumber } = useParams()
  const minimumCount: number = 10

  // ************************************
  // Lifecycle
  // ************************************

  const [pageContent, setPageContent] = useState<any>()
  const [loadingContentState, setLoadingContentState] = useState<ILoadingState>()
  const [transactionsHouse, setTransactionsHouse] = useState<IPlankHouse>()

  useEffect(() => {
    const { user, epiChildren } = defaultProps
    const { userData } = user

    setContentLoading(true)

    if (userData && epiChildren) {
      if (props.serviceId === 'trumf') {
        let servicePage: IServicePage = getEpiServicePage(epiChildren?.HeadlessServicesPage, 'trumf')
        _setupTrumfContent(servicePage)
      }
      if (props.serviceId === 'forutsigbar') {
        if (predictablePaymentAccountNumber) {
          let servicePage: IServicePage = getEpiServicePage(epiChildren?.HeadlessServicesPage, 'forutsigbar')
          let account = userData.accounts.find(x => x.accountId.toString() === predictablePaymentAccountNumber)

          if (account?.steddiInfo) {
            _setupPredictablePaymentContent(account, servicePage)
          }
        }
      }
    }
  }, [defaultProps, predictablePaymentAccountNumber])

  const _generalPageSetupHandling = (pageContent: any) => {
    setLoadingContentState({
      loadedAmount: minimumCount,
      loadedMax: false
    })
    setTitle(pageContent.title)
    setBack(pageContent.back)
    setPageContent(pageContent)
    setContentLoading(false)
  }

  const _setupTrumfContent = async (servicePage: IServicePage) => {
    const { GET } = defaultProps.services

    if (servicePage.servicePageId === 'trumf') {
      let resp: IResponse = await GET('trumf/transactions')

      if (resp.callState === 'success' && resp.data) {
        let trumfData = resp.data as ITrumfTransactions
        let pageContent: any = getTrumfTransactionsPageContent({
          servicePage,
          trumfData
        })

        await _handleTransactionsHouse(servicePage)
        _generalPageSetupHandling(pageContent)
      } else if (resp.callState === 'error') {
        _generalPageSetupHandling(pageContent)
      }
    }
  }

  const _setupPredictablePaymentContent = async (account: ICustomerAccountInformation, servicePage: IServicePage) => {
    const { GETTYPED } = defaultProps.services

    const pageContent = await getPredictablePaymentTransactionsPageContent({
      account,
      servicePage,
      GETTYPED
    })
    await _handleTransactionsHouse(servicePage, account.accountId)
    _generalPageSetupHandling(pageContent)
  }

  // ************************************
  // Helpers
  // ************************************

  const _handleLoadingInput = (total: number) => {
    let loadCount = loadingContentState?.loadedAmount ?? 0
    let loadedMax = loadingContentState?.loadedMax ?? false

    if (loadedMax) {
      loadCount -= 10
    } else {
      loadCount += 10
    }

    if (loadCount >= total) {
      loadCount = total
      loadedMax = true
    } else if (loadCount <= minimumCount) {
      loadCount = minimumCount
      loadedMax = false
    }

    setLoadingContentState({
      loadedAmount: loadCount,
      loadedMax
    })

    return { loadCount, loadedMax }
  }

  const _handleTransactionsHouse = async (translation: IServicePage, accountNumber?: string) => {
    const { GET } = defaultProps.services

    let house: IPlankHouse = {
      plankWalls: []
    }

    if (translation) {
      if (translation.servicePageId === 'trumf') {
        let resp: IResponse = await GET('trumf/transactions')
        if (resp.callState === 'success' && resp.data) {
          let trumfData: ITrumfTransactions = resp.data
          trumfData.transactions = _.orderBy(trumfData.transactions, ['date'], ['desc'])
          let amount = _handleLoadingInput(trumfData.transactions.length)
          house.plankWalls.push(getParsedTrumfTransactions(resp.data.transactions, translation, amount.loadCount))
        }
      }
      if (translation.servicePageId === 'forutsigbar' && accountNumber) {
        let resp: IResponse = await GET(`account/${accountNumber}/transactions/summary`)

        if (resp.callState === 'success' && resp.data) {
          const steddiData = resp.data as ISteddiResponse
          let amount = _handleLoadingInput(steddiData.invoicedTransactions.length)

          house = getPredictablePaymentTransactionsHouse({
            paid: steddiData.invoicedTransactions,
            unpaid: steddiData.nonInvoicedTransactions,
            loadAmount: amount.loadCount,
            translation: translation
          })
        }
      }
    }

    if (house.plankWalls.length > 0) {
      setTransactionsHouse(house)
    }
  }

  const _onTransactionsListRender = () => {
    if (loadingContentState?.loadedAmount !== minimumCount) {
      let ele = document.getElementById(`${classPrefix}-id`)

      if (ele) {
        ele.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderOverviewPlankHouse = (data: any) => {
    return (
      <PlankHouseBlock
        className={`${classPrefix}__plank-house`}
        brand={data.brand}
        plankWalls={[
          {
            planks: [
              getPlankPrefab('Point', {
                brand: data.brand,
                template: data.template,
                useDecimals: true,
                left: {
                  title: data.title,
                  description: data.description
                },
                right: data.extra
                  ? {
                      description: data.extra
                    }
                  : undefined,
                points: data.amount,
                pointsLabel: data.suffix,
                theme: data.theme
              } as IPointPlankPrefab)
            ]
          }
        ]}
      />
    )
  }

  const _renderTransactionsHouse = useMemo(() => {
    const { activeBrand, activeTheme } = defaultProps

    if (transactionsHouse) {
      return (
        <PlankHouseBlock
          {...transactionsHouse}
          className={`${classPrefix}__transactions-grid`}
          theme={activeTheme}
          brand={activeBrand}
          onReRender={() => {
            _onTransactionsListRender()
          }}
        />
      )
    }
  }, [transactionsHouse, defaultProps, loadingContentState])

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      className={classPrefix}
      direction='column'
      alignment='top-center'
      gap={4}
      wrap={false}
    >
      {pageContent?.overviewData && _renderOverviewPlankHouse(pageContent.overviewData)}
      {_renderTransactionsHouse}
      <ContentGrid
        className={`${classPrefix}__bottom`}
        direction='column'
        alignment='top-center'
        tagType='nav'
        gap={2}
      >
        {loadingContentState && defaultProps.translations && transactionsHouse && !loadingContentState.loadedMax && (
          <ActionButton
            brand={defaultProps.activeBrand}
            template={MS_ButtonTemplate(defaultProps.activeTheme, 'primary')}
            iconPlacement={'Right'}
            padding='default'
            action={{
              onClick: () => {
                _handleTransactionsHouse(defaultProps.translations, predictablePaymentAccountNumber)
              },
              text: getText('moreTransactionsText', defaultProps.translations),
              icon: IconType.PlusThin
            }}
          />
        )}
      </ContentGrid>
    </StyleGrid>
  )
}
