import React from 'react'
import {
  IComponent,
  Text,
  Button,
  IAction,
  Icon,
  IconType,
  IComponentTemplate
} from '@fjordkraft/fjordkraft.component.library'
import { useApplicationContext } from '../../../contexts'
import { MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs'

export interface IArrowButton extends IComponent {
  useTextUnderline?: boolean
  iconLocation: 'left' | 'right'
  action: IAction
  template?: IComponentTemplate
}

/**
 *    The ArrowButton is used to -FILL IN-
 *    @type {string} text
 */
export const ArrowButton = (props: IArrowButton) => {
  // ************************************
  // Properties
  // ************************************

  const { activeBrand } = useApplicationContext()
  const {
    className,
    useTextUnderline,
    iconLocation,
    action,
    disabled,
    theme = 'Light',
    template = MS_ButtonTemplate(theme, 'arrow')
  } = props

  const _renderIcon = (icon: IconType) => {
    return (
      <Icon
        brand={activeBrand}
        type={icon}
      />
    )
  }

  // ************************************
  // Render
  // ************************************
  return (
    <Button
      className={className}
      brand={activeBrand}
      action={action ? action : {}}
      disabled={disabled}
      template={template}
    >
      {iconLocation === 'left' && _renderIcon(IconType.ArrowLeft)}
      <Text
        brand={activeBrand}
        {...paragraphTextPrefab()}
        weight={600}
        underline={useTextUnderline}
      >
        {action.text}
      </Text>
      {iconLocation === 'right' && _renderIcon(IconType.ArrowRight)}
    </Button>
  )
}
