import React from 'react'
import { BrandColors, IMasterPlank, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import { IBlock } from '../../models'
import {
  IMonthlyTransaction,
  ITransaction,
  IYearlyTransaction
} from '../../pages/BenefitsPage/TransactionsPage/TransactionsPageData'
import { v4 as uuid4 } from 'uuid'
import classNames from 'classnames'
import { IMSPlankWall } from '../PlankWallBlock'
import { getPlankPrefab } from '../../Prefabs'
import { PlankHouseBlock } from '../PlankHouseBlock/PlankHouseBlock'
import { capitalizeFirstLetter, createString, getText } from '../../services'
import { format } from 'date-fns'
import './KickbackTransactionsBlock.scss'

export interface IKickbackTransactionsBlock extends IBlock {
  transactions: IYearlyTransaction[]
  expiringTransactions?: ITransaction[]
  translations: any
}

export const KickbackTransactionsBlock = (props: IKickbackTransactionsBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme, brand, transactions, translations, expiringTransactions } = props
  const classPrefix = 'kickback-transactions-block'

  // ************************************
  // Helper Functionality
  // ************************************

  const _isNegative = (transaction: ITransaction) => transaction.amount < 0
  const _getTransactionTypeIdColor = (transaction: ITransaction) =>
    transaction.typeId === 'Pending' ? BrandColors['primary-shade-light-2'] : BrandColors['text-shade-light-3']

  const _getTransactionTitleColor = (transaction: ITransaction) =>
    _isNegative(transaction) ? BrandColors['status-shade-light-3'] : BrandColors['status-shade-light-1']

  const _getParsedTransactionAmount = (transaction: ITransaction) =>
    _isNegative(transaction) ? `${transaction.amount}` : `+${transaction.amount}`

  const _getExpiringPlankDateOriginDate = (transaction: ITransaction) => {
    let prefix: string = getText(`transaction${transaction.typeId}`, translations)
    let suffix: string = `${format(new Date(transaction.dateTime), 'dd.MM.yyyy')}`
    return `${prefix} ${suffix}`
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderYearHouses = () => {
    let houses: any[] = []

    if (expiringTransactions && expiringTransactions.length > 0) {
      houses.push(
        <PlankHouseBlock
          key={uuid4()}
          title={getText('expiringTransactionsTitle', translations)}
          plankWalls={[
            {
              planks: _renderExpiringPlanks(expiringTransactions)
            }
          ]}
        />
      )
    }

    if (transactions.length > 0) {
      transactions.forEach((year: IYearlyTransaction) => {
        houses.push(
          <PlankHouseBlock
            key={uuid4()}
            title={`${year.year}`}
            titleGap={1}
            plankWalls={_renderMonthWalls(year)}
          />
        )
      })
    }

    return houses
  }

  const _renderExpiringPlanks = (expiringTransactions: ITransaction[]) => {
    let planks: IMasterPlank[] = []

    expiringTransactions.forEach((transaction: ITransaction) => {
      if (transaction.expiryDate) {
        planks.push(
          getPlankPrefab('Text', {
            left: {
              title: transaction.message,
              description: createString(getText('plankBenefitTransactionExpirationDesc', translations), {
                date: format(new Date(transaction.expiryDate), 'dd.MM.yyyy')
              }),
              customization: {
                description: {
                  color: BrandColors['status-shade-light-3']
                }
              }
            },
            right: {
              title: _getParsedTransactionAmount(transaction),
              description: _getExpiringPlankDateOriginDate(transaction),
              customization: {
                title: {
                  color: BrandColors['text-shade-light-3']
                },
                description: {
                  color: _getTransactionTypeIdColor(transaction)
                }
              }
            }
          })
        )
      }
    })

    return planks
  }

  const _renderMonthWalls = (year: IYearlyTransaction) => {
    let plankWalls: IMSPlankWall[] = []

    year.months.forEach((month: IMonthlyTransaction) => {
      plankWalls.push({
        title: `${capitalizeFirstLetter(month.monthStr)}`,
        titleCustomization: {
          size: 'regular',
          weight: 500,
          faded: true
        },
        titleGap: 1,
        planks: _renderTransactionPlanks(month.transactions)
      } as IMSPlankWall)
    })

    return plankWalls
  }

  const _renderTransactionPlanks = (monthlyTransactions: ITransaction[]) => {
    let planks: IMasterPlank[] = []

    if (monthlyTransactions.length > 0) {
      monthlyTransactions.forEach((transaction: ITransaction) => {
        planks.push(
          getPlankPrefab('Text', {
            left: {
              title: transaction.message,
              description: transaction.date,
              customization: {
                description: {
                  color: BrandColors['text-shade-light-3']
                }
              }
            },
            right: {
              title: _getParsedTransactionAmount(transaction),
              description: transaction.typeStatus,
              customization: {
                title: {
                  color: _getTransactionTitleColor(transaction)
                },
                description: {
                  color: _getTransactionTypeIdColor(transaction)
                }
              }
            }
          })
        )
      })
    }

    return planks
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      brand={brand}
      theme={theme}
      alignment={'center'}
      direction={'row'}
      gap={2}
    >
      {_renderYearHouses()}
    </StyleGrid>
  )
}
