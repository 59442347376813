import {
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

const _transform = (desktopView: boolean) => {
  return {
    height: 'auto',
    width: desktopView ? '15rem' : '100%',
    padding: desktopView ? '1rem' : '1.25rem 1rem',
    border: desktopView
      ? {
          radiusType: 'curved',
          type: 'solid',
          thickness: 3
        }
      : undefined,
    grid: {
      boxSizing: 'border-box',
      gap: 1,
      direction: 'column',
      alignment: 'top-left'
    }
  } as IComponentTransform
}

const _palette = {
  Light: {
    background: { color: BrandColors['background-shade-light-1'] },
    text: { color: BrandColors['text-shade-dark-3'] },
    icon: { color: BrandColors['status-shade-dark-3'] }
  },
  Dark: {
    background: { color: BrandColors['background-shade-dark-3'] },
    text: { color: BrandColors['text-shade-light-1'] },
    icon: { color: BrandColors['status-shade-light-3'] }
  }
} as IThemedColors

export const MessageToastTemplate = (theme: Theme, desktopView: boolean): IComponentTemplate => {
  return {
    transform: _transform(desktopView),
    palette: _palette[theme]
  }
}
