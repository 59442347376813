import { format } from 'date-fns'
import { IAreaChartCard } from '../../components'
import { Constants } from '../../data'
import { BrandColors, IChartHighlightIndicator } from '@fjordkraft/fjordkraft.component.library'
import { ICustomerInstallation, ICustomerSpotPrice, ICustomerTimeSeries, IDefaultProps } from '../../models'
import { createString, tNumber, getText } from '../../services'

export interface ISpotPricePageData extends IDefaultProps {}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: ISpotPricePageData) => {
  const { user, translations, services } = config
  const { installation } = user
  const { GET } = services

  if (installation && GET !== undefined) {
    let priceArea: string = installation?.address?.priceArea ?? ''
    let postalCode: string = installation?.address?.postalCode ?? ''
    let resp = await GET(`SpotPrice/${priceArea}/${postalCode}/today`)
    let chartData: IAreaChartCard = {
      cardHeader: {
        title: getText('pageTitle', translations),
        subTitle: getText('description', translations)
      },
      cardTopLeftText: {
        title: '',
        titleSuffix: getText('currency', translations)
      },
      series: [],
      highlights: []
    }

    if (installation.address.priceArea || installation.address.postalCode) {
      chartData = _getChartData(translations, resp.data, installation)
    }

    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: {
          text: installation?.address.streetAddress,
          link: Constants.paths.energyPage
        },
        subTitle: getText('description', translations)
      },
      chartData: chartData
    }
  }
}

// ************************************
// PRIVATE
// ************************************

const _getChartData = (translation: any, today: ICustomerSpotPrice, installation: ICustomerInstallation) => {
  let currentTime = new Date().getHours()
  let currentValue = 0
  let categories: string[] = []
  let seriesToday: any = {
    name: '',
    data: []
  }

  let seriesLater: any = {
    name: '',
    data: []
  }

  for (let i = 0; i < 24; i++) {
    let prefix = i <= 9 ? `0${i}` : `${i}`
    categories.push(`${prefix}:00`)
  }

  if (today?.timeSeries?.length > 0) {
    today.timeSeries.forEach((timeSerie: ICustomerTimeSeries) => {
      let xVal: number = new Date(timeSerie.startTime).getHours()

      if (xVal === currentTime) {
        currentValue = timeSerie.cost
      }

      if (xVal <= currentTime) {
        seriesToday.data.push(timeSerie.cost)
      }

      seriesLater.data.push(timeSerie.cost)
    })
  }

  let chartTitle = getText('notAvailable', translation)

  if (installation?.address.priceArea) {
    chartTitle = getText(`region${[installation.address.priceArea]}`, translation)
  }

  return {
    cardHeader: {
      title: chartTitle,
      subTitle: createString(getText('chartDescription', translation), {
        address: installation.address.streetAddress
      })
    },
    cardTopLeftText: {
      title: `${tNumber(currentValue, 'no-NO', 2)}`,
      titleSuffix: today?.unit ?? getText('currency', translation)
    },
    highlights: [
      {
        type: 'annotation',
        x: `${format(new Date(), 'HH')}:00`,
        color: BrandColors['primary-shade-light-2']
      } as IChartHighlightIndicator,
      {
        type: 'point',
        x: `${format(new Date(), 'HH')}:00`,
        y: currentValue,
        color: BrandColors['primary-shade-light-2'],
        outlineColor: BrandColors['primary-shade-light-1']
      } as IChartHighlightIndicator
    ],
    categories: categories,
    series: [seriesToday, seriesLater]
  }
}
