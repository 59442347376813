import React, { useMemo, useState } from 'react'
import {
  BaseComponent,
  BrandColors,
  CheckInput,
  ContentGrid,
  getCounterTheme,
  IAction,
  IComponent,
  StyleGrid,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import { PopupCard } from '../PopupCard/PopupCard'
import { getText } from '../../services'
import { v4 as uuid4 } from 'uuid'
import { h3TextPrefab, h4TextPrefab, MSCheckInputTemplate, paragraphTextPrefab } from '../../Prefabs'
import {
  IDetailCheckboxItemData,
  IDetailCheckboxListItem
} from '../../blocks/DetailCheckboxCollectionBlock/DetailCheckboxItem/DetailCheckboxItem'
import {
  DetailCheckboxItemTemplate,
  IDetailCheckboxItemTemplate
} from '../../blocks/DetailCheckboxCollectionBlock/DetailCheckboxItem/DetailCheckboxItemTemplate'
import { MSRichText } from '../../components'
import { useConsentContext } from '../../contexts'
import { ConsentStateEnum, IConsent, MarketingConsentType } from '../../models'
import './ConsentPopupModal.scss'
import { formatConsentData } from '../../pages/MyPage/ConsentPage/ConsentPageData'

export interface IConsentPopupModal extends IComponent {
  translations: any
  onClose: () => void
}

export const ConsentPopupModal = (config: IConsentPopupModal) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand, translations, onClose } = config
  const classPrefix = 'consent-popup-modal'
  const { PUT_CONSENTS, consents, isReservedAgainstMarketing } = useConsentContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [stage, setStage] = useState<number>(0)
  const [consentData, setConsentData] = useState<IDetailCheckboxItemData[]>(
    formatConsentData(consents, translations, id)
  ) //formatConsentData());

  const _updateConsentSelection = (consents: IDetailCheckboxItemData[], value: any, state: boolean) => {
    for (const c of consents) {
      if (c.id === value) {
        c.state = state ? ConsentStateEnum.ACCEPTED : ConsentStateEnum.DECLINED
        break
      }
    }
    setConsentData(consents)
  }

  const _save = async () => {
    let consentsToUpdate = consentData.map(consent => ({
      consentName: consent.id,
      value: consent.state
    })) as IConsent[]
    consentsToUpdate = consentsToUpdate.map(consent =>
      consent.value === ConsentStateEnum.PENDING ? { ...consent, value: ConsentStateEnum.DECLINED } : consent
    )

    const reservedAgainstMarketingIsPending = consents?.find(
      consent =>
        consent.consentName === MarketingConsentType.DisableMarketing && consent.value === ConsentStateEnum.PENDING
    )

    if (isReservedAgainstMarketing || reservedAgainstMarketingIsPending) {
      consentsToUpdate.push({ consentName: MarketingConsentType.DisableMarketing, value: ConsentStateEnum.DECLINED })
    }
    PUT_CONSENTS(consentsToUpdate, translations)
    onClose()
  }

  // ************************************
  // Handling
  // ************************************

  const _getTitle = (): string => {
    return getText(`popupTitle_${consentData[stage]?.id}`, translations)
  }

  const _getPrimaryAction = (): IAction => {
    let textKey: string = 'goNext'

    if (stage === consentData?.length - 1) {
      textKey = 'save'
    }

    return {
      text: getText(textKey, translations),
      onClick: () => {
        if (stage < consentData?.length - 1) {
          setStage(stage + 1)
        } else {
          _save()
        }
      }
    }
  }

  const _getSecondaryAction = (): IAction => {
    let textKey: string = 'close'

    if (stage > 0) {
      textKey = 'goBack'
    }

    return {
      text: getText(textKey, translations),
      onClick: () => {
        if (stage > 0) {
          setStage(stage - 1)
        } else {
          onClose()
        }
      }
    }
  }

  const _customListTemplate = (): IDetailCheckboxItemTemplate => {
    let base = DetailCheckboxItemTemplate(theme)

    base.list.palette.background = {
      color: BrandColors['background-shade-light-1']
    }
    base.list.transform.width = '94%'
    base.list.transform.margin = '0 0 0 6%'

    return base
  }

  const _isCheckboxActive = (consent: IDetailCheckboxItemData): boolean => {
    return consent.state === 'ACCEPTED'
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderListItems = (items: IDetailCheckboxListItem[]) => {
    return items.map((item: IDetailCheckboxListItem) => {
      return (
        <ContentGrid
          key={uuid4()}
          direction='column'
          alignment='top-left'
          tagType='section'
          boxSizing='border-box'
        >
          <Text
            {...h4TextPrefab()}
            theme={getCounterTheme(theme)}
          >
            {item.name}
          </Text>
          <Text
            {...paragraphTextPrefab()}
            theme={getCounterTheme(theme)}
            faded
          >
            {item.description}
          </Text>
        </ContentGrid>
      )
    })
  }

  const _renderConsent = useMemo(() => {
    if (consentData?.length) {
      let index = 0

      for (const consent of consentData) {
        if (stage === index) {
          return (
            <StyleGrid
              className={`${classPrefix}__consent`}
              alignment='top-left'
              direction='column'
              boxSizing='border-box'
              gap={2}
            >
              <StyleGrid
                className={`${classPrefix}__consent__top`}
                alignment='top-left'
                direction='row'
                boxSizing='border-box'
                gap={1}
                wrap={false}
              >
                <ContentGrid
                  className={`${classPrefix}__consent__top__left`}
                  alignment='top-left'
                  direction='row'
                  boxSizing='border-box'
                  tagType='section'
                >
                  <CheckInput
                    key={consent.id}
                    template={MSCheckInputTemplate(theme, 'checkbox')}
                    active={_isCheckboxActive(consent)}
                    value={consent.id}
                    type='checkbox'
                    onChange={(value, state) => {
                      _updateConsentSelection(consentData, value, state ?? false)
                    }}
                  />
                </ContentGrid>
                <MSRichText
                  className={`${classPrefix}__consent__top__right`}
                  alignment='lawful'
                  text={consent.description}
                  theme={getCounterTheme(theme)}
                />
              </StyleGrid>
              {consent.listData && (
                <BaseComponent
                  className={`${classPrefix}__consent__list`}
                  template={_customListTemplate().list}
                >
                  {consent?.listData?.title && (
                    <Text
                      {...h3TextPrefab()}
                      size='medium'
                    >
                      {consent.listData.title}
                    </Text>
                  )}
                  {consent?.listData?.items && (
                    <StyleGrid
                      className={`${classPrefix}__consent__list__view`}
                      direction='column'
                      alignment='top-left'
                      boxSizing='border-box'
                    >
                      <ContentGrid
                        className={`${classPrefix}__consent__list__view__container`}
                        tagType='section'
                        alignment='top-left'
                        direction='column'
                        boxSizing='border-box'
                        gap={3}
                      >
                        {_renderListItems(consent.listData.items)}
                      </ContentGrid>
                    </StyleGrid>
                  )}
                </BaseComponent>
              )}
            </StyleGrid>
          )
        }
        index++
      }
    }
  }, [consentData, stage])

  // ************************************
  // Render
  // ************************************

  return (
    <PopupCard
      id={id}
      className={className}
      theme={theme}
      brand={brand}
      contentTitle={_getTitle()}
      onClose={onClose}
      primaryAction={_getPrimaryAction()}
      secondaryAction={_getSecondaryAction()}
    >
      {_renderConsent}
    </PopupCard>
  )
}
