import React from 'react'
import {
  FlexGridAligner,
  Text,
  Modal,
  getCounterTheme,
  IconType,
  BrandColors,
  Icon,
  ModalTemplate
} from '@fjordkraft/fjordkraft.component.library'
import { useApplicationContext } from '../../../contexts'
import { MsButton } from '../../../components'
import { BenefitListItemModalContent, IBenefitModal } from '../../../modals'
import { v4 as uuid4 } from 'uuid'
import { MS_ButtonTemplate } from '../../../Prefabs'
import { ListItemSponsorTag } from '../../../blocks'
import './BenefitListItemModalDesktop.scss'

export const BenefitListItemModalDesktop = (props: IBenefitModal) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { id, data, open, close, recruitLink } = props
  const classPrefix = 'benefit-list-item-modal-desktop'

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {open && data && (
        <Modal
          id={id ? id : uuid4()}
          className={`${classPrefix}`}
          theme={activeTheme}
          brand={activeBrand}
          open={open}
          template={{
            ...ModalTemplate(),
            transform: {
              padding: '0',
              border: {
                radiusType: 'curved'
              }
            }
          }}
          onClose={() => {
            close()
          }}
        >
          <FlexGridAligner
            className={`${classPrefix}__content`}
            id={id}
            brand={activeBrand}
            theme={activeTheme}
            alignment={'center-left'}
            direction={'row'}
            wrap={false}
          >
            <FlexGridAligner
              className={`${classPrefix}__content__image`}
              style={{
                backgroundImage: 'url(' + data?.imageUrl + ')'
              }}
              alignment={'top-left'}
              direction={'column'}
              spaceBetween
            >
              {data.sponsorTag && (
                <ListItemSponsorTag
                  text={data.sponsorTag}
                  backgroundColor={BrandColors['secondary-shade-dark-3']}
                />
              )}

              <FlexGridAligner
                className={`${classPrefix}__content__image__sponsor`}
                alignment={'center-left'}
                direction={'row'}
              >
                {data.sponsorLogoUrl && (
                  <img
                    className={`${classPrefix}__content__image__sponsor__icon`}
                    src={data.sponsorLogoUrl}
                    alt={'sponsor logo'}
                  />
                )}

                <FlexGridAligner
                  className={`${classPrefix}__content__image__sponsor__desc`}
                  alignment={'center-left'}
                  direction={'column'}
                  gap={1}
                  spaceBetween
                >
                  {data.sponsorName && (
                    <Text
                      className={`${classPrefix}__content__image__sponsor__name`}
                      type={'p'}
                      size={'small'}
                      brand={activeBrand}
                      theme={activeTheme}
                    >
                      {data.sponsorName}
                    </Text>
                  )}
                  {data.sponsorText && (
                    <Text
                      className={`${classPrefix}__content__image__sponsor__text`}
                      type={'p'}
                      size={'small'}
                      brand={activeBrand}
                      theme={activeTheme}
                    >
                      {data.sponsorText}
                    </Text>
                  )}
                </FlexGridAligner>
              </FlexGridAligner>
            </FlexGridAligner>
            <FlexGridAligner
              className={`${classPrefix}__content__text`}
              alignment={'top-left'}
              direction={'column'}
              spaceBetween
            >
              <FlexGridAligner
                className={`${classPrefix}__content__text__header`}
                alignment={'center-left'}
                direction={'row'}
                spaceBetween
              >
                {data.categories && (
                  <Text
                    type={'p'}
                    weight={600}
                    size={'small'}
                    brand={activeBrand}
                    faded
                    theme={getCounterTheme(activeTheme)}
                  >
                    {data.categories.map((category: string, i: number) => {
                      return category.toUpperCase() + (i < data.categories.length - 1 ? ', ' : '')
                    })}
                  </Text>
                )}
                <MsButton
                  theme={activeTheme}
                  brand={activeBrand}
                  template={MS_ButtonTemplate(activeTheme, 'icon')}
                  action={{ onClick: close }}
                >
                  <Icon type={IconType.Close} />
                </MsButton>
              </FlexGridAligner>

              <Text
                className={`${classPrefix}__content__text__title`}
                type={'h2'}
                brand={activeBrand}
                theme={getCounterTheme(activeTheme)}
              >
                {data.heading}
              </Text>

              <BenefitListItemModalContent
                data={data}
                recruitLink={recruitLink}
              />
            </FlexGridAligner>
          </FlexGridAligner>
        </Modal>
      )}
    </>
  )
}
