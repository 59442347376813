import React from 'react'
import {
  IComponent,
  Text,
  IconType,
  useQueryParamPageRouter,
  ContentGrid,
  StyleGrid,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { useApplicationContext } from '../../contexts'
import { ActionButton, ArrowButton } from '../../components'
import { MS_ButtonTemplate } from '../../Prefabs'
import { MSRichText } from '../MSRichText/MSRichText'
import { ProgressBar } from '../ProgressBar'
import './FullPageStory.scss'

export interface IFullPageStory extends IComponent {
  pages: any[]
  endUrl: string
}

/**
 *    The FullPageStory is used to show a story with image and text and a "page" query parameter that controls routing.
 */
export const FullPageStory = (props: IFullPageStory) => {
  // ************************************
  // Properties
  // ************************************
  const { id, pages, endUrl } = props
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { getPage, next, prev } = useQueryParamPageRouter(1)

  const page = getPage() != undefined ? (getPage() as number) - 1 : undefined

  const classPrefix = 'full-page-story'

  // ************************************
  // Render
  // ************************************
  return (
    <StyleGrid
      id={id}
      className={`${classPrefix}`}
      brand={activeBrand}
      theme={activeTheme}
      alignment={'center'}
      direction={'row'}
      gap={2}
    >
      {page != undefined && pages.length > 0 && (
        <>
          <img
            src={pages[page].imageUrl}
            alt={pages[page].imageAlt}
            className={`${classPrefix}__image`}
          />
          <ContentGrid
            className={`${classPrefix}__content`}
            alignment={'center-left'}
            direction={'column'}
            tagType={'article'}
            spaceBetween
          >
            <ContentGrid
              className={`${classPrefix}__content_text`}
              alignment={'center-left'}
              direction={'column'}
              tagType={'section'}
            >
              <Text
                className={`${classPrefix}__content__title`}
                type={'h1'}
                weight={600}
                family={'main'}
              >
                {pages[page].title}
              </Text>
              <MSRichText
                className={`${classPrefix}__content__description`}
                text={pages[page].description}
                theme={getCounterTheme(activeTheme)}
              />
            </ContentGrid>

            <ContentGrid
              className={`${classPrefix}__navigation`}
              alignment={'center-left'}
              direction={'column'}
              tagType={'section'}
            >
              {desktopView && (
                <ProgressBar
                  current={page}
                  max={pages.length}
                />
              )}
              <StyleGrid
                className={`${classPrefix}__navigation__buttons`}
                alignment={'center-right'}
                direction={'row'}
                spaceBetween={page > 0}
              >
                {page > 0 && (
                  <ArrowButton
                    action={{
                      onClick: prev,
                      text: 'Tilbake'
                    }}
                    iconLocation='left'
                  />
                )}

                {page < pages.length - 1 ? (
                  <ActionButton
                    action={{
                      onClick: next,
                      text: 'Neste',
                      icon: IconType.ArrowRight
                    }}
                    template={MS_ButtonTemplate(activeTheme, 'primary')}
                    iconPlacement='Right'
                    padding='medium'
                  />
                ) : (
                  <ActionButton
                    template={MS_ButtonTemplate(activeTheme, 'primary')}
                    brand={activeBrand}
                    iconPlacement={'Right'}
                    padding='medium'
                    action={{
                      link: endUrl,
                      text: 'Gå til Fjordkraft Fordel',
                      icon: IconType.ArrowRight
                    }}
                  />
                )}
              </StyleGrid>
              {!desktopView && (
                <ProgressBar
                  current={page}
                  max={pages.length}
                />
              )}
            </ContentGrid>
          </ContentGrid>
        </>
      )}
    </StyleGrid>
  )
}
