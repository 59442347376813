import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useApplicationContext } from '../../contexts'
import { LoaderGraphic, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import './SmoothLoader.scss'

type TransitionSate = 'STANDBY' | 'IN' | 'OUT' | 'HIDDEN'

export interface ISmoothLoader {
  loadingState: boolean
}

export const SmoothLoader = (props: ISmoothLoader) => {
  // ************************************
  // Properties
  // ************************************

  const { loadingState } = props
  const classPrefix = 'smooth-loader'
  const { activeTheme, activeBrand } = useApplicationContext()
  const [transitionState, setTransitionState] = useState<TransitionSate>('HIDDEN')

  // ************************************
  // Lifecycle
  // ************************************

  useEffect(() => {
    if (!loadingState) {
      let timeoutId = setTimeout(() => {
        setTransitionState('OUT')
      }, 300)

      return () => clearTimeout(timeoutId)
    } else {
      setTransitionState('STANDBY')
    }
  }, [loadingState])

  // ************************************
  // Render
  // ************************************

  return (
    <>
      <StyleGrid
        direction='column'
        alignment='center'
        boxSizing='border-box'
        className={classNames(classPrefix, {
          [`${activeBrand}`]: activeBrand,
          [`${classPrefix}--${activeTheme}`]: activeTheme,
          [`${classPrefix}--${transitionState}`]: transitionState
        })}
      >
        <LoaderGraphic
          className={`${classPrefix}__graphic`}
          theme={activeTheme}
          brand={activeBrand}
          scaleThickness={1}
          size={6}
        />
      </StyleGrid>
      <span
        className={classNames(`${classPrefix}__mask`, {
          [`${classPrefix}--${activeTheme}`]: activeTheme,
          [`${classPrefix}--${transitionState}`]: transitionState
        })}
      />
    </>
  )
}
