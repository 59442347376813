import React from 'react'
import { getCounterTheme, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import { useDefaultPageContext, useSubPageLayoutContext } from '../../../contexts'
import { getPageContent } from './BenefitsIntroductionPageData'
import { FullPageStory } from '../../../components/FullPageStory/FullPageStory'
import { Constants } from '../../../data'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import './BenefitsIntroductionPage.scss'

export interface IBenefitsIntroductionPage extends IDefaultViewProps {
  benefitIntroductionData: any
  points: number
}

export const BenefitsIntroductionPage = () => {
  // ************************************
  // Properties
  // ************************************

  const { setInfoBoxContent } = useSubPageLayoutContext()
  const { setMainContentClamping } = useDefaultPageContext()
  const classPrefix = 'benefits-introduction-page'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderFullPageStory = (benefitIntroductionData: any) => {
    if (benefitIntroductionData?.pages && benefitIntroductionData?.pages.length > 0) {
      return <FullPageStory {...benefitIntroductionData} />
    }
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.benefits.type,
      usesSubPage: true
    },
    renderUpdate: () => {
      setInfoBoxContent(null)
      setMainContentClamping('stretch')
    },
    handleData: getPageContent,
    render: (config: IBenefitsIntroductionPage) => {
      const { activeBrand, activeTheme, benefitIntroductionData } = config

      return (
        <StyleGrid
          className={`${classPrefix}`}
          alignment={'center'}
          direction={'row'}
          boxSizing='border-box'
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
        >
          {_renderFullPageStory(benefitIntroductionData)}
        </StyleGrid>
      )
    }
  })
}
