import ForutsigbarIkon from '../../assets/art/forutsigbar-agreement-icon.svg'
import SpotprisIkon from '../../assets/art/spotpris-agreement-icon.svg'
import SpotprisForvaltningIkon from '../../assets/art/spotpris-forvaltning-agreement-icon.svg'
import { BrandColors, Theme } from '@fjordkraft/fjordkraft.component.library'
import {
  ConsentStateEnum,
  ICustomer,
  ICustomerInstallation,
  ICustomerInvoice,
  IGuestRelationship,
  InvoiceStatusType
} from '../../models'
import { format } from 'date-fns'
import { nb } from 'date-fns/locale'
import { Constants } from '../../data'
import { User } from 'oidc-client-ts'
import { orderBy } from 'lodash'
import { getCorrectInvoicePeriodDate } from './InvoiceService'
import { useEffect, useRef } from 'react'

export const getBrand = () => {
  if (Constants.uiBrand) {
    return import.meta.env.REACT_APP_UI_BRAND as 'brand-fjordkraft' | 'brand-trondelagkraft' | 'brand-nges'
  } else {
    return 'brand-fjordkraft'
  }
}

export const getBrandShorthand = (): 'FKAS' | 'TKAS' | 'NGES' => {
  if (Constants.api.brand) {
    return (import.meta.env.REACT_APP_API_BRAND as string).toUpperCase() as 'FKAS' | 'TKAS' | 'NGES'
  } else {
    return 'FKAS'
  }
}

export const isExternalUrl = (url: string | undefined) => {
  return url?.includes('http')
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeObjectDeep = (target: any, ...sources: any): any => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeObjectDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeObjectDeep(target, ...sources)
}

export const capitalizeFirstLetter = (string: string | null | undefined) => {
  let convertString: string | null | undefined = string

  if (convertString && convertString.length > 0) {
    convertString = convertString.toLocaleLowerCase()
    return convertString.charAt(0).toUpperCase() + convertString.slice(1)
  }

  return ''
}

export const getProductIcons = (productId: string) => {
  switch (productId) {
    case 'Spotpris':
      return SpotprisIkon
    case 'Forutsigbar Strømpris':
      return ForutsigbarIkon
    case 'Spot med forvaltning':
      return SpotprisForvaltningIkon
    default:
      return ''
  }
}

export const sortInvoiceByDueDate = (invoices: ICustomerInvoice[]) => {
  invoices.sort((a, b) => {
    return new Date(a.dueDate) < new Date(b.dueDate) ? 1 : -1
  })
  return invoices
}

export const sortInvoiceByPeriodDate = (invoices: ICustomerInvoice[]) => {
  invoices.sort((a, b) => {
    return getCorrectInvoicePeriodDate(a.invoicePeriod) < getCorrectInvoicePeriodDate(b.invoicePeriod) ? 1 : -1
  })
  return invoices
}

export const unpaidInvoices = (invoices: ICustomerInvoice[]) => {
  return invoices.filter(invoice => invoice.paymentStatus === 'Unpaid' || invoice.paymentStatus === 'PartlyPaid')
}

export const getDaysInMonthList = (month: number, year: number, limit: number = 60) => {
  const date = new Date(year, month, 1)
  const days = []
  let index = 1

  while (date.getMonth() === month) {
    if (index <= limit) {
      days.push(new Date(date))
      index++
    }

    date.setDate(date.getDate() + 1)
  }

  return days
}

export const tNumber = (
  value: number,
  language: string = 'no-NO',
  minFractionDigits: number = 0,
  maxFractionDigits: number = 2
) => {
  return Intl.NumberFormat(language, {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits
  }).format(value)
}

/**
 * Converts days to rounded months.
 * @param days
 * @returns
 */
export const daysToMonths = (days: number) => {
  return Math.round(days / 30.44)
}

export const getInvoiceColorBasedOnStatus = (status: InvoiceStatusType | null | undefined, theme: Theme) => {
  switch (status) {
    case 'Credit':
      return BrandColors['status-shade-light-1']
    case 'Overdue':
      return BrandColors['status-shade-light-3']
    case 'Paid':
      return BrandColors['status-shade-light-1']
    case 'PartlyPaid':
      return BrandColors['status-shade-light-2']
    case 'Unpaid':
      return BrandColors['status-shade-light-3']
    default:
      return theme === 'Light' ? BrandColors['text-shade-dark-3'] : BrandColors['text-shade-light-1']
  }
}

export const epiUrl = (path: string) => {
  return `${Constants.appUrl}${path}`
}

export const createString = (string: string, variables: any) => {
  if (!string) {
    logger('Failed to createString, string value undefined.', 'warn')
    return ''
  }

  return string.replace(new RegExp('{([^{]+)}', 'g'), function (_unused, varName) {
    return variables[varName]
  })
}

export interface IDate {
  year: number
  month?: number
  day?: number
  hour?: number
  min?: number
  sec?: number
  toString?: boolean
}

export const ISODate = (config: IDate) => {
  let values = dateValueParser(config)

  if (config.toString) {
    return `${values.y}-${values.m}-${values.d}T${values.h}:00:00`
  } else {
    return new Date(`${values.y}-${values.m}-${values.d}T${values.h}:00:00`)
  }
}

export const dateValueParser = (config: IDate) => {
  const { year, month = 0, day = 1, hour = 0 } = config

  let y: number | string = year
  let m: number | string = month + 1
  let d: number | string = day
  let h: number | string = hour

  y = `${year}`
  m = m < 10 ? (m < 1 ? `01` : `0${m}`) : `${m}`
  d = d < 10 ? (d < 1 ? `01` : `0${d}`) : `${d}`
  h = h < 10 ? `0${h}` : `${h}`

  return { y, m, d, h }
}

export const isBetweenTwoTimes = (from: Date, to: Date) => {
  let currentTime: Date = new Date()

  let fromTime: Date = new Date()
  fromTime.setHours(from.getHours())
  fromTime.setMinutes(from.getMinutes())

  let toTime: Date = new Date()
  toTime.setHours(to.getHours())
  toTime.setMinutes(to.getMinutes())

  if (currentTime >= fromTime && currentTime <= toTime) {
    return true
  } else {
    return false
  }
}

export const getMonths = (fullMonthName: boolean = false, locale: any = nb) => {
  let months: string[] = []

  for (let i = 0; i < 12; i++) {
    let date = new Date().setMonth(i)
    months.push(capitalizeFirstLetter(format(date, fullMonthName ? 'LLLL' : 'LLL', { locale })))
  }

  return months
}

export const logger = (text: string, type: 'warn' | 'error' | 'log' | 'debug' = 'log', objects: any[] = []) => {
  if (import.meta.env.DEV) {
    switch (type) {
      case 'error':
        newrelicLogger(text, { level: 'error', customAttributes: { ...objects } })
        break
      case 'log':
        newrelicLogger(text, { level: 'info', customAttributes: { ...objects } })
        break
      case 'warn':
        newrelicLogger(text, { level: 'warn', customAttributes: { ...objects } })
        break
      case 'debug':
        newrelicLogger(text, { level: 'debug', customAttributes: { ...objects } })
        break
    }
  }
}

export const newrelicLogger = (message: string, options: NewRelicOptions) => {
  try {
    let opt = {
      ...options,
      customAttributes: { ...options.customAttributes, brand: import.meta.env.REACT_APP_API_BRAND }
    }
    newrelic.log(message, opt)

    if (import.meta.env.DEV) {
      switch (options.level) {
        case 'error':
          console.error(message, opt)
          break
        case 'info':
          console.log(message, opt)
          break
        case 'warn':
          console.warn(message, opt)
          break
        case 'debug':
          console.debug(message, opt)
          break
      }
    }
  } catch (err) {
    //If we are not able to log error, ignore it...
  }
}

export const newrelicErrorLogger = (err: Error, options: NewRelicErrorOptions) => {
  try {
    let opt = {
      ...options,
      customAttributes: { ...options.customAttributes, brand: import.meta.env.REACT_APP_API_BRAND }
    }
    if (!err.message) {
      err.message = opt.message
    }
    newrelic.noticeError(err, opt)
  } catch (err) {
    //If we are not able to log error, ignore it...
  }
}

export const isWhiteListed = (user: User) => {
  let whitelisted = false
  if (Constants.whitelisted?.length > 0) {
    Constants.whitelisted.forEach((indicator: string) => {
      if (indicator === (user?.profile?.email ?? '')) {
        whitelisted = true
      }
    })
  }
  return whitelisted
}

export const toCamelCase = (text: string) => {
  return text.replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase()
  })
}

interface IGetAddressesBasedOnAgreement {
  userData: ICustomer
  accountId: string
  maxCount?: number
  newLine?: boolean
}

export const getAddressesBasedOnAgreement = (config: IGetAddressesBasedOnAgreement) => {
  const { userData, accountId, maxCount = 3, newLine = false } = config

  let desc = ''

  if (userData.installations.length > 0) {
    let relevantInstallations: ICustomerInstallation[] = []

    userData.installations.forEach((inst: ICustomerInstallation, index: number) => {
      if (index <= maxCount && `${inst.accountId}` === `${accountId}`) {
        relevantInstallations.push(inst)
      }
    })

    if (relevantInstallations.length > 0) {
      relevantInstallations.forEach((inst: ICustomerInstallation, index: number) => {
        if (index !== 0 && index <= userData.installations.length - 1) {
          desc += newLine ? `${inst.address.streetAddress} \n` : `, ${inst.address.streetAddress}`
        } else {
          desc += newLine ? `${inst.address.streetAddress} \n` : `${inst.address.streetAddress}`
        }
      })
    }

    if (relevantInstallations.length > maxCount) {
      desc += '...'
    }
  }

  return desc
}

export const getListedAddresses = (userData: IGuestRelationship): string => {
  let text = ''

  if (userData.addresses && userData.addresses.length > 0) {
    userData.addresses.forEach(address => {
      text += `${address.streetAddress} \n`
    })
  }

  return text
}

export const isImpersonating = (user: User | undefined | null) => {
  if (!user?.profile) {
    return false
  }
  return !!user?.profile.impersonating
}

export const currentElectricityCustomerNumber = (user: User | undefined | null): string | null => {
  if (!user?.profile) {
    return null
  }
  if (user.profile.selectedElectricityCustomerId) {
    return <string>user.profile.selectedElectricityCustomerId
  }
  const brand = (<string>import.meta.env.REACT_APP_API_BRAND).toLowerCase()
  const maxDate = new Date(8640000000000000)
  const relationships = (<string[]>user.profile.relationships)
    .filter(r => {
      const arr = r.split(';')
      return arr[1] === 'NFK' || (arr[1] === 'POF' && arr[2].toLowerCase() === brand)
    })
    .map(r => {
      const arr = r.split(';')
      return {
        sourceSystem: arr[1],
        sourceSystemId: arr[3],
        expired: arr[4] ? new Date(arr[4]).getTime() : maxDate.getTime()
      }
    })
  return orderBy(relationships, ['sourceSystemId', 'expired'], 'desc')[0]?.sourceSystemId
}

/**
 * This checks if the user is currently an active customer by their installations.
 * If there are no selected installation in context, we can assume that the user has previously been active,
 * but has ended their elmera subscription.
 * @param installation Installation of userData
 * @returns true if installation is not null/undefined, false otherwise
 */
export const IsActiveCustomer = (installation?: ICustomerInstallation | null): boolean => {
  return !!installation
}

export const GetInstallationStatusAndInstallationDate = (
  validFromDate: string,
  validToDate?: string
): ['none' | 'establishing' | 'ending', Date] => {
  let current: Date = new Date()
  let from: Date = new Date(validFromDate)
  let status: 'none' | 'establishing' | 'ending' = 'none'
  let installationDate: Date = from

  if (validToDate) {
    status = 'ending'
    installationDate = new Date(validToDate)
  } else if (from > current) {
    status = 'establishing'
  }
  return [status, installationDate]
}

export const GetInstallationStatus = (
  validFromDate: string,
  validToDate?: string
): 'none' | 'establishing' | 'ending' => {
  return GetInstallationStatusAndInstallationDate(validFromDate, validToDate)[0]
}

interface IIsGuestCheck {
  chosenHost?: IGuestRelationship
  hosts?: IGuestRelationship[]
  mainUser?: ICustomer
}

// We are a guest if host is set or if hosts contain the mainUser customerId
export const isGuestCheck = (config: IIsGuestCheck): boolean => {
  const { chosenHost, mainUser, hosts } = config
  return chosenHost !== undefined || isNonCustomer(hosts, mainUser)
}

// We are a non-customer if we have a hosts with the same customerId as the one we logged in as (mainUser)
export const isNonCustomer = (hosts?: IGuestRelationship[], mainUser?: ICustomer): boolean => {
  const foundMainUserInHosts = hosts?.find(host => host.customerId === mainUser?.customerId) !== undefined

  return foundMainUserInHosts
}

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const diffObjects = (obj1: any, obj2: any) => {
  if (!obj1 || !obj2) return
  function findDifferences(obj1: any, obj2: any, path = '') {
    let differences: any = {}

    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const fullPath = path ? `${path}.${key}` : key

        if (!obj2.hasOwnProperty(key)) {
          differences[fullPath] = { type: 'removed', value: obj1[key] }
        } else if (
          typeof obj1[key] === 'object' &&
          obj1[key] !== null &&
          typeof obj2[key] === 'object' &&
          obj2[key] !== null
        ) {
          const nestedDifferences = findDifferences(obj1[key], obj2[key], fullPath)
          Object.assign(differences, nestedDifferences)
        } else if (obj1[key] !== obj2[key]) {
          differences[fullPath] = { type: 'changed', oldValue: obj1[key], newValue: obj2[key] }
        }
      }
    }

    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        const fullPath = path ? `${path}.${key}` : key
        differences[fullPath] = { type: 'added', value: obj2[key] }
      }
    }

    return differences
  }

  return findDifferences(obj1, obj2)
}

/**
 * Can be put in code in place of "useEffect" to log which dependencies changed
 */
export function useEffectDebugger(effectHook: any, dependencies: any, dependencyNames: string[] = []) {
  const previousDeps = usePrevious(dependencies, [])

  if (import.meta.env.DEV) {
    const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
            diff: diffObjects(previousDeps[index], dependency)
          }
        }
      }

      return accum
    }, {})
    if (Object.keys(changedDeps).length) {
      logger(`[useEffect debugger]`, 'log', [changedDeps])
    }
  }

  useEffect(effectHook, dependencies)
}

export const translateCheckboxValue = (state: boolean) => {
  if (state) return ConsentStateEnum.ACCEPTED
  return ConsentStateEnum.DECLINED
}
