import { IPlankHouse } from '../../../blocks'
import { Constants } from '../../../data'
import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { IBenefitSummary, IDefaultProps, ILocationNavigationInfoBoxContent } from '../../../models'
import { createString, getText, getTextV2 } from '../../../services'
import earnPointsIcon from '../../../assets/art/benefits/benefits-earnPoints.svg'
import myBenefitsIcon from '../../../assets/art/benefits/benefits-myBenefits.svg'
import recruitJpg from '../../../assets/art/benefits/recruitCardLink.jpg'
import { getPlankPrefab, IPointPlankPrefab, ITextPlankPrefab } from '../../../Prefabs'
import { fetchEarnPointsCategory, fetchKickbackTransactions } from '../../../services/collection/BenefitService'
import { NavigateFunction } from 'react-router-dom'
import { IFlexiblePitchCard } from '../../../blocks/PitchCardsBlock/FlexiblePitchCard'
import _, { sum } from 'lodash'
import { format } from 'date-fns'

export interface IBenefitOverviewPageData extends IDefaultProps {
  navigateFunc: NavigateFunction
  summary: any
}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IBenefitOverviewPageData) => {
  const { translations, navigateFunc, services, relationship, summary } = config
  const { GET } = services
  const { isGuest } = relationship

  if (translations && GET !== undefined) {
    let transactions = await fetchKickbackTransactions(GET)
    let earnPointsCategory = await fetchEarnPointsCategory(GET)

    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: {
          text: getText('back', translations),
          link: Constants.paths.energyPage
        }
      },
      info: {
        hasInfo: true,
        infoTitle: getText('navigationInfoTitle', translations),
        infoText: getText('navigationInfoText', translations),
        buttons: [
          {
            type: 'link',
            text: getText('navigationInfoIntroductionText', translations),
            link: Constants.paths.benefitsIntroPage,
            buttonStyle: 'primary'
          },
          {
            type: 'close',
            text: getText('navigationInfoCloseText', translations),
            buttonStyle: 'secondary'
          }
        ]
      } as ILocationNavigationInfoBoxContent,
      listedOptions: _getListedOptions({
        translations,
        summary,
        latestTransaction: transactions.allTransactions.length > 0 ? transactions.allTransactions[0] : {},
        earnPointsCategory,
        navigateFunc,
        isGuest
      }),
      benefitButtonCardOptions: _getBenefitButtonCardsOptions(translations, earnPointsCategory)
    }
  }
}

interface IBenefitsOverviewData {
  translations: any
  summary: IBenefitSummary
  latestTransaction: any
  navigateFunc: Function
  earnPointsCategory: any
  isGuest: boolean
}

// ************************************
// PRIVATE
// ************************************

const _getListedOptions = (config: IBenefitsOverviewData) => {
  let house: IPlankHouse = {
    plankWalls: [
      {
        planks: []
      }
    ]
  }

  house.plankWalls[0].planks.push(_getPointPlankValues(config))
  house.plankWalls[0].planks.push(_getEarningsPlank(config))
  house.plankWalls[0].planks.push(_getUsePointsPlank(config))
  house.plankWalls[0].planks.push(_getTransactionsPlank(config))

  return house
}

const _getEarningsPlank = (config: IBenefitsOverviewData) => {
  const { translations, navigateFunc, earnPointsCategory } = config

  return _getPlank(getText('plankEarningTitle', translations), getText('plankEarningDesc', translations), () => {
    navigateFunc(`${Constants.paths.myBenefitsPage}?include=${earnPointsCategory}`)
  })
}

const _getUsePointsPlank = (config: IBenefitsOverviewData) => {
  const { translations, navigateFunc, isGuest } = config

  return _getPlank(
    getText('plankEarningTitlePoints', translations),
    getText('plankEarningDescPoints', translations),
    () => {
      navigateFunc(`${Constants.paths.usePointsPage}`)
    },
    isGuest
  )
}

const _getPointPlankValues = (config: IBenefitsOverviewData) => {
  const { summary, translations } = config

  let title: string = getText('plankPointTitle', translations)
  let description: string = getText('plankPointDescription', translations)

  if (summary?.expiringPoints) {
    description = createString(getText('plankPointDescriptionNegative', translations), {
      amount: summary.expiringPoints.points,
      date: format(new Date(summary.expiringPoints.date), 'dd.MM.yyyy')
    })
  }

  return getPlankPrefab('Point', {
    points: summary?.amount,
    pointsLabel: getText('plankTotalDesc', translations),
    left: {
      title,
      description
    },
    right: {
      description: getText('plankTotalValue', translations)
    }
  } as IPointPlankPrefab)
}

const _getTransactionsPlank = (config: IBenefitsOverviewData) => {
  const { latestTransaction, translations, navigateFunc } = config

  let goodData = !_.isEmpty(latestTransaction) && latestTransaction != null
  let status = goodData && latestTransaction.typeId != 'Ventende' ? 'Utført' : 'Ventende'
  let latestTransactionMessage = goodData
    ? `${latestTransaction.message}: ${latestTransaction.amount} / ${latestTransaction.date} / ${status}`
    : getText('plankTransactionDesc', translations)

  return getPlankPrefab('Text', {
    left: {
      title: getText('plankTransactionTitle', translations),
      description: latestTransactionMessage
    },
    right: {
      icon: IconType.ChevronRight,
      title: goodData ? getText('SeeMore', translations) : ''
    },
    action: {
      onClick: () => {
        navigateFunc(`${Constants.paths.kickbackTransactionsPage}`)
      },
      disabled: !goodData
    }
  })
}

const _getPlank = (title: string, description: string, navigateFunc: Function, isGuest: boolean = false) => {
  return getPlankPrefab('Text', {
    left: {
      title,
      description
    },
    right: {
      icon: IconType.ChevronRight
    },
    action: {
      disabled: isGuest,
      onClick: navigateFunc
    }
  } as ITextPlankPrefab)
}

// ************************************
// Benefit Button Cards Options
// ************************************

const _getBenefitButtonCardsOptions = (translation: any, earnPointsCategory: string) => {
  let benefitButtonCards = []

  benefitButtonCards.push({
    title: getText('cardPointsTitle', translation),
    description: getText('cardPointsIngress', translation),
    image: earnPointsIcon,
    imageAlt: getText('cardPointsTitle', translation),
    imageStyle: 'rounded',
    alignment: 'align-center',
    action: {
      link: Constants.paths.myBenefitsPage + '?include=' + earnPointsCategory,
      text: getText('cardPointsLinkText', translation),
      icon: IconType.ArrowRight
    }
  } as IFlexiblePitchCard)

  benefitButtonCards.push({
    title: getText('cardDiscountsTitle', translation),
    description: getText('cardDiscountsIngress', translation),
    image: myBenefitsIcon,
    imageAlt: getText('cardDiscountsTitle', translation),
    imageStyle: 'rounded',
    alignment: 'align-center',
    action: {
      link: Constants.paths.myBenefitsPage + '?exclude=' + earnPointsCategory,
      text: getText('cardDiscountsLinkText', translation),
      icon: IconType.ArrowRight
    }
  } as IFlexiblePitchCard)

  benefitButtonCards.push({
    title: getText('cardRecruitTitle', translation),
    description: getText('cardRecruitIngress', translation),
    image: recruitJpg,
    imageAlt: getText('cardRecruitTitle', translation),
    imageStyle: 'rounded',
    alignment: 'align-center',
    action: {
      link:
        Constants.paths.myBenefitsPage +
        '?include=' +
        (getTextV2({ key: 'cardRecruitLinkCategory', translations: translation, includeMissing: false }) ?? ''),
      text: getText('cardRecruitLinkText', translation),
      icon: IconType.ArrowRight
    }
  } as IFlexiblePitchCard)
  return benefitButtonCards
}
