import React from 'react'
import { Button, IAction, IButton } from '@fjordkraft/fjordkraft.component.library'
import { useNavigate } from 'react-router-dom'
import { logger } from '../../../services'
import { useDefaultPageContext } from '../../../contexts'
import '../Button.scss'

export const RouterButton = (props: IButton) => {
  // ************************************
  // Properties
  // ************************************

  const { action } = props
  const { setContentLoading } = useDefaultPageContext()
  const navigate = useNavigate()

  // ************************************
  // Helper Functionality
  // ************************************

  const _getParsedAction = () => {
    return {
      ...action,
      ...({
        onClick: e => {
          if (setContentLoading) {
            setContentLoading(true)
          }

          if (action.link) {
            navigate(action.link ?? '/')
          } else {
            logger(`Failed to navigate with button. Requires working link, redirects to home page.`, 'error')
          }

          e.preventDefault()
        },
        link: action.link,
        overrideTagEnforcer: true
      } as IAction)
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <Button
      {...props}
      action={_getParsedAction()}
    >
      {props.children}
    </Button>
  )
}
