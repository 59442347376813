import { ICustomerConsumptionPoint, ICustomerInstallation, IDateState, IDefaultProps } from '../../../models'
import {
  capitalizeFirstLetter,
  tNumber,
  getText,
  fetchYearlyConsumption,
  fetchCustomerConsumption,
  createString
} from '../../../services'
import { Constants, getConsumptionSeriesData, getMockData } from '../../../data'
import { BrandColors, IAction, getColorCode } from '@fjordkraft/fjordkraft.component.library'
import { format, getDaysInMonth, monthsInYear } from 'date-fns'
import { nb } from 'date-fns/esm/locale'
import _ from 'lodash'
import { ILegend } from '../../../components/Legend/Legend'
import { DefaultColumnChartColors } from '../../../components'

export interface IConsumptionUsePageData extends IDefaultProps {
  state?: IDateState
  pageNode: any
  selectedYears: number[]
}

export const getPageContent = async (config: IConsumptionUsePageData) => {
  const { translations, user, pageNode, selectedYears, services, state } = config
  const { GET } = services
  const { installation } = user

  let validFromDate: Date | undefined = undefined

  if (!state) {
    let currentDate = new Date()
    let allDays = getDaysInMonth(currentDate)
    let year = currentDate.getFullYear()
    let month = currentDate.getMonth()

    config.state = {
      from: new Date(year, month, 1),
      to: new Date(year, month, allDays),
      resolution: 'day'
    } as IDateState
  }

  if (installation) {
    let currDate: Date = new Date()
    let from = new Date(installation?.validFrom)

    if (from > currDate) {
      validFromDate = from
    }
  }

  let consumption: ICustomerConsumptionPoint[][] = []
  let extractedYears: number[] | undefined = undefined
  let showEstimatedView: boolean = !!validFromDate
  let dateState = _getState(showEstimatedView, config.state)

  if (installation?.meterId && translations) {
    if (dateState.resolution === 'month' && selectedYears?.length > 1) {
      let multipleResp = await fetchYearlyConsumption(installation.meterId, selectedYears, GET)
      consumption = multipleResp
    } else {
      let singleResp = await fetchCustomerConsumption(installation.meterId, dateState, GET)
      consumption = [singleResp]
    }

    let desc = _getTranslationBasedOnResolution(dateState, config)

    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: {
          text: installation.address.streetAddress,
          link: Constants.paths.energyPage
        } as IAction,
        description: desc.preface,
        tooltipDescription: desc.tooltip
      },
      chartData: _getParsedChartData({
        translation: translations,
        consumptions: consumption,
        installation,
        state: dateState,
        chartNode: pageNode,
        showEstimatedView
      }),
      selectedYears: extractedYears,
      availableYears: installation.consumptionYears,
      showEstimatedView
    }
  }
}

const _getState = (showEstimatedView: boolean, state?: IDateState) => {
  let currentDate = new Date()
  let allDays = getDaysInMonth(currentDate)
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth()

  if (showEstimatedView) {
    return {
      from: new Date(year, 0),
      to: new Date(year, 11),
      resolution: 'month'
    } as IDateState
  } else if (state && !showEstimatedView) {
    return state
  } else {
    return {
      from: new Date(year, month, 1),
      to: new Date(year, month, allDays),
      resolution: 'day'
    } as IDateState
  }
}

const _getTranslationBasedOnResolution = (state: IDateState, config: IConsumptionUsePageData) => {
  let { translations, desktopView } = config

  translations.touchDevice = {
    year: getText('clickDeviceYear', translations),
    month: getText('clickDeviceMonth', translations),
    day: getText('clickDeviceDay', translations),
    tooltip: getText('clickDeviceTooltip', translations)
  }

  translations.clickDevice = {
    year: getText('touchDeviceYear', translations),
    month: getText('touchDeviceMonth', translations),
    day: getText('touchDeviceDay', translations),
    tooltip: getText('touchDeviceTooltip', translations)
  }

  let data = desktopView ? translations.clickDevice : translations.touchDevice
  let preface = ''

  switch (state.resolution) {
    case 'month':
      preface = data.year
      break
    case 'day':
      preface = data.month
      break
    case 'hour':
      preface = data.day
      break
  }

  return { preface, tooltip: data.tooltip }
}

interface IParsedChartData {
  translation: any
  consumptions: ICustomerConsumptionPoint[][]
  installation: ICustomerInstallation
  state: IDateState
  chartNode: any
  showEstimatedView: boolean
}

const _getParsedChartData = (config: IParsedChartData) => {
  let { translation, consumptions, installation, state, showEstimatedView } = config

  // Consumption mock fallback
  consumptions = _getTestAndDevFallbackMock(consumptions, state)

  // Get chart series
  let { series, knownTotal, estimatedTotal, knownDate } = _handleChartSeries(config)

  // Get chart descriptions
  let { subTitle, knownSubTitle, estimatedSubTitle, title } = _getChartDescriptions(
    state,
    translation,
    knownDate,
    installation,
    showEstimatedView
  )

  // Left and Right description
  let { left, right } = _parseChartDescriptionData({
    knownTotal,
    estimatedTotal,
    translation,
    knownSubTitle,
    estimatedSubTitle,
    installation
  })

  return {
    series,
    header: {
      title,
      subTitle
    },
    legends: !showEstimatedView ? _getLegends(translation, series, estimatedTotal > 0) : [],
    hasEstimatedData: estimatedTotal > 0,
    leftDescription: left,
    rightDescription: right
  }
}

interface IParseChartDescriptionData {
  knownTotal: number
  estimatedTotal: number
  translation: any
  knownSubTitle: string
  estimatedSubTitle: string
  installation: ICustomerInstallation
}

const _parseChartDescriptionData = (config: IParseChartDescriptionData) => {
  const { knownSubTitle, knownTotal, estimatedTotal, translation, estimatedSubTitle } = config

  let rightData = {
    title: `${tNumber(Math.round(knownTotal + estimatedTotal), 'no-NO')}`,
    titleSuffix: `${getText('powerMeasurement', translation)}`,
    subTitle: estimatedSubTitle
  }

  return {
    left: {
      title: `${tNumber(Math.round(knownTotal), 'no-NO')}`,
      titleSuffix: `${getText('powerMeasurement', translation)}`,
      subTitle: knownSubTitle
    },
    right: estimatedTotal > 0 ? rightData : undefined
  }
}

const _handleChartSeries = (config: IParsedChartData) => {
  let { state, consumptions, translation, chartNode, installation, showEstimatedView } = config
  let resolution = state?.resolution ?? 'day'
  let series: any[] = []
  let knownTotal = 0
  let estimatedTotal = 0
  let knownDate = state.to

  if (consumptions?.length === 0 || showEstimatedView) {
    estimatedTotal = installation.estimatedYearlyConsumption

    let estimatedSeries: any = {
      name: `${getText('series1Title', translation)}`,
      data: getConsumptionSeriesData('month', state.to),
      date: new Date(),
      totalYAmount: estimatedTotal / monthsInYear
    }

    estimatedSeries.data.forEach((data: any, index: number) => {
      let date = new Date(state.to)
      date.setMonth(index)

      data.date = date
      data.y = estimatedTotal / monthsInYear
      data.fillColor = getColorCode({
        color: BrandColors['icon-shade-light-2'],
        type: 'hex',
        element: chartNode
      })
    })

    series.push(estimatedSeries)
  } else {
    consumptions.forEach((consumption: ICustomerConsumptionPoint[]) => {
      let newSeries: any = {
        name: `${getText('series1Title', translation)}`,
        data: getConsumptionSeriesData(resolution, state.to),
        date: new Date(),
        totalYAmount: 0
      }

      if (consumption?.length > 0) {
        consumption.forEach((con: ICustomerConsumptionPoint, i: number) => {
          let date = new Date(con.startTime)

          let cost = null
          let cat = `${date.getDate()}`
          if (resolution === 'hour') {
            cat = `${format(date, 'HH:mm')}`
            cost = con.ratedVolume?.sumInclVat
          } else if (resolution === 'day') {
            cat = `${format(date, 'd.', { locale: nb })}`
          } else if (resolution === 'month') {
            cat = `${format(date, 'MMMM', { locale: nb })}`
          }

          let seriesIndex = _.findIndex(newSeries.data, (datum: any) => {
            if (resolution === 'hour') return datum.x === cat
            if (resolution === 'day') return datum.x === cat
            if (resolution === 'month') return datum.x === cat
          })

          if (seriesIndex != -1 && con.energy) {
            let yValKnown = 0
            if (con.energy.isEstimated) {
              estimatedTotal += con.energy.amount
            } else {
              newSeries.totalYAmount += con.energy.amount
              knownTotal += con.energy.amount
              knownDate = new Date(con.startTime)
            }
            yValKnown = con.energy.amount
            newSeries.date = date
            newSeries.data[seriesIndex] = {
              x: cat,
              y: yValKnown,
              date: date,
              fillColor: con.energy.isEstimated
                ? getColorCode({
                    color: BrandColors['icon-shade-light-2'],
                    type: 'hex',
                    element: chartNode
                  })
                : undefined,
              estimated: con.energy.isEstimated,
              cost: cost
            }
          }
        })
      }

      series.push(newSeries)
    })

    if (series.length > 1) {
      series.forEach((s: any, index: number) => {
        if (s.date.getFullYear() === state.from.getFullYear()) {
          let filterYear = series.splice(index, 1)
          series.unshift(filterYear[0])
        }
      })
    }
  }

  return { series, knownTotal, estimatedTotal, knownDate }
}

const _getChartDescriptions = (
  state: IDateState,
  translation: any,
  knownDate: Date,
  installation: ICustomerInstallation,
  showEstimatedView: boolean
) => {
  let title: string = `${installation.address.streetAddress}`
  let subTitle: string = ''
  let knownSubTitle: string = ''
  let estimatedSubTitle: string = ''
  let from = new Date(installation.validFrom)
  let validFromDate: Date | undefined = undefined
  let currentDate: Date = new Date()

  if (from > currentDate) {
    validFromDate = from
  }

  if (validFromDate && showEstimatedView) {
    subTitle = `${state.to.getFullYear()}`
    estimatedSubTitle = getText('estimatedYearlyUse', translation)
    knownSubTitle = createString(getText('availableAt', translation), {
      date: format(validFromDate, 'dd.MM.yyyy')
    })
  } else {
    if (state.resolution === 'month') {
      subTitle = `${state.to.getFullYear()}`
      estimatedSubTitle = `${getText('estimatedIn', translation)} ${state.to.getFullYear()}`
      knownSubTitle = `${format(state.from, 'MMMM', {
        locale: nb
      })} → ${format(knownDate, 'MMMM', { locale: nb })}`
    } else if (state.resolution === 'hour') {
      subTitle = `${capitalizeFirstLetter(format(state.from, 'EEEE', { locale: nb }))}`
      estimatedSubTitle = `${getText('estimated', translation)} ${format(state.from, 'EEEE', {
        locale: nb
      }).toLocaleLowerCase()}`
      knownSubTitle = `00:00 → ${format(knownDate, 'HH:mm')}`
    } else {
      subTitle = `${capitalizeFirstLetter(format(state.to, 'MMMM', { locale: nb }))} ${state.to.getFullYear()}`
      estimatedSubTitle = `${getText('estimatedIn', translation)} ${format(state.to, 'MMMM', {
        locale: nb
      })}`
      knownSubTitle = `${format(new Date(state.to.getFullYear(), state.to.getMonth(), 1), 'dd.MM')} → ${format(
        knownDate,
        'dd.MM'
      )}`
    }
  }

  return { subTitle, knownSubTitle, estimatedSubTitle, title }
}

const _getTestAndDevFallbackMock = (consumptions: ICustomerConsumptionPoint[][], state: IDateState) => {
  let cons: ICustomerConsumptionPoint[][] = consumptions

  // For testing purposes
  if (cons.length === 0 && import.meta.env.DEV) {
    cons = [
      getMockData({
        resolution: state.resolution,
        toDate: state.to,
        fromDate: state.from
      })
    ]
  }

  return cons
}

const _getLegends = (translation: any, series: any[], hasEstimatedData: boolean = false) => {
  let legends: ILegend[] = []

  series.forEach((serie: any, index: number) => {
    let name: string = serie.name
    let suffix: string = ''

    if (series.length > 1) {
      name = `${serie.date.getFullYear()}`
      suffix = `(${Math.round(serie.totalYAmount)} ${getText('powerMeasurement', translation)})`
    }

    legends.push({
      text: name,
      suffix,
      color: DefaultColumnChartColors[index],
      size: 0.7
    } as ILegend)
  })

  if (hasEstimatedData) {
    legends.push({
      text: getText('series2Title', translation),
      color: BrandColors['icon-shade-light-2'],
      size: 0.7
    } as ILegend)
  }

  return legends
}
