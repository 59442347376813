import { typedGetRequest } from '../../contexts'
import { IGridTariffResponse } from '../../models/interfaces/pages/IConsumptionCostPage'

export const fetchGridTariff = async (meterId: string, selectedDate: Date, GETTYPED: typedGetRequest) => {
  let year = selectedDate.getFullYear()
  let month = selectedDate.getMonth() + 1
  let fetchUrl = `Consumption/${meterId}/gridtariff/${year}/${month}`
  let resp = await GETTYPED<IGridTariffResponse>(fetchUrl)

  if (resp?.callState === 'success' && resp?.data) {
    return resp.data
  } else {
    return null
  }
}
