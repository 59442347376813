import React, { useState } from 'react'
import { BrandColors, IComponent, StyleGrid, Text } from '@fjordkraft/fjordkraft.component.library'
import { IGuestRelationship, IHomePage, IRelationSentInvitation } from '../../../models'
import classNames from 'classnames'
import validator from 'validator'
import { CustomerEditorInput, InfoInputType } from './CustomerEditorInput/CustomerEditorInput'
import './CustomerInfoEditor.scss'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { isEqual, parse } from 'date-fns'
import { getTextV2 } from '../../../services'
import { act } from 'react-dom/test-utils'

export interface ICustomerEditorInformation {
  name?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  customerNumber?: string
  email?: string
  address?: string
  postalCode?: string
  postalLocation?: string
  birthday?: string
  residenceName?: string
}

export interface ICustomerInfoEditor extends IComponent {
  customerInformation: ICustomerEditorInformation
  translation: IHomePage
  onChange: (value: ICustomerEditorInformation, valid: boolean) => void
  maxGuests?: number
  pendingGuests?: IRelationSentInvitation[]
}

interface VResponse {
  isValid: boolean
  message: 'NoValue' | 'OK' | 'Underage' | 'InvalidDate' | 'Invalid' | 'InvalidEmail' | 'InvalidAddress'
}

export const CustomerInfoEditor = (props: ICustomerInfoEditor) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme, brand, translation, customerInformation, onChange, pendingGuests, maxGuests } = props
  const classPrefix = 'userData-info-editor'

  const { guests } = useApplicationGuestsAndHostsContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [customerInfo, setCustomerInfo] = useState<ICustomerEditorInformation>(customerInformation)

  // ************************************
  // Validators
  // ************************************

  const _handleValidators = (customerInfo: ICustomerEditorInformation) => {
    return (
      _validName(customerInfo.name).isValid &&
      _validFirstName(customerInfo.firstName).isValid &&
      _validLastName(customerInfo.lastName).isValid &&
      _validEmail(customerInfo.email).isValid &&
      _validPhoneNumber(customerInfo.phoneNumber).isValid &&
      _validAddress(customerInfo.address).isValid &&
      _validPostalCode(customerInfo.postalCode).isValid &&
      _validPostalLocation(customerInfo.postalLocation).isValid &&
      _validCustomerNumber(customerInfo.customerNumber).isValid &&
      _validBirthday(customerInfo.birthday).isValid &&
      _validIsNotAlreadyGuest(customerInfo).isValid &&
      _validIsNotAlreadyPendingGuest(customerInfo).isValid &&
      _validAvailableGuestSlots().isValid &&
      _validResidenceName(customerInfo.residenceName).isValid
    )
  }

  const hasSameBirthday = (currentBday: string | undefined, guestBday: string) => {
    if (!currentBday || !guestBday) return false

    const current = parse(currentBday, 'dd.MM.yyyy', new Date())
    const guest = parse(guestBday, 'yyyy-MM-dd', new Date())

    return isEqual(current, guest)
  }

  const hasSamePhoneNumber = (currentPhone: string | undefined, guestPhone: string) => {
    const removeNorwegianCountryCode = (str: string | undefined) => str?.replace(/^\+47/, '')

    const sanitizedGuestPhone = removeNorwegianCountryCode(guestPhone.split(' ').pop())
    const sanitizedCurrentPhone = removeNorwegianCountryCode(currentPhone)

    return sanitizedCurrentPhone === sanitizedGuestPhone
  }

  const returnGuestIfFound = (
    customerInfo: ICustomerEditorInformation,
    users?: IGuestRelationship[] | IRelationSentInvitation[]
  ) =>
    users?.find(
      guest =>
        hasSamePhoneNumber(customerInfo.phoneNumber, guest.phoneNumber) &&
        hasSameBirthday(customerInfo.birthday, guest.birthDate)
    )

  const _validIsNotAlreadyGuest = (userInfo?: ICustomerEditorInformation) => {
    userInfo = userInfo ?? customerInfo
    const foundGuest = returnGuestIfFound(userInfo, guests)

    if (foundGuest) return { isValid: false, message: 'alreadyGuest' }
    return { isValid: true, message: 'OK' }
  }

  const _validAvailableGuestSlots = () => {
    const valid = (guests?.length ?? 0) < (maxGuests ?? 5)

    if (!valid) return { isValid: false, message: 'noAvailableGuestSlots' }
    return { isValid: true, message: 'OK' }
  }

  const _validIsNotAlreadyPendingGuest = (userInfo?: ICustomerEditorInformation) => {
    userInfo = userInfo ?? customerInfo
    const activeInvitations = pendingGuests?.filter(guest => guest.status === 'PENDING')
    const foundGuest = returnGuestIfFound(userInfo, activeInvitations)

    if (foundGuest) return { isValid: false, message: 'alreadyPendingGuest' }
    return { isValid: true, message: 'OK' }
  }

  const basicValidation = (value?: string): VResponse => {
    if (value === undefined || value === null) {
      return { isValid: true, message: 'NoValue' }
    }

    if (value && value.length > 0) {
      return { isValid: true, message: 'OK' }
    } else {
      return { isValid: false, message: 'Invalid' }
    }
  }
  const _validName = (value?: string) => basicValidation(value)
  const _validFirstName = (value?: string) => basicValidation(value)
  const _validLastName = (value?: string) => basicValidation(value)
  const _validCustomerNumber = (value?: string): VResponse => basicValidation(value) as VResponse

  const _validEmail = (value?: string): VResponse => {
    if (value === undefined || value === null) {
      return { isValid: true, message: 'OK' }
    } else if (value?.length === 0) {
      return { isValid: false, message: 'NoValue' }
    } else {
      let validatorString = String(value)
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      return { isValid: validatorString !== null, message: 'InvalidEmail' }
    }
  }

  const _validPhoneNumber = (value?: string): VResponse => {
    if (value === undefined || value === null) {
      return { isValid: true, message: 'OK' }
    } else if (value.length === 0) {
      return { isValid: false, message: 'NoValue' }
    } else {
      if (validator.isMobilePhone(value ?? '', ['nb-NO'])) {
        let valid = validator.isMobilePhone(value ?? '', ['nb-NO'])

        return { isValid: valid, message: valid ? 'OK' : 'Invalid' }
      } else {
        let externalCountryCheck = new RegExp('(\\+\\d{1,3})(\\d{4,})')
        if (value.match(externalCountryCheck) && validator.isMobilePhone(value ?? '')) {
          return { isValid: true, message: 'OK' }
        }
      }
    }

    return { isValid: false, message: 'Invalid' }
  }

  // Superficially checks if address is obviously valid: only a single value that looks like an address, number, letter, and no double spaces
  const _validAddress = (value?: string): VResponse => {
    if (value === undefined || value === null) {
      return { isValid: true, message: 'OK' }
    } else if (value.length === 0) {
      return { isValid: true, message: 'NoValue' }
    } else {
      let addressInfo: string[] = value!.split(
        new RegExp('(?<street>\\D{1,})\\s?(?<house>\\d{1,5})?\\s?(?<letter>\\D{1})?')
      )

      let streetAddress: string | undefined = undefined
      let houseNumber: string | undefined = undefined
      let houseLetter: string | undefined = undefined
      let valid = false

      if (addressInfo && addressInfo !== undefined) {
        // no more than one item fits each value type
        addressInfo.forEach(item => {
          if (item) {
            if (item.match(`(\\D{2,})`)) {
              if (streetAddress !== undefined) {
                // if there is more than one value matching streetaddress
                valid = false
              } else if (item.trim().length < 2) {
                // no previous value matches address
                valid = false
              } else {
                valid = true
                streetAddress = item.trim()
              }
            } else if (item.match(`[0-9]{1,5}`)) {
              // check for house number
              if (houseNumber !== undefined) {
                valid = false
              } else {
                houseNumber = item.trim()
              }
            } else if (item.match(`(\\w{1})`)) {
              // check for house letter
              if (houseLetter !== undefined) {
                valid = false
              } else {
                houseLetter = item.trim()
              }
              houseLetter = item.trim()
            }
          }
        })
      }
      // no double spaces
      if (value.match(`\\s{2,}`)) {
        valid = false
      }

      if (value && value.length > 0 && valid) {
        return { isValid: true, message: 'OK' }
      } else {
        return { isValid: false, message: 'InvalidAddress' }
      }
    }
  }

  const _validPostalCode = (value?: string) => {
    const valid = { isValid: true, message: 'OK' }
    const invalid = { isValid: false, message: 'invalidPostalCode' }

    if (value === undefined || value === null) return valid
    if (value.length === 0) return invalid
    if (value && !(value?.length === 4)) return invalid

    return valid
  }

  const _validPostalLocation = (value?: string) => {
    if (value !== undefined && value.length === 0) {
      return { isValid: false, message: 'invalidPostalLocation' }
    }
    return { isValid: true, message: 'OK' }
  }

  const _validBirthday = (value?: string): VResponse => {
    if (value !== undefined && value !== null) {
      const datePattern = /^(\d{2})\.(\d{2})\.(\d{4})$/
      // Check if the input matches the pattern
      const match = value.match(datePattern)

      if (!match) {
        return { isValid: false, message: 'InvalidDate' }
      }

      const day = parseInt(match[1], 10)
      const month = parseInt(match[2], 10)
      const year = parseInt(match[3], 10)

      // Check if the date is a valid calendar date
      const date = new Date(year, month - 1, day)
      if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
        return { isValid: false, message: 'InvalidDate' }
      }

      // Check if the user is at least 18 years old
      const today = new Date()
      const age = today.getFullYear() - year
      const monthDiff = today.getMonth() - (month - 1)
      const dayDiff = today.getDate() - day

      if (age > 18 || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
        return { isValid: true, message: 'OK' }
      } else {
        return { isValid: false, message: 'Underage' }
      }
    }

    return { isValid: true, message: 'NoValue' }
  }

  const _validResidenceName = (value?: string): VResponse => {
    let basicValid = basicValidation(value)

    if (!basicValid.isValid) return basicValid

    if ((value ?? '').length > 25) return { isValid: false, message: 'Invalid' }

    return { isValid: true, message: 'OK' }
  }

  // ************************************
  // Auto-formating
  // ************************************

  const _handleAutoFormating = (type: InfoInputType, value: string) => {
    switch (type) {
      case 'birthday':
        return _formatDateString(value)
      default:
        return value
    }
  }

  /**
   * Formats a string of numbers into a date (as dd.MM.yyyy) automatically as the user types in numbers. Also works with copy-paste.
   * @param date
   * @returns
   */
  const _formatDateString = (date: string) =>
    date.length <= 5
      ? date.replace(/(\d{2})\.*(\d{1,2})/, '$1.$2') //makes sure to add the first period after the first 2 numbers.
      : date.replace(/(\d{2})\.*(\d{2})\.*(\d{1,4})/, `$1.$2.$3`) //then adds the second period.

  // ************************************
  // Render Functionality
  // ************************************

  interface IRenderInput {
    valid: any
    type: InfoInputType
    size?: 'full' | 'half' | 'small' | 'large'
  }

  const _renderInput = (config: IRenderInput) => {
    const { size = 'full', valid, type } = config
    let value = _getUpdatedInfo(undefined, type)[type]

    if (customerInformation[type] !== undefined) {
      return (
        <CustomerEditorInput
          theme={theme}
          brand={brand}
          type={type}
          valid={valid}
          size={size}
          translations={translation}
          value={value ?? ''}
          onChange={e => {
            if (customerInfo) {
              let updatedInfo = _getUpdatedInfo(e, type)
              setCustomerInfo(updatedInfo)
              onChange(updatedInfo, _handleValidators(updatedInfo))
              value = e.target.value
            }
          }}
        />
      )
    }
  }

  const _getUpdatedInfo = (e: any, type: InfoInputType) => {
    let info = {
      name: customerInfo.name,
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName,
      phoneNumber: customerInfo.phoneNumber,
      customerNumber: customerInfo.customerNumber,
      email: customerInfo.email,
      address: customerInfo.address,
      postalCode: customerInfo.postalCode,
      postalLocation: customerInfo.postalLocation,
      birthday: customerInfo.birthday,
      residenceName: customerInfo.residenceName
    } as ICustomerEditorInformation

    if (e) {
      info[type] = _handleAutoFormating(type, e.target.value)
    }

    return info
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      direction='column'
      alignment='top-left'
      gap={1}
      boxSizing='border-box'
    >
      {_renderInput({
        valid: _validName,
        type: 'name'
      })}
      {customerInfo?.firstName !== undefined && customerInfo?.lastName !== undefined && (
        <StyleGrid
          className={classNames(`${classPrefix}__stretch`, {
            [`${classPrefix}__strentch--not-valid`]:
              _validLastName(customerInfo.firstName) || _validLastName(customerInfo.lastName)
          })}
          direction='row'
          alignment='center'
          gap={1}
          boxSizing='border-box'
          wrap={false}
        >
          {_renderInput({
            size: 'half',
            valid: _validFirstName,
            type: 'firstName'
          })}
          {_renderInput({
            size: 'half',
            valid: _validLastName,
            type: 'lastName'
          })}
        </StyleGrid>
      )}
      {_renderInput({
        valid: _validBirthday,
        type: 'birthday'
      })}
      {_renderInput({
        valid: _validCustomerNumber,
        type: 'customerNumber'
      })}
      {_renderInput({
        valid: _validEmail,
        type: 'email'
      })}
      {_renderInput({
        valid: _validPhoneNumber,
        type: 'phoneNumber'
      })}
      {_renderInput({
        valid: _validAddress,
        type: 'address'
      })}
      {_renderInput({
        valid: _validResidenceName,
        type: 'residenceName'
      })}
      {customerInfo?.postalCode !== undefined && customerInfo?.postalLocation !== undefined && (
        <StyleGrid
          className={classNames(`${classPrefix}__postal`, {
            [`${classPrefix}__stretch--not-valid`]:
              !_validPostalCode(customerInfo.postalCode).isValid ||
              !_validPostalLocation(customerInfo.postalLocation).isValid
          })}
          direction='row'
          alignment='center'
          gap={1}
          boxSizing='border-box'
        >
          {_renderInput({
            size: 'small',
            valid: _validPostalCode,
            type: 'postalCode'
          })}
          {_renderInput({
            size: 'large',
            valid: _validPostalLocation,
            type: 'postalLocation'
          })}
        </StyleGrid>
      )}
      <ErrorMessage
        {..._validAvailableGuestSlots()}
        classPrefix={classPrefix}
        brand={brand}
        translation={translation}
      />
      <ErrorMessage
        {..._validIsNotAlreadyPendingGuest()}
        classPrefix={classPrefix}
        brand={brand}
        translation={translation}
      />
      <ErrorMessage
        {..._validIsNotAlreadyGuest()}
        classPrefix={classPrefix}
        brand={brand}
        translation={translation}
      />
    </StyleGrid>
  )
}

const ErrorMessage = ({
  isValid,
  message,
  classPrefix,
  brand,
  translation
}: {
  message: string
  isValid: boolean
  classPrefix: string
  brand?: string
  translation: any
}) => {
  return (
    <>
      {!isValid && (
        <Text
          type={'p'}
          className={`${classPrefix}__required__label`}
          brand={brand}
          color={BrandColors['status-shade-light-3']}
          family='main'
          weight={400}
          size={'regular'}
        >
          {getTextV2({
            translations: translation,
            key: `popup-customerInfoEditor-${message}`,
            includeMissing: false
          })}
        </Text>
      )}
    </>
  )
}
