import {
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

const _transform = (desktopView: boolean): IComponentTransform => {
  return {
    width: '20.875rem',
    padding: '1.5rem',
    border: {
      radiusType: 'curved'
    },
    grid: {
      direction: 'column',
      alignment: 'top-left',
      boxSizing: 'border-box',
      gap: 2,
      tagType: 'article'
    }
  }
}

const _palette = {
  Light: {
    background: { color: BrandColors['background-shade-light-2'] }
  },
  Dark: {
    background: { color: BrandColors['background-shade-dark-2'] }
  }
} as IThemedColors

export const SidePopupCardTemplate = (theme: Theme, desktopView: boolean): IComponentTemplate => {
  return {
    dontUpdateChildren: true,
    transform: _transform(desktopView),
    palette: _palette[theme]
  }
}
