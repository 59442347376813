import React from 'react'
import { Text, BrandColors, Icon, IconType, StyleGrid, ContentGrid } from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../data'
import { SubPageLayout } from '../Base/SubPageLayout/SubPageLayout'
import { MS_ButtonTemplate, h1TextPrefab, paragraphTextPrefab } from '../../Prefabs'
import { ActionButton } from '../../components'
import { getText } from '../../services'
import { v4 as uuid4 } from 'uuid'
import { IDefaultViewProps, PageV2 } from '../PageV2'
import { IDefaultProps } from '../../models'
import './NotFoundPage.scss'

interface INotFoundPage extends IDefaultViewProps {
  id?: string
}

interface INotFoundPageData extends IDefaultProps {}

export const NotFoundPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'not-found-page'

  // ************************************
  // Helper Functionality
  // ************************************

  const _getData = async (config: INotFoundPageData) => {
    return {
      ...config,
      id: uuid4()
    } as INotFoundPage
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.notFound.type
    },
    handleData: _getData,
    render: (config: INotFoundPage) => {
      const { id, activeBrand, activeTheme, translations } = config

      return (
        <SubPageLayout>
          <ContentGrid
            id={id}
            className={`${classPrefix}`}
            brand={activeBrand}
            theme={activeTheme}
            alignment={'center'}
            direction={'column'}
            tagType={'nav'}
          >
            <StyleGrid
              brand={activeBrand}
              className={`${classPrefix}__icon-container`}
              direction='row'
              alignment='center'
            >
              <Icon
                brand={activeBrand}
                type={IconType.ErrorFace}
                color={BrandColors['background-shade-light-1']}
                width={7}
                height={7}
              />
            </StyleGrid>
            <Text
              {...h1TextPrefab()}
              className={`${classPrefix}__title`}
              color={BrandColors['primary-shade-light-2']}
            >
              {getText('title', translations)}
            </Text>
            <Text
              {...paragraphTextPrefab()}
              className={`${classPrefix}__subTitle`}
            >
              {getText('subTitle', translations)}
            </Text>
            <ActionButton
              className={`${classPrefix}__button`}
              template={MS_ButtonTemplate(activeTheme, 'secondary')}
              padding='default'
              brand={activeBrand}
              iconPlacement={'Left'}
              action={{
                link: Constants.paths.energyPage,
                icon: IconType.ArrowLeft,
                text: getText('back', translations)
              }}
            />
          </ContentGrid>
        </SubPageLayout>
      )
    }
  })
}
