import React, { useState } from 'react'
import { capitalizeFirstLetter, tNumber, getText } from '../../../services'
import { ColumnChartCard, MSRichText } from '../../../components'
import { getFilterOptions, getPageContent } from './EnergyConsumptionCostPageData'
import { format } from 'date-fns'
import { CommercialBlock, IPlankHouse, PlankHouseBlock, PlankWall } from '../../../blocks'
import nb from 'date-fns/esm/locale/nb/index.js'
import {
  Text,
  getCounterTheme,
  BrandColors,
  StyleGrid,
  ContentGrid,
  TabSelectFilter,
  ITabFilterState,
  LoaderGraphic
} from '@fjordkraft/fjordkraft.component.library'
import { IDateState } from '../../../models'
import { getPlankPrefab } from '../../../Prefabs'
import _ from 'lodash'
import classNames from 'classnames'
import { PopupCard } from '../../../modals'
import { Constants } from '../../../data'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import '../EnergyConsumptionPage.scss'

export interface IEnergyConsumptionCostPage extends IDefaultViewProps {
  house: IPlankHouse | null
  chartData: any
  dateState: IDateState
  selectedDate: Date
}

export const EnergyConsumptionCostPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'energy-consumption-page'

  // ************************************
  // Setup
  // ************************************

  const _hasSupport = (): boolean => {
    let savedShowSupport = localStorage.getItem('showCostSupport')

    if (savedShowSupport && savedShowSupport === 'true') {
      return true
    } else {
      return false
    }
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [loadingChart, setLoadingChart] = useState<boolean>(false)
  const [activeDate, setActiveDate] = useState<Date>(new Date())
  const [showSupportPopup, setShowSupportPopup] = useState<boolean>(false)
  const [includeSupport, setIncludeSupport] = useState<boolean>(_hasSupport())

  // ************************************
  // Render Functionality
  // ************************************

  const _renderChartFallback = (config: any) => {
    const { activeBrand, activeTheme, translations } = config

    return (
      <ContentGrid
        tagType='section'
        className={`${classPrefix}__fallback`}
        brand={activeBrand}
        direction='column'
        alignment='center'
      >
        <Text
          brand={activeBrand}
          theme={getCounterTheme(activeTheme)}
          weight={400}
          type={'p'}
          family={'main'}
          size={'regular'}
        >
          {getText('noData', translations)}
        </Text>
      </ContentGrid>
    )
  }

  const _renderChart = (config: IEnergyConsumptionCostPage) => {
    const { translations, activeBrand, activeTheme, chartData } = config

    if (chartData && activeDate) {
      let showFallback: boolean = _.toNumber(chartData.leftDescription.title) === 0

      return (
        <ColumnChartCard
          theme={activeTheme}
          brand={'brand-fjordkraft-chart'}
          className={`${classPrefix}__chart`}
          series={chartData.series}
          max={chartData.max}
          categories={[]}
          cardHeader={chartData.header}
          cardTopLeftText={chartData.leftDescription}
          cardTopRightText={chartData.rightDescription}
          stack={true}
          fallback={{
            show: showFallback,
            content: _renderChartFallback({
              translations: translations,
              activeBrand,
              activeTheme
            })
          }}
          chartTooltip={{
            show: true,
            x: {
              formatter: (value: any) => {
                let tooltipDate = new Date(activeDate.getFullYear(), activeDate.getMonth(), value)
                let dayName = capitalizeFirstLetter(format(tooltipDate, 'EEEE', { locale: nb }))
                return `${dayName} ${value}`
              }
            },
            y: {
              formatter: (value: any, opts: any) => {
                let returnVal = ''
                let dataPointIndex = opts.dataPointIndex ?? null

                // Support
                if (opts.series[2])
                  returnVal += `${getText('chartHoverBoxSupport', translations)}: ${tNumber(
                    opts.series[2][dataPointIndex],
                    'no-NO',
                    2
                  )} ${getText('currency', translations)}</br>`

                // Consumption
                returnVal += `${getText('chartHoverBoxConsumption', translations)}: ${tNumber(
                  opts.series[1][dataPointIndex],
                  'no-NO',
                  2
                )} ${getText('currency', translations)}</br>`

                // Grid tariff (Energy section)
                returnVal += `${getText('chartHoverBoxEnergySection', translations)}: ${tNumber(
                  opts.series[0][dataPointIndex],
                  'no-NO',
                  2
                )} ${getText('currency', translations)}`

                return returnVal
              },
              suffix: ` ${getText('currency', translations)}`,
              showOnAxisLabels: true
            }
          }}
        />
      )
    }
  }

  const _renderFilter = (config: IEnergyConsumptionCostPage) => {
    const { desktopView, activeBrand, activeTheme } = config

    return (
      <TabSelectFilter
        className={`${classPrefix}__filter`}
        brand={activeBrand}
        theme={activeTheme}
        curvy={desktopView}
        desktopView={desktopView}
        filters={getFilterOptions(activeDate)}
        onChange={(filterSettings: ITabFilterState) => {
          setActiveDate(filterSettings.selected?.value)
        }}
      />
    )
  }

  const _renderHouse = (config: IEnergyConsumptionCostPage) => {
    const { activeBrand, activeTheme, house } = config

    if (house) {
      return (
        <PlankHouseBlock
          {...house}
          className={`${classPrefix}__planks`}
          brand={activeBrand}
          theme={activeTheme}
        />
      )
    }
  }

  const _renderSupportPopup = (config: IEnergyConsumptionCostPage) => {
    const { translations, activeBrand, activeTheme } = config

    if (showSupportPopup) {
      return (
        <PopupCard
          contentTitle={getText('supportPopupTitle', translations)}
          contentGap={4}
          onClose={() => {
            setShowSupportPopup(false)
          }}
          secondaryAction={{
            text: getText('close', translations),
            onClick: () => {
              setShowSupportPopup(false)
            }
          }}
        >
          <MSRichText
            className={`${classPrefix}__popup__text`}
            theme={getCounterTheme(activeTheme)}
            brand={activeBrand}
            text={getText('supportPopupDescription', translations)}
            alignment='lawful'
          />
          <PlankWall
            planks={[
              getPlankPrefab('Toggle', {
                title: getText('plankToggleSupportTitle', translations),
                description: getText('plankToggleSupportDesc', translations),
                active: includeSupport,
                onChange() {
                  setIncludeSupport(!includeSupport)
                }
              })
            ]}
          />
        </PopupCard>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.consumptionCost.type,
      usesSubPage: true
    },
    dependencies: [{ selectedDate: activeDate }, { includeSupport }, { showSupportPopup }, { setShowSupportPopup }],
    handleData: getPageContent,
    onDataChange: (config: IEnergyConsumptionCostPage) => {
      setLoadingChart(false)
    },
    render: (config: IEnergyConsumptionCostPage) => {
      const { activeBrand, activeTheme, desktopView, translations, chartData } = config

      return (
        <StyleGrid
          className={`${classPrefix}`}
          direction='column'
          alignment='top-center'
          gap={2}
          gapType={desktopView ? 'rem' : 'px'}
        >
          <StyleGrid
            className={`${classPrefix}__filter-wrapper`}
            direction={'column'}
            alignment={'center'}
          >
            {_renderFilter(config)}
            {loadingChart && (
              <span
                className={classNames(`${classPrefix}__filter-wrapper__cover`, {
                  [`${classPrefix}__filter-wrapper__cover--${activeTheme}`]: activeTheme
                })}
              />
            )}
          </StyleGrid>
          <ContentGrid
            className={classNames(`${classPrefix}__chart-wrapper`, {
              [`${classPrefix}__chart-wrapper--background`]: !chartData,
              [`${classPrefix}__chart-wrapper--cost`]: true
            })}
            tagType='section'
            direction='column'
            alignment='center'
          >
            {loadingChart && (
              <LoaderGraphic
                className={`${classPrefix}__chart-wrapper__loader`}
                size={4}
                scaleThickness={0.5}
                colors={{
                  backgroudColor: BrandColors['background-shade-light-1'],
                  fillColor: BrandColors['primary-shade-light-2']
                }}
                theme={activeTheme}
                brand={activeBrand}
              />
            )}
            {!loadingChart && _renderChart(config)}
            {!chartData && !loadingChart && translations && (
              <Text
                weight={400}
                type={'p'}
                size={'regular'}
                family={'main'}
                theme={getCounterTheme(activeTheme)}
              >
                {getText('noData', translations)}
              </Text>
            )}
          </ContentGrid>
          {_renderHouse(config)}
          <CommercialBlock />
          {showSupportPopup && _renderSupportPopup(config)}
        </StyleGrid>
      )
    }
  })
}
