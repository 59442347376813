import { useContext, createContext } from 'react'

interface IApplicationReviewContext {
  resetTimer: () => void
}

export const ApplicationReviewContext = createContext<IApplicationReviewContext>({
  resetTimer: () => {}
})

export const useApplicationReviewContext = () => useContext(ApplicationReviewContext)
