import React from 'react'
import { Text, ToggleSwitch, IComponent, IMasterPlank } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import './TogglePlankPrefab.scss'
import { MS_ToggleSwitchTemplate } from '../../Templates/components/MS_ToggleSwitchTemplate'

export interface ITogglePlankPrefab extends IComponent {
  title: string
  description: string
  onChange?: (value?: any) => void
}

export const TogglePlankPrefab = (props: ITogglePlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, theme = 'Light', brand = 'brand-fjordkraft', active, title, description, onChange } = props
  const classPrefix = 'ms-toggle-plank'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderLeftTitle = () => {
    if (title) {
      return (
        <Text
          brand={brand}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
        >
          {title}
        </Text>
      )
    }
  }

  const _renderLeftDescription = () => {
    if (description) {
      return (
        <Text
          brand={brand}
          weight={400}
          size={'small'}
          family={'main'}
          type={'p'}
          faded={true}
        >
          {description}
        </Text>
      )
    }
  }

  const _renderToggleSwitch = () => {
    return (
      <ToggleSwitch
        theme={theme}
        brand={brand}
        template={MS_ToggleSwitchTemplate(theme)}
        active={active}
        onValueChange={(state: boolean) => {
          if (onChange) {
            onChange(state)
          }
        }}
      />
    )
  }

  // ************************************
  // Render
  // ************************************

  return {
    id,
    className: classNames(classPrefix, {
      [`${className}`]: className
    }),
    leftColumn2: [_renderLeftTitle(), _renderLeftDescription()],
    rightColumn2: [_renderToggleSwitch()]
  } as IMasterPlank
}
