import { useContext, createContext } from 'react'
import { ICustomerAccountInformation } from '../../models'

interface IApplicationAccountSelectContext {
  includeAllOption: boolean
  setIncludeAllOption: (state: boolean) => void
  selectedAccount?: ICustomerAccountInformation
  setSelectedAccount: (account?: ICustomerAccountInformation) => void
}

export const ApplicationAccountSelectContext = createContext<IApplicationAccountSelectContext>({
  includeAllOption: false,
  setIncludeAllOption: () => {},
  selectedAccount: undefined,
  setSelectedAccount: () => {}
})

export const useApplicationAccountSelectContext = () => useContext(ApplicationAccountSelectContext)
