import { useContext, createContext } from 'react'
import { Theme } from '@fjordkraft/fjordkraft.component.library'
import { SeasonalMode } from '../../models'

interface IApplicationProps {
  activeTheme: Theme
  setActiveTheme: (theme: Theme) => void
  activeBrand: string
  setActiveBrand: (brand: string) => void
  desktopView: boolean
  seasonalMode: SeasonalMode
  locales: string
  setLocales: (locales: string) => void
}

export const ApplicationContext = createContext<IApplicationProps>({
  activeTheme: 'Light',
  activeBrand: 'brand-fjordkraft',
  setActiveTheme: () => {},
  setActiveBrand: () => {},
  desktopView: true,
  seasonalMode: 'None',
  locales: 'nb',
  setLocales: () => {}
})

export const useApplicationContext = () => useContext(ApplicationContext)
