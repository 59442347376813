import React from 'react'
import {
  Text,
  ContentGrid,
  BrandColors,
  getCounterTheme,
  IconType,
  IMasterPlank,
  ITextCustomization,
  IComponentTemplate,
  IText
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { h1TextPrefab } from '../../Texts/Header1TextPrefab'
import { h3TextPrefab } from '../../Texts/Header3TextPrefab'
import { AnimatedCounter, MSRichText } from '../../../components'
import { tNumber } from '../../../services'
import { v4 as uuid4 } from 'uuid'
import { PointPlankTemplate } from '../../Templates/planks/PointPlankTemplate'
import '../PlankPrefabStyle.scss'
import { GetToastPrefab, ToastPrefabsType } from '../../Toasts'
import { Toast } from '../../../components/Toaster'

export interface IPointPlankPrefabCustomization {
  title?: ITextCustomization
  description?: ITextCustomization
}

export interface IPointPlankPrefab extends Omit<IMasterPlank, 'template'> {
  points: number
  pointsLabel: string
  pointsColor?: BrandColors
  useDecimals?: boolean
  desktopView?: boolean
  toast?: {
    icon: IconType
    text: string
    status: 'negative' | 'positive' | 'neutral'
  }
  left?: {
    title?: string
    description?: string
    dontUseCounterTheme?: boolean
    customization?: IPointPlankPrefabCustomization
  }
  right?: {
    title?: string
    description?: string
    customization?: IPointPlankPrefabCustomization
  }
  bottom?: {
    title?: string
    description?: string
  }
  template?: IComponentTemplate
}

export const PointPlankPrefab = (props: IPointPlankPrefab) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    theme = 'Light',
    brand,
    left,
    right,
    toast,
    points,
    pointsLabel,
    pointsColor = BrandColors['secondary-shade-light-1'],
    useDecimals = false,
    location = 'single',
    template = PointPlankTemplate(theme, location),
    desktopView = true,
    bottom
  } = props
  const classPrefix = 'ms-plank'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderBottomContent = (bottom?: { title?: string; description?: string }) => {
    if (bottom) {
      return (
        <ContentGrid
          tagType='section'
          alignment='top-left'
          direction='column'
          boxSizing='border-box'
          gap={1}
        >
          {bottom.title && (
            <Text
              brand={brand}
              weight={500}
              size={'regular'}
              family={'main'}
              type={'h4'}
              theme={getCounterTheme(theme)}
              align={'align-right'}
            >
              {bottom.title}
            </Text>
          )}
          {bottom.description && (
            <Text
              brand={brand}
              weight={400}
              size={'small'}
              family={'main'}
              align={'align-right'}
              type={'p'}
              theme={getCounterTheme(theme)}
            >
              {bottom.description}
            </Text>
          )}
        </ContentGrid>
      )
    }
  }

  const _renderTitle = (text?: string, customization?: IPointPlankPrefabCustomization) => {
    if (text) {
      return (
        <Text
          brand={brand}
          theme={getCounterTheme(theme)}
          weight={500}
          size={'regular'}
          family={'main'}
          type={'h4'}
          align={'align-left'}
          {...customization?.title}
        >
          {text}
        </Text>
      )
    }
  }

  const _renderDescription = (text?: string, customization?: ITextCustomization) => {
    if (text) {
      return (
        <MSRichText
          brand={brand}
          alignment='lawful'
          customization={customization as IText}
          text={text}
          theme={getCounterTheme(theme)}
        />
      )
    }
  }

  const _renderCounterGraphic = () => {
    return (
      <ContentGrid
        direction='row'
        alignment='bottom-left'
        gap={1}
        boxSizing='border-box'
        wrap={false}
        tagType='section'
      >
        {useDecimals && (
          <Text
            {...h1TextPrefab()}
            className={`${classPrefix}__content__animated-counter`}
            brand={brand}
            color={pointsColor}
          >
            {tNumber(points, 'no-NO')}
          </Text>
        )}
        {!useDecimals && (
          <AnimatedCounter
            {...h1TextPrefab()}
            className={`${classPrefix}__content__animated-counter`}
            brand={brand}
            from={0}
            n={points}
            size={'huge'}
            color={pointsColor}
          />
        )}
        <Text
          {...h3TextPrefab()}
          brand={brand}
          size={'medium'}
          color={pointsColor}
        >
          {pointsLabel}
        </Text>
      </ContentGrid>
    )
  }

  const _renderLeft = () => {
    if (left) {
      return [
        _renderStaticToast(toast, 'left'),
        _renderTitle(left.title, left.customization),
        _renderCounterGraphic(),
        _renderDescription(left.description, left?.customization?.description)
      ]
    }
  }

  const _renderRight = () => {
    if (right || (toast && desktopView)) {
      return [
        _renderStaticToast(toast, 'right'),
        _renderTitle(right?.title, right?.customization),
        _renderDescription(right?.description, right?.customization?.description)
      ]
    }
  }

  const _renderStaticToast = (toast: any, location: 'left' | 'right') => {
    if (toast && ((location === 'left' && !desktopView) || (location === 'right' && desktopView))) {
      return (
        <Toast
          key={uuid4()}
          className={`${classPrefix}__toast`}
          {...GetToastPrefab(ToastPrefabsType.StatusToast, {
            id: uuid4(),
            text: toast.text,
            status: toast.status
          })}
          staticToast={true}
          brand={brand}
          theme={getCounterTheme(theme)}
        />
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  return {
    id,
    className: classNames(classPrefix, {
      [`${className}`]: className
    }),
    theme,
    brand,
    template,
    leftColumn2: _renderLeft(),
    rightColumn1: _renderRight(),
    bottomContent: _renderBottomContent(bottom),
    customization: {
      wrapper: {
        alignment: 'top-center'
      },
      left: {
        scaling: toast ? 3 : 2,
        alignment: 'center-left'
      },
      right: {
        scaling: toast ? 2 : 1,
        alignment: 'top-right'
      }
    }
  } as IMasterPlank
}
