import { IDefaultViewProps, PageV2 } from '../PageV2'
import { Constants } from '../../data'
import { ContentGrid, IconType, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import { DynamicEpiContentBlock, PlankHouseBlock } from '../../blocks'
import classNames from 'classnames'
import {
  useApplicationOverlayWrapperContext,
  useApplicationServicehandlerContext,
  useDefaultPageContext
} from '../../contexts'

import FKASgraphic from '../../assets/art/homebanners/FKAS.svg?react'
import TKASgraphic from '../../assets/art/homebanners/TKAS.svg?react'
import NGEgraphic from '../../assets/art/homebanners/NGE.svg?react'
import { MainTitle } from '../../blocks/MainTitle/MainTitle'
import { getPlankPrefab } from '../../Prefabs'
import { IStatePlank } from '../../components'
import { createString, getText } from '../../services'
import { callStateToast } from '../../components/Toastify/Toastify'

export const HomePageNoAccess = () => {
  const pageType = Constants.epiServerPageNames.homeNoAccess
  const mobileRedirectAddition = 'Redirect/mobile'
  const classPrefix = 'home-page'
  const { setMainContentClamping } = useDefaultPageContext()
  const { setGlobalLoading } = useApplicationOverlayWrapperContext()
  const { GET } = useApplicationServicehandlerContext()
  const getPageContent = async (config: IDefaultViewProps) => {
    return {
      ...config
    }
  }
  const _getPlanks = (config: IDefaultViewProps): IStatePlank[] => {
    const { setChosenHost, setHostIdForCustomerDataRequests } = config.relationship
    return [
      getPlankPrefab('Text', {
        action: {
          onClick: async () => {
            setGlobalLoading(true)
            const resp = await GET(mobileRedirectAddition)
            if (resp?.callState === 'success') {
              window.location.href = resp.data
            } else {
              setGlobalLoading(false)
              callStateToast({
                text: getText(`mobileRedirect_error`, config.translations),
                callState: resp.callState
              })
            }
          }
        },
        left: {
          title: getText('areYouMobileCustomer', config.translations),
          description: getText('fjordkraftMobile', config.translations)
        },
        right: {
          title: getText('logInWithMobile', config.translations),
          icon: IconType.ChevronRight
        }
      }),
      ...(config?.relationship?.hosts?.map(host =>
        getPlankPrefab('Text', {
          action: {
            onClick: () => {
              setGlobalLoading(true)
              setChosenHost(host)
              setHostIdForCustomerDataRequests(host.customerId)
            }
          },
          left: {
            title: createString(getText('youAreGuestOf', config.translations), { name: host.firstName.split(' ')[0] }),
            description: getText('guestUser', config.translations)
          },
          right: {
            title: createString(getText('logInAsGuest', config.translations), { name: host.firstName.split(' ')[0] }),
            icon: IconType.ChevronRight
          }
        })
      ) ?? [])
    ]
  }
  return PageV2({
    setup: {
      pageType: pageType.type
    },
    loadingComplete: () => {
      setTimeout(() => {
        setGlobalLoading(false)
      }, 200)
    },
    onDataChange: (config: IDefaultViewProps) => {
      setMainContentClamping(config.desktopView ? 'clamp' : 'stretch')
    },
    handleData: getPageContent,
    render: (config: IDefaultViewProps) => {
      const { desktopView, activeBrand, relationship, activeTheme } = config
      const { hosts } = relationship
      const bannerClassName = `${classPrefix}__banner`
      const bannerPrefix = 'home-page-banner-block'
      return (
        <StyleGrid
          id={`${classPrefix}-id`}
          className={`${classPrefix}`}
          direction='column'
          alignment={'top-left'}
          gap={10}
        >
          {config && (
            <ContentGrid
              className={classNames(`${bannerPrefix}`, {
                [`${bannerClassName}`]: bannerClassName
              })}
              direction='column'
              alignment={!desktopView && hosts?.length === 0 ? 'top-left' : 'top-center'}
              gap={2}
              tagType={'section'}
            >
              <MainTitle />
              {config.translations?.titleEditorialContent && (
                <DynamicEpiContentBlock
                  epiItems={config.translations.titleEditorialContent}
                  theme={activeTheme}
                  brand={activeBrand}
                />
              )}
              <PlankHouseBlock
                plankWalls={[
                  {
                    planks: _getPlanks(config)
                  }
                ]}
              />

              {config.translations?.topEditorialContent && (
                <DynamicEpiContentBlock
                  epiItems={config.translations.topEditorialContent}
                  theme={activeTheme}
                  brand={activeBrand}
                />
              )}
              <StyleGrid
                className={classNames(classPrefix, {
                  [`${bannerClassName}`]: bannerClassName
                })}
                direction='column'
                alignment='center'
                boxSizing='border-box'
                wrap={false}
              >
                {activeBrand === 'brand-fjordkraft' && <FKASgraphic className={`${classPrefix}__graphic`} />}
                {activeBrand === 'brand-trondelagkraft' && <TKASgraphic className={`${classPrefix}__graphic`} />}
                {activeBrand === 'brand-nges' && <NGEgraphic className={`${classPrefix}__graphic`} />}
              </StyleGrid>
            </ContentGrid>
          )}
          <StyleGrid
            className={`${classPrefix}__middle`}
            alignment='top-center'
            direction='column'
          ></StyleGrid>
        </StyleGrid>
      )
    }
  })
}
