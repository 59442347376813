import { IAction } from '@fjordkraft/fjordkraft.component.library'
import React, { useEffect, useMemo, useState } from 'react'
import {
  IDefaultPageContentClampType,
  useApplicationCoreDataContext,
  useApplicationDefaultContext,
  useDefaultPageContext,
  useSubPageLayoutContext
} from '../contexts'
import _ from 'lodash'
import { IDefaultProps, PageType } from '../models'
import { IStatusToast } from './Base/SubPageLayout/SubPageLayout'
import {
  assignedAllDepndencies,
  convertMapToObject,
  convertObjectArrayToMap,
  getEpiServicePage,
  logger,
  useEffectDebugger
} from '../services'

export interface IDefaultViewProps extends IDefaultProps {
  sub?: {
    title?: string
    subTitle?: string
    description?: string
    tooltip?: string
    tooltipDescription?: string
    back?: IAction
    showInstallationSelector?: boolean
    statusToast?: IStatusToast
  }
}

export interface IPageV2 {
  setup: {
    pageType: PageType
    subPageType?: string
    usesSubPage?: boolean
    loadingDelayMS?: number
    pageClamp?: IDefaultPageContentClampType
    clearRequiredOnStateChange?: boolean
    clearOptionalOnStateChange?: boolean
  }
  dependencies?: any[]
  dependenciesOptional?: any[]
  startedRender?: () => void
  renderUpdate?: () => void
  loadingComplete?: () => void
  fallbackRender?: () => any
  handleData: (config: any) => Promise<any>
  onDataChange?: (config: any) => void
  render: (config?: any) => React.ReactElement | undefined
}

const PAGE_DEP_REQUIRED: Map<string, any> = new Map<string, any>()
const PAGE_DEP_OPTIONAL: Map<string, any> = new Map<string, any>()
const PREVIOUS_PAGE_CONTENT: Map<string, any> = new Map<string, any>()

export const PageV2 = (props: IPageV2) => {
  // ************************************
  // Properties
  // ************************************

  const { defaultProps } = useApplicationDefaultContext()
  const { translations, epiChildren } = defaultProps
  const { setContentLoading, ScrollIntoAppView, setMainContentClamping } = useDefaultPageContext()
  const { setTranslation } = useApplicationCoreDataContext()
  const {
    setTitle,
    setBack,
    setDescription,
    setSubTitle,
    setDescriptionTooltip,
    setStatusToast,
    setShowInstallationSelector
  } = useSubPageLayoutContext()
  const {
    setup,
    dependencies,
    dependenciesOptional,
    startedRender,
    renderUpdate,
    loadingComplete,
    fallbackRender,
    handleData,
    onDataChange,
    render
  } = props

  // ************************************
  // Lifecycle
  // ************************************

  const [content, setContent] = useState<any>()

  // Initial and once render starts
  useEffect(() => {
    PAGE_DEP_OPTIONAL.clear()
    PAGE_DEP_REQUIRED.clear()

    ScrollIntoAppView()

    if (startedRender) {
      startedRender()
    }
  }, [])

  // Each rerender
  useEffect(() => {
    if (setup?.pageClamp) {
      setMainContentClamping(setup.pageClamp)
    }

    if (renderUpdate) {
      renderUpdate()
    }
  })

  // DEFAULT DEPENDENCIES
  useEffectDebugger(
    () => {
      if (defaultProps) {
        _getTranslations(defaultProps.epiChildren)
        _updatePageData(defaultProps)
      }
    },
    [defaultProps],
    ['pagev2 defaultProps']
  )

  useEffect(() => {
    if (setup.clearRequiredOnStateChange) {
      setContent(undefined)
    }

    if (dependencies && !assignedAllDepndencies(dependencies, PAGE_DEP_REQUIRED)) {
      _assignDependencies(dependencies, PAGE_DEP_REQUIRED)

      if (PAGE_DEP_REQUIRED && PAGE_DEP_REQUIRED.size > 0) {
        if (_hasAllDependenciesInMap(PAGE_DEP_REQUIRED)) {
          _updatePageData()
        }
      }
    }
  }, [dependencies])

  useEffect(() => {
    if (setup.clearOptionalOnStateChange) {
      setContent(undefined)
    }

    if (dependenciesOptional && _canUpdateOptionalDependencies(dependenciesOptional)) {
      _assignDependencies(dependenciesOptional, PAGE_DEP_OPTIONAL)

      if (PAGE_DEP_OPTIONAL && PAGE_DEP_OPTIONAL.size > 0) {
        _updatePageData()
      }
    }
  }, [dependenciesOptional])

  // Page content loading and handling
  useEffect(() => {
    if (content) {
      if (onDataChange) {
        onDataChange(content)
      }

      setTimeout(() => {
        setContentLoading(false)

        if (loadingComplete) {
          loadingComplete()
        }
      }, setup.loadingDelayMS ?? 200)
    }
  }, [content])

  // ************************************
  // Helpers
  // ************************************

  const _assignDependencies = (deps: any[], ref: Map<string, any>) => {
    for (const obj of deps) {
      if (obj !== undefined && obj !== null) {
        for (const [key, value] of Object.entries(obj)) {
          ref.set(key, value)
        }
      }
    }
  }

  const _canUpdateOptionalDependencies = (deps: any[]): boolean => {
    let mappedDeps: Map<string, any> = convertObjectArrayToMap(deps)

    if (_.isEqual(mappedDeps, PAGE_DEP_OPTIONAL)) {
      return false
    }

    return true
  }

  const _hasAllDependenciesInMap = (deps: Map<string, any>, ignore?: string[]): boolean => {
    let hasAllDependencies: boolean = true

    if (deps && deps.size > 0) {
      for (let [key, value] of deps) {
        if (ignore && ignore.length > 0) {
          for (let ignoreKey of ignore) {
            if (key !== ignoreKey) {
              if (value === undefined || value === null) {
                hasAllDependencies = false
                break
              }
            }
          }
        } else if (value === undefined || value === null) {
          hasAllDependencies = false
          break
        }
      }
    }

    return hasAllDependencies
  }

  const _parseDependencies = (def: IDefaultProps, req: any, opt: any) => {
    return { ...def, ...convertMapToObject(req), ...convertMapToObject(opt) }
  }

  const _getTranslations = (epiChildren: any) => {
    let pageTranslations

    if (!setup.subPageType && _.isEqual(epiChildren[setup.pageType].data, translations)) {
      pageTranslations = translations
    } else if (setup.subPageType) {
      pageTranslations = getEpiServicePage(epiChildren[setup.pageType], setup.subPageType)
    } else {
      pageTranslations = epiChildren[setup.pageType].data
    }

    setTranslation(pageTranslations)

    return pageTranslations
  }

  const _isCorrectTranslation = (defaultProps: IDefaultProps): boolean => {
    if (epiChildren && defaultProps.translations) {
      if (
        (!setup.subPageType && _.isEqual(epiChildren[setup.pageType].data, defaultProps.translations)) ||
        (setup.subPageType &&
          _.isEqual(getEpiServicePage(epiChildren[setup.pageType], setup.subPageType), defaultProps.translations))
      ) {
        return true
      }
    }

    return false
  }

  const _isCorrectUserData = (defaultProps: IDefaultProps): boolean => {
    const { relationship, user } = defaultProps
    const { chosenHost } = relationship
    return chosenHost ? chosenHost.customerId === user.userData?.customerId : true
  }

  // ************************************
  // Handle data
  // ************************************

  const _updatePageData = async (def?: IDefaultProps) => {
    if (_hasAllDependenciesInMap(PAGE_DEP_REQUIRED) && (def || defaultProps)) {
      if (_isCorrectTranslation(def ?? defaultProps) && _isCorrectUserData(defaultProps)) {
        let parsedProperties = _parseDependencies(def ?? defaultProps, PAGE_DEP_REQUIRED, PAGE_DEP_OPTIONAL)

        if (parsedProperties) {
          _handleContentDelivery(await handleData(parsedProperties))
        }
      }
    }
  }

  const _handleContentDelivery = (data: any) => {
    if (data) {
      let previousContentKey = `${setup.pageType}${setup.subPageType ?? ''}`
      let previousContent: any = PREVIOUS_PAGE_CONTENT.get(previousContentKey)

      if (setup.usesSubPage) {
        _handleSubPageData(data)
      }

      if (previousContent) {
        if (!_.isEqual(data, previousContent)) {
          PREVIOUS_PAGE_CONTENT.set(previousContentKey, data)
          setContent(data)
        } else if (_.isEqual(data, previousContent) && !content) {
          setContent(previousContent)
        }
      } else {
        PREVIOUS_PAGE_CONTENT.set(previousContentKey, data)
        setContent(data)
      }
    }
  }

  const _handleSubPageData = (data: IDefaultViewProps) => {
    setTitle(data?.sub?.title)
    setSubTitle(data?.sub?.subTitle)
    setDescription(data?.sub?.description)
    setDescriptionTooltip(data?.sub?.tooltipDescription)
    setBack(data?.sub?.back)
    setStatusToast(data?.sub?.statusToast)
    setShowInstallationSelector(data?.sub?.showInstallationSelector ?? false)
  }

  // ************************************
  // Render
  // ************************************

  return useMemo(() => {
    if (content) {
      logger(`-- Render: ${content.translations?.modelType} --`, 'log')
      return render(content)
    } else if (!content && !!fallbackRender) {
      return fallbackRender()
    } else {
      return <></>
    }
  }, [content])
}
