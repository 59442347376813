import { Constants } from '../../../data'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import { getPageContent } from './ConsentPageData'
import { DetailCheckboxCollectionBlock, IDetailCheckboxCollectionBlockData } from '../../../blocks'
import { v4 as uuid4 } from 'uuid'
import { getCounterTheme, StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import { ConsentStateEnum, IConsent, MarketingConsentType } from '../../../models'
import { MSRichText } from '../../../components'
import './ConsentPage.scss'
import { useConsentContext } from '../../../contexts'
import { translateCheckboxValue } from '../../../services'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'

export interface IConsentPage extends IDefaultViewProps {
  detailCheckboxCollection: IDetailCheckboxCollectionBlockData[]
  description: string
  consentStates: IConsent[]
  isReservedAgainstMarketing: boolean
  isGuest: boolean
}

export const ConsentPage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'consent-page'
  const { consents, PUT_CONSENTS, isReservedAgainstMarketing } = useConsentContext()
  const { isGuest } = useApplicationGuestsAndHostsContext()

  // ************************************
  // Handling
  // ************************************

  const _handleConsentSettings = async (id: MarketingConsentType, state: boolean, translations: any) => {
    const newConsentsTypes = [
      MarketingConsentType.PersonalizedMarketing,
      MarketingConsentType.GeneralMarketing,
      MarketingConsentType.DisableMarketing
    ]
    let consentsToUpdate = [{ consentName: id, value: translateCheckboxValue(state) }]

    //if any of the other consents are pending, set them to DECLINED
    consents?.forEach(consent => {
      if (newConsentsTypes.includes(consent.consentName) && consent.consentName !== id) {
        if (consent.value === ConsentStateEnum.PENDING) {
          consentsToUpdate.push({
            consentName: consent.consentName,
            value: ConsentStateEnum.DECLINED as ConsentStateEnum
          })
        }
        if (isReservedAgainstMarketing && consent.consentName === MarketingConsentType.DisableMarketing) {
          consentsToUpdate.push({
            consentName: consent.consentName,
            value: ConsentStateEnum.DECLINED as ConsentStateEnum
          })
        }
      }
    })

    PUT_CONSENTS(consentsToUpdate, translations)
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderDetailCheckboxCollections = (config: IConsentPage) => {
    const { detailCheckboxCollection, activeBrand, activeTheme, isGuest } = config

    return detailCheckboxCollection.map((item: IDetailCheckboxCollectionBlockData) => {
      return (
        <DetailCheckboxCollectionBlock
          {...item}
          key={uuid4()}
          className={classPrefix}
          disabled={isGuest}
          theme={activeTheme}
          brand={activeBrand}
          onChange={(value, state) => {
            _handleConsentSettings(value, state, config.translations)
          }}
        />
      )
    })
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.consentPage.type,
      usesSubPage: true,
      pageClamp: 'clamp'
    },
    dependencies: [{ consents }, { isReservedAgainstMarketing }, { isGuest }],
    handleData: getPageContent,
    render: (config: IConsentPage) => {
      const { activeBrand, activeTheme, description, desktopView } = config

      return (
        <StyleGrid
          className={`${classPrefix}`}
          direction='column'
          alignment='top-left'
          gap={4}
          boxSizing='border-box'
        >
          <MSRichText
            className={`${classPrefix}__description`}
            brand={activeBrand}
            theme={getCounterTheme(activeTheme)}
            text={description}
            alignment='lawful'
            customization={{
              type: 'p',
              align: desktopView ? 'align-center' : 'align-left'
            }}
          />
          {_renderDetailCheckboxCollections(config)}
        </StyleGrid>
      )
    }
  })
}
