import React from 'react'
import { ContentGrid, getCounterTheme, StyleGrid, Text } from '@fjordkraft/fjordkraft.component.library'
import { IBlock } from '../../models'
import { h3TextPrefab } from '../../Prefabs'
import { MSRichText } from '../../components'
import classNames from 'classnames'
import { DetailCheckboxItem, IDetailCheckboxItemData } from './DetailCheckboxItem/DetailCheckboxItem'
import { DetailCheckboxItemTemplate } from './DetailCheckboxItem/DetailCheckboxItemTemplate'
import { v4 as uuid4 } from 'uuid'

import './DetailCheckboxCollectionBlock.scss'

export interface IDetailCheckboxCollectionBlockData extends IBlock {
  title?: string
  items: IDetailCheckboxItemData[]
  description?: string
}

interface IDetailCheckboxCollectionBlock extends IDetailCheckboxCollectionBlockData {
  onChange: (value: any, state: boolean) => void
}

export const DetailCheckboxCollectionBlock = (props: IDetailCheckboxCollectionBlock) => {
  // ************************************
  // Properties
  // ************************************

  const { id, className, title, items, description, theme = 'Light', brand, disabled, onChange } = props
  const classPrefix = 'detail-checkbox-collection'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderItems = () => {
    return items.map((item: IDetailCheckboxItemData, index: number) => {
      let location: 'top' | 'bottom' | 'single' | undefined = undefined

      if (items.length === 1) {
        location = 'single'
      } else if (index === 0) {
        location = 'top'
      } else if (index === items.length - 1) {
        location = 'bottom'
      }

      return (
        <DetailCheckboxItem
          {...item}
          key={uuid4()}
          location={location}
          theme={theme}
          brand={brand}
          disabled={disabled}
          template={DetailCheckboxItemTemplate(theme, location)}
          onChange={(value, state) => onChange(value, state)}
        />
      )
    })
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ContentGrid
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      alignment='top-left'
      direction='column'
      gap={3}
      tagType='article'
    >
      {title && <Text {...h3TextPrefab()}>{title}</Text>}
      {items?.length > 0 && (
        <StyleGrid
          className={`${classPrefix}__grid`}
          direction='column'
          alignment='top-left'
          gap={2}
          gapType='px'
        >
          {_renderItems()}
        </StyleGrid>
      )}
      {description && (
        <MSRichText
          brand={brand}
          text={description}
          theme={getCounterTheme(theme)}
          alignment={'lawful'}
          customization={{
            type: 'p',
            faded: true
          }}
        />
      )}
    </ContentGrid>
  )
}
