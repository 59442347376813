import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../../data'
import { AllowFeature, createString, getText } from '../../../services'
import { IHomePageData } from '../HomePageData'
import { _getTextPlankPrefabTemplate } from '.'
import { IStatePlank } from '../../../components'

export const InboxPlank = (config: IHomePageData) => {
  const { translations, services } = config
  const { user, customerServiceFeature } = services

  if (AllowFeature(Constants.features.inbox, translations, user, customerServiceFeature)) {
    return {
      promise: fetchMessagesPlank(config)
    } as IStatePlank
  }
}

const fetchMessagesPlank = async (props: IHomePageData) => {
  const { activeTheme, activeBrand, translations, services, desktopView } = props
  const { GET } = services

  let messagesDesc: string = getText('plankMessagesDescRead', translations)
  let unreadMesgCount = await GET('Messages/unread')

  if (unreadMesgCount?.callState === 'success' && unreadMesgCount?.data) {
    messagesDesc =
      unreadMesgCount.data > 0
        ? createString(getText('plankMessagesDescUnread', translations), {
            amount: unreadMesgCount.data
          })
        : getText('plankMessagesDescRead', translations)
  }

  return _getTextPlankPrefabTemplate({
    theme: activeTheme,
    brand: activeBrand,
    title: getText('plankMessagesTitle', translations),
    description: messagesDesc,
    link: Constants.paths.messagesPage,
    iconLeft: IconType.EnvelopeLowDetail,
    iconRight: IconType.ChevronRight
  })
}
