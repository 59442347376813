import { BrandColors, CallState, IAction, IconType } from '@fjordkraft/fjordkraft.component.library'
import { IMSPlankWall } from '../../../blocks'
import {
  ICustomer,
  IGuestRelationship,
  IDefaultProps,
  IRelationSentInvitation,
  IRelationRecievedInvitation
} from '../../../models'
import { IResponse, createString, getListedAddresses, getText } from '../../../services'
import { Constants } from '../../../data'
import { IStatePlank } from '../../../components'
import { getPlankPrefab } from '../../../Prefabs'
import { IGuestAdminPopupData } from '../../../modals'
import { format } from 'date-fns'

// ************************************
// Interfaces
// ************************************

export interface IGuestAdminPageData extends IDefaultProps {
  setPopupData: (data?: IGuestAdminPopupData) => void
  updateCustomerData: () => Promise<{ callState: CallState; data: ICustomer }>
  maxGuests?: number
  queryParams?: URLSearchParams
  pendingGuests?: IRelationSentInvitation[]
}

// ************************************
// Public (main)
// ************************************

export const getPageContent = async (config: IGuestAdminPageData) => {
  const { translations, setPopupData } = config
  const hosts = config.relationship.hosts

  if (translations && setPopupData !== undefined) {
    let maxGuests: number = parseInt(getText('maxGuests', translations))
    config.maxGuests = isNaN(maxGuests) ? 0 : maxGuests

    return {
      ...config,
      sub: {
        title: _getTitle(config),
        back: _getBack(config),
        subTitle: _getSubTitle(config)
      },
      house: { plankWalls: await _assemblePlankWalls(config) },
      hosts
    }
  }
}

// ************************************
// Private (handling)
// ************************************

const _getBack = (config: IGuestAdminPageData): IAction => {
  return {
    link: Constants.paths.userPage,
    text: getText('pageBack', config.translations)
  }
}

const _getTitle = (config: IGuestAdminPageData): string => {
  return getText('pageTitle', config.translations)
}

const _getSubTitle = (config: IGuestAdminPageData): string => {
  return createString(getText('pageSubTitle', config.translations), {
    amount: config.maxGuests ?? 0
  })
}

const _assemblePlankWalls = async (config: IGuestAdminPageData): Promise<IMSPlankWall[]> => {
  const addIfPlanks = (imsPlankWall: IMSPlankWall) => (imsPlankWall.planks.length > 0 ? [imsPlankWall] : [])
  const t = config.translations
  const planks = await _plankFetchingHandling(config)
  return [
    ...addIfPlanks({ planks: planks.topPlanks }),
    ...addIfPlanks(_getPlankWall(t, 'plankWallYourGuestsTitle', planks.yourGuestPlanks)),
    ...addIfPlanks(_getPlankWall(t, 'plankWallInvitationsRecieved', planks.invitationsRecievedPlanks)),
    ...addIfPlanks(_getPlankWall(t, 'plankWallInvitationsSent', planks.invitationsSentPlanks)),
    ...addIfPlanks(_getPlankWall(t, 'plankWallInvitationHistory', planks.invitationPlanksHistory))
  ]
}

const _getPlankWall = (translations: any, titleKey: string, planks: IStatePlank[]): IMSPlankWall => {
  return {
    title: getText(titleKey, translations),
    titleCustomization: {
      weight: 500,
      faded: true
    },
    customization: {
      titleGap: 1,
      backgroundColor: {
        Light: BrandColors['background-shade-light-2'],
        Dark: BrandColors['background-shade-dark-3']
      }
    },
    planks
  }
}

const _plankFetchingHandling = async (config: IGuestAdminPageData) => {
  const { services, relationship } = config
  const { GET } = services
  const { guests, hosts } = relationship

  let invitations: IResponse = await GET('Guest/invite/statuses')
  let { history, planksRecieved, planksSent } = _getInvitationPlanks({
    config,
    invitationsSent: invitations?.data?.sentInvitations,
    invitationsRecieved: invitations?.data?.receivedInvitations
  })

  return {
    topPlanks: [_getInvitePlank({ ...config, pendingGuests: invitations?.data?.sentInvitations })],
    yourGuestPlanks: _getDeletePlanks(config, 'remove-guest', guests),
    invitationsSentPlanks: planksSent,
    invitationsRecievedPlanks: planksRecieved,
    invitationPlanksHistory: history
  }
}
const _getDeletePlanks = (
  config: IGuestAdminPageData,
  deleteType: 'remove-guest' | 'remove-host',
  list?: IGuestRelationship[]
) => {
  let planks: IStatePlank[] = []

  if (list && list.length > 0) {
    for (let item of list) {
      planks.push(_getDeletePlank(config, item, deleteType))
    }
  }

  return planks
}

interface IGetInvitationPlanks {
  config: IGuestAdminPageData
  invitationsSent?: IRelationSentInvitation[]
  invitationsRecieved?: IRelationRecievedInvitation[]
}

const _getInvitationPlanks = (props: IGetInvitationPlanks) => {
  const { config, invitationsSent, invitationsRecieved } = props

  let planksSent: IStatePlank[] = []
  let planksRecieved: IStatePlank[] = []
  let history: IStatePlank[] = []

  if (invitationsSent && invitationsSent.length > 0) {
    for (let s of invitationsSent) {
      if (s?.status === 'PENDING') {
        planksSent.push(_getSendtInvitePlank(config, s))
      } else {
        history.push(_getSendtInvitePlank(config, s))
      }
    }
  }

  if (invitationsRecieved && invitationsRecieved.length > 0) {
    for (let r of invitationsRecieved) {
      if (r?.status === 'PENDING') {
        planksRecieved.push(_getRecievedInvitePlank(config, r))
      } else {
        history.push(_getRecievedInvitePlank(config, r))
      }
    }
  }

  return { planksSent, planksRecieved, history }
}

// ************************************
// Private (Plank handling)
// ************************************

const _getSendtInvitePlank = (config: IGuestAdminPageData, data: IRelationSentInvitation): IStatePlank => {
  return getPlankPrefab('Text', {
    left: {
      title: getText(`plankInviteTitle-${data.status}`, config.translations),
      description: `${format(new Date(data.birthDate), 'dd.MM.yyyy')}, ${data.phoneNumber}`
    },
    right: {
      description: getText(`plankDeleteValue-${data.status}`, config.translations),
      customization: {
        description: {
          color: _getStatusColor(data.status)
        }
      }
    }
  })
}

const _getInvitePlank = (config: IGuestAdminPageData): IStatePlank => {
  const { translations, setPopupData, maxGuests, relationship, queryParams, pendingGuests } = config
  const { guests, isGuest } = relationship

  if (queryParams) {
    let state: boolean = queryParams.get('showGuestInvite') === 'true'

    if (state) {
      setPopupData({
        purpose: 'add',
        translations,
        selectedUser: undefined,
        maxGuests,
        pendingGuests
      })
    }
  }

  return getPlankPrefab('Text', {
    action: {
      onClick: () => {
        setPopupData({
          purpose: 'add',
          translations,
          selectedUser: undefined,
          maxGuests,
          pendingGuests
        })
      },
      disabled: isGuest || (guests && guests.length === maxGuests)
    },
    left: {
      title: getText('plankInviteGuestTitle', translations),
      icon: IconType.PersonAdd,
      customization: {
        icon: {
          type: IconType.PersonAdd,
          color: BrandColors['primary-shade-light-2']
        }
      }
    },
    right: {
      title: createString(getText('plankInviteGuestValue', translations), {
        amount: guests ? guests.length : 0,
        total: maxGuests ?? 0
      }),
      icon: IconType.ChevronRight
    }
  })
}

const _getRecievedInvitePlank = (config: IGuestAdminPageData, data: IRelationRecievedInvitation) => {
  return getPlankPrefab('Text', {
    left: {
      title: createString(getText('plankHostInviteTitle', config.translations), {
        name: ''
      }),
      description: `${data.sender?.firstName} ${data.sender?.lastName}`
    },
    right: {
      icon: data.status === 'PENDING' ? IconType.ExternalLinkThick : undefined,
      description: getText(`plankDeleteValue-${data.status}`, config.translations),
      customization: {
        description: {
          color: _getStatusColor(data.status)
        }
      }
    },
    action:
      data.status === 'PENDING'
        ? {
            link: data.invitationUrl
          }
        : undefined
  })
}

const _getDeletePlank = (
  config: IGuestAdminPageData,
  userData: IGuestRelationship,
  type: 'remove-guest' | 'remove-host'
): IStatePlank => {
  const { setPopupData, translations } = config

  let desc: string = ''

  if (type === 'remove-guest') {
    desc = `${format(new Date(userData.birthDate), 'dd.MM.yyyy')}, ${userData.phoneNumber}`
  } else {
    desc = getListedAddresses(userData)
  }

  return getPlankPrefab('Text', {
    action: {
      onClick: () => {
        setPopupData({
          purpose: type,
          translations,
          selectedUser: userData
        })
      }
    },
    left: {
      title: userData.firstName,
      description: desc
    },
    right: {
      icon: IconType.TrashFilled,
      customization: {
        icon: {
          type: IconType.TrashFilled,
          color: BrandColors['status-shade-light-3']
        }
      }
    }
  })
}

// ************************************
// Private (Plank handling) - Helpers
// ************************************

const _getStatusColor = (status: string): BrandColors => {
  switch (status) {
    case 'ACCEPTED':
      return BrandColors['status-shade-light-1']
    case 'FAILED':
    case 'EXPIRED':
      return BrandColors['status-shade-light-3']
    case 'PENDING':
      return BrandColors['status-shade-light-2']
    default:
      return BrandColors['text-shade-dark-3']
  }
}
