import { ICustomer, ICustomerInstallation, IDefaultProps } from '../../models'
import { createString, getParsedAddonStates, getText, IsActiveCustomer } from '../../services'
import { IMSPlankWall } from '../../blocks'
import {
  BenefitPlank,
  ElectricityAgreementPlank,
  GetConsumptionPlanks,
  InboxPlank,
  InvoicePlank,
  MovingPlank,
  MySettingsPlank,
  OnboardingPlank,
  ServicesPlank,
  SpotpricePlank,
  SteddiPlank,
  TrumfPlank
} from './FrontServices'
import { InstallationStatusPlank } from './FrontServices/InstallationStatusPlank'
import { NoInstallationMeterPlank } from './FrontServices/NoInstallationMeterPlank'
import { IArticleItem } from '../../components'
import { Constants } from '../../data'
import { getAvailableServicePages } from '../ServicesPagesWrapper/ServicesOverviewPage/ServicesOverviewPageData'
import { getRequest } from '../../contexts'

// ************************************
// Main (Public)
// ************************************

export interface IHomePageData extends IDefaultProps {
  setShowAddInstallationMeter: (state: boolean) => void
}

export const getPageContent = async (config: IHomePageData) => {
  const { user, translations } = config
  const { userData } = user

  if (translations) {
    return {
      ...config,
      sub: {
        title: _getTitle(userData, translations)
      },
      article: translations.article,
      plankWalls: _getPlankWalls(config),
      upgradeOptions: translations.upgradeOptions,
      onboardingArticle: _getOnboardingArticleItem(config)
    }
  }
}

// ************************************
// Other handling
// ************************************

const getAddonStatesWrapper = async (config: IHomePageData) => {
  return await getAddonStates(
    config.epiChildren,
    config.services.customerServiceFeature,
    config.services.user,
    config.services.GETTYPED,
    config.services.GET,
    config.user.userData,
    config.user.installation
  )
}

export const getAddonStates = async (
  epiChildren: any,
  customerServiceFeature: boolean,
  user: any,
  GETTYPED: getRequest,
  GET: getRequest,
  userData?: ICustomer,
  installation?: ICustomerInstallation
) => {
  const addonStates = await getParsedAddonStates(epiChildren, GET)
  return await getAvailableServicePages(
    epiChildren,
    customerServiceFeature,
    user,
    GETTYPED,
    userData,
    installation,
    addonStates
  )
}

const _getTitle = (userData?: ICustomer, translations?: any): string => {
  if (userData) {
    return createString(getText('pageTitleExtended', translations), {
      name: `${userData.firstName.split(' ')[0]}`
    })
  } else {
    return ''
  }
}

// ************************************
// Async Fetching (Private)
// ************************************

const _getPlankWalls = (props: IHomePageData) => {
  const { user } = props
  const { installation } = user

  let topWalls: IMSPlankWall[] = []
  let middleWalls: IMSPlankWall[] = []
  let bottomWalls: IMSPlankWall[] = []

  let itemGroupHighlighted = {
    planks: []
  } as IMSPlankWall

  let itemGroupInstallation = {
    planks: []
  } as IMSPlankWall

  let itemGroupPerson = {
    planks: []
  } as IMSPlankWall

  let itemGroupUniques = {
    planks: []
  } as IMSPlankWall

  let itemGroupPersonSecondary = {
    planks: []
  } as IMSPlankWall

  let consumption = undefined
  let installationStatus = undefined
  let steddi = undefined
  let spotpris = undefined
  let agreement = undefined
  let services = undefined
  let invoice = undefined
  let benefits = undefined
  let move = undefined
  let trumf = undefined
  let inbox = undefined
  let settings = undefined
  let onboarding = undefined
  let noInstallationMeterPlank = undefined

  if (IsActiveCustomer(installation) && installation?.physicalMeterNumber == null) {
    noInstallationMeterPlank = NoInstallationMeterPlank(props)
  } else {
    consumption = GetConsumptionPlanks(props)
    installationStatus = InstallationStatusPlank(props)
    steddi = SteddiPlank(props)
    spotpris = SpotpricePlank(props)
    agreement = ElectricityAgreementPlank(props)
    services = ServicesPlank(props)
    invoice = InvoicePlank(props)
    benefits = BenefitPlank(props)
    move = MovingPlank(props)
    trumf = TrumfPlank(props)
    inbox = InboxPlank(props)
    settings = MySettingsPlank(props)
    onboarding = OnboardingPlank(props)
  }

  if (noInstallationMeterPlank) {
    itemGroupHighlighted.planks.push(noInstallationMeterPlank)
  }

  if (installationStatus) {
    itemGroupHighlighted.planks.push(installationStatus)
  }

  if (steddi) {
    itemGroupHighlighted.planks.push(steddi)
  }

  if (consumption?.use) {
    itemGroupInstallation.planks.push(consumption.use)
  }

  if (consumption?.cost) {
    itemGroupInstallation.planks.push(consumption.cost)
  }

  if (agreement) {
    itemGroupInstallation.planks.push(agreement)
  }

  if (spotpris) {
    itemGroupInstallation.planks.push(spotpris)
  }

  if (services) {
    itemGroupPerson.planks.push(services)
  }

  if (invoice) {
    itemGroupPerson.planks.push(invoice)
  }

  if (benefits) {
    itemGroupPerson.planks.push(benefits)
  }

  if (move) {
    itemGroupPerson.planks.push(move)
  }

  if (trumf) {
    itemGroupUniques.planks.push(trumf)
  }

  if (inbox) {
    itemGroupPersonSecondary.planks.push(inbox)
  }

  if (settings) {
    itemGroupPersonSecondary.planks.push(settings)
  }

  if (onboarding) {
    itemGroupPersonSecondary.planks.push(onboarding)
  }

  if (itemGroupHighlighted.planks.length > 0) {
    topWalls.push(itemGroupHighlighted)
  }

  if (itemGroupInstallation.planks.length > 0) {
    topWalls.push(itemGroupInstallation)
  }

  if (itemGroupPerson.planks.length > 0) {
    middleWalls.push(itemGroupPerson)
  }

  if (itemGroupUniques.planks.length > 0) {
    middleWalls.push(itemGroupUniques)
  }

  if (itemGroupPersonSecondary.planks.length > 0) {
    bottomWalls.push(itemGroupPersonSecondary)
  }

  return { topWalls, middleWalls, bottomWalls }
}

const _getOnboardingArticleItem = (config: IHomePageData): undefined | IArticleItem => {
  const { user, translations, activeBrand, activeTheme } = config
  const { installation, userData } = user

  if (!userData?.hasOnboarded && IsActiveCustomer(installation)) {
    return {
      theme: activeTheme,
      brand: activeBrand,
      title: translations?.hasNotOnboardedArticleBlock?.imageLabelText,
      description: translations?.hasNotOnboardedArticleBlock?.content,
      image: {
        src: translations?.hasNotOnboardedArticleBlock?.imageSrc,
        alt: translations?.hasNotOnboardedArticleBlock?.imageAlt
      },
      action: {
        text: translations?.hasNotOnboardedArticleBlock?.action?.text,
        link: `${Constants.paths.onboardingPage}?side=0`
      }
    }
  }
}
