import { ContentGrid, Icon, IconType, IPalette } from '@fjordkraft/fjordkraft.component.library'
import React from 'react'
import { useApplicationContext } from '../../contexts'
import { v4 as uuid4 } from 'uuid'
import { ArrowButton } from './ArrowButtons'

export const CarouselControls = ({
  className,
  currentIndex,
  listLength,
  onPrevious,
  onNext,
  palette
}: {
  className?: string
  currentIndex: number
  listLength: number
  onPrevious: () => void
  onNext: () => void
  palette: IPalette
}) => {
  const { activeBrand, desktopView } = useApplicationContext()
  const classPrefix = 'carousel-controls'
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0
  const buttonProps = { classPrefix, palette, activeBrand }

  return (
    <ContentGrid
      tagType={'nav'}
      direction={'row'}
      alignment={'center'}
      gap={3}
      className={className ?? classPrefix}
    >
      {(desktopView || !isTouchDevice) && (
        <ArrowButton
          {...buttonProps}
          isVisible={currentIndex > 0}
          direction={'left'}
          onClick={onPrevious}
        />
      )}
      <ContentGrid
        tagType={'nav'}
        direction={'row'}
        alignment={'center'}
        gap={1}
      >
        {Array.from(Array(listLength)).map((e, index) => (
          <Icon
            key={uuid4()}
            brand={activeBrand}
            palette={palette}
            type={currentIndex === index ? IconType.RoundedDash : IconType.BulletPoint}
            height={0.5}
            {...(currentIndex === index && { width: 1.5 })}
          />
        ))}
      </ContentGrid>
      {(desktopView || !isTouchDevice) && (
        <ArrowButton
          {...buttonProps}
          isVisible={currentIndex < listLength - 1}
          direction={'right'}
          onClick={onNext}
        />
      )}
    </ContentGrid>
  )
}
