import React from 'react'
import {
  BrandColors,
  FlexGridAligner,
  IComponent,
  IconType,
  Icon,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import './Legend.scss'

export interface ILegend extends IComponent {
  color: BrandColors
  icon?: IconType
  text: string
  suffix?: string
  size?: number
  legendSpotPlacement?: 'left' | 'right'
  desktopView?: boolean
}

export const Legend = (props: ILegend) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    color,
    text,
    suffix,
    theme,
    brand,
    size = 1,
    legendSpotPlacement = 'right',
    icon = IconType.CircleIcon,
    desktopView = true
  } = props
  const classPrefix = 'ms-legend'

  // ************************************
  // Render Functionality
  // ************************************

  const renderLeft = () => {
    return (
      <>
        <Icon
          brand={brand}
          theme={theme}
          type={icon}
          color={color}
          width={size}
          height={size}
        />
        <Text
          type='label'
          brand={brand}
          family='main'
          weight={400}
          size={desktopView ? 'regular' : 'small'}
          theme={getCounterTheme(theme)}
          faded
        >
          {text}
        </Text>
        {suffix && (
          <Text
            type='label'
            brand={brand}
            family='main'
            weight={700}
            size={'tiny'}
            theme={getCounterTheme(theme)}
            faded
          >
            {suffix}
          </Text>
        )}
      </>
    )
  }

  const renderRight = () => {
    return (
      <>
        {suffix && (
          <Text
            type='label'
            brand={brand}
            family='main'
            weight={700}
            size={'tiny'}
            theme={getCounterTheme(theme)}
            faded
          >
            {suffix}
          </Text>
        )}
        <Text
          type='label'
          brand={brand}
          family='main'
          weight={400}
          size={desktopView ? 'regular' : 'small'}
          theme={getCounterTheme(theme)}
          faded
        >
          {text}
        </Text>
        <Icon
          brand={brand}
          theme={theme}
          type={icon}
          color={color}
          width={size}
          height={size}
        />
      </>
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <FlexGridAligner
      id={id}
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
      direction='row'
      alignment={legendSpotPlacement === 'right' ? 'center-right' : 'center-left'}
      brand={brand}
      theme={theme}
      gap={1}
    >
      {legendSpotPlacement === 'left' && renderLeft()}
      {legendSpotPlacement === 'right' && renderRight()}
    </FlexGridAligner>
  )
}
