import {
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  width: '100%',
  height: '100%',
  border: {
    thickness: 2,
    type: 'solid',
    sides: 'top'
  },
  grid: {
    tagType: 'nav',
    boxSizing: 'border-box',
    direction: 'row',
    alignment: 'center-left',
    wrap: false
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  Light: {
    background: { color: BrandColors['background-shade-light-1'] },
    border: { color: BrandColors['background-shade-light-2'] }
  },
  Dark: {
    background: { color: BrandColors['background-shade-dark-1'] },
    border: { color: BrandColors['background-shade-dark-2'] }
  }
} as IThemedColors

export const MobileNavigationTemplate = (theme: Theme) => {
  return {
    palette: _palette[theme],
    transform: _transform
  } as IComponentTemplate
}
