import React, { useState } from 'react'
import {
  IconType,
  CallState,
  IComponentTemplate,
  IIcon,
  ITextCustomization
} from '@fjordkraft/fjordkraft.component.library'
import classnames from 'classnames'
import { TextAndIconButtonTemplate } from '../../../Prefabs'
import { useApplicationContext, useApplicationServicehandlerContext } from '../../../contexts'
import { ActionButton } from '../ActionButton'
import './AsyncRedirectButton.scss'

interface IAsyncRedirectButton {
  id?: string
  className?: string
  text: string
  icon?: IconType
  addition: string
  template?: IComponentTemplate
  flipped?: boolean
  customization?: {
    icon?: Omit<IIcon, 'type'>
    text?: ITextCustomization
  }
}

export const AsyncRedirectButton = (props: IAsyncRedirectButton) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'ms-mobile-redirect-button'
  const { activeTheme, activeBrand } = useApplicationContext()
  const { GET } = useApplicationServicehandlerContext()
  const {
    id,
    className,
    text,
    icon,
    addition,
    flipped = false,
    template = TextAndIconButtonTemplate(activeTheme),
    customization
  } = props

  // ************************************
  // Lifecycle
  // ************************************

  const [callState, setCallState] = useState<CallState>('idle')

  const _getIcon = () => {
    switch (callState) {
      case 'aborted':
      case 'error':
        return IconType.ErrorIcon
      case 'idle':
      case 'pending':
        return icon
      case 'success':
        return IconType.CheckmarkThick
    }
  }

  // ************************************
  // Handler
  // ************************************

  const _handleRedirect = async () => {
    setCallState('pending')
    let resp = await GET(addition)
    setCallState(resp.callState)

    if (resp.callState === 'success' && resp.data) {
      window.location.href = resp.data
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ActionButton
      id={id}
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
      brand={activeBrand}
      disabled={callState === 'pending'}
      template={template}
      iconPlacement={flipped ? 'Left' : 'Right'}
      customization={customization}
      action={{
        text,
        icon: _getIcon(),
        onClick: () => {
          _handleRedirect()
        }
      }}
    />
  )
}
