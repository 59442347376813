import {
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = () => {
  return {
    height: 'auto',
    width: '90vw',
    border: {
      radiusType: 'curved'
    },
    padding: '1.5rem',
    margin: undefined,
    grid: {
      direction: 'column',
      alignment: 'top-center',
      gap: 2,
      tagType: 'article'
    }
  } as IComponentTransform
}

// ************************************
// Palette
// ************************************

const _palette = () => {
  return {
    Light: {
      background: { color: BrandColors['secondary-shade-dark-2'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['none'] }
    },
    Dark: {
      background: { color: BrandColors['background-shade-dark-3'] },
      text: { color: BrandColors['text-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['none'] }
    }
  } as IThemedColors
}

export const LocationNavigationInfoBoxTemplate = (theme: Theme = 'Light') => {
  return {
    palette: _palette()[theme],
    transform: _transform()
  } as IComponentTemplate
}
