import React, { useEffect, useMemo, useState } from 'react'
import {
  BrandColors,
  ContentGrid,
  LoaderGraphic,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import { IBlock, IDateState, IDefaultProps } from '../../models'
import { DateResolution } from '../../models/types/Consumption'
import { getDaysInMonth, format } from 'date-fns'
import nb from 'date-fns/esm/locale/nb/index.js'
import { toInteger } from 'lodash'
import { capitalizeFirstLetter, getText, tNumber, fetchConsumptionCsv } from '../../services'
import { ColumnChartCard, DateFilter, IFilterDate } from '../../components'
import { InstallationYearsBar } from '../InstallationYearsBar/InstallationYearsBar'
import classNames from 'classnames'
import './ConsumptionBlock.scss'

let filterDate: Date = new Date()

export interface IConsumptionBlock extends Omit<IDefaultProps & IBlock, 'activeTheme' | 'activeBrand'> {
  chartData?: any
  showEstimatedView: boolean
  onDateChange: (state: IDateState) => void
  onSelectedYearsChange: (years: number[]) => void
}

export const ConsumptionBlock = (props: IConsumptionBlock) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    className,
    brand,
    theme,
    translations,
    user,
    services,
    desktopView,
    chartData,
    showEstimatedView,
    onDateChange,
    onSelectedYearsChange
  } = props
  const { installation } = user
  const { token, GETTYPED } = services
  const classPrefix = 'consumption-block'

  // ************************************
  // Lifecycle
  // ************************************

  const _getDefaultDateState = () => {
    let currentDate = new Date()
    let allDays = getDaysInMonth(currentDate)
    let year = currentDate.getFullYear()
    let month = currentDate.getMonth()

    if (showEstimatedView) {
      return {
        from: new Date(year, 0),
        to: new Date(year, 11),
        resolution: 'month'
      } as IDateState
    } else {
      return {
        from: new Date(year, month, 1),
        to: new Date(year, month, allDays),
        resolution: 'day'
      } as IDateState
    }
  }

  const [loadingChart, setLoadingChart] = useState<boolean>(true)
  const [dateState, setDateState] = useState<IDateState>(_getDefaultDateState())
  const [selectedYears, setSelectedYears] = useState<number[]>()

  useEffect(() => {
    if (chartData) {
      setLoadingChart(false)
    }
  }, [chartData])

  useEffect(() => {
    if (selectedYears && selectedYears.length > 0) {
      onSelectedYearsChange(selectedYears)
    }
  }, [selectedYears])

  useEffect(() => {
    if (dateState) {
      onDateChange(dateState)
    }
  }, [dateState])

  // ************************************
  // Helper Functionality
  // ************************************

  const _compileDates = (res: DateResolution, y: number, m: number, d: number) => {
    let from = new Date(y, m, d)
    let to = new Date(y, m, d)

    if (res === 'hour') {
      from = new Date(y, m, d)
      to = new Date(y, m, d)
      filterDate = new Date(filterDate.getFullYear(), filterDate.getMonth(), d)
    } else if (res === 'day') {
      d = getDaysInMonth(new Date(filterDate.getFullYear(), m))
      from = new Date(filterDate.getFullYear(), m, 1)
      to = new Date(filterDate.getFullYear(), m, d)
      filterDate = new Date(filterDate.getFullYear(), m, 1)
    } else if (res === 'month') {
      from = new Date(y, 0, 1)
      to = new Date(y, 11, 31)
      filterDate = new Date(y, filterDate.getMonth(), filterDate.getDay())
    }

    return { to, from }
  }

  const _handleColumnClick = (value: any, date: Date, state: IDateState) => {
    let compiled: any

    switch (state.resolution) {
      case 'day':
        setLoadingChart(true)

        compiled = _compileDates('hour', filterDate.getFullYear(), filterDate.getMonth(), toInteger(value))

        // Offset for index based value
        compiled.from.setDate(compiled.from.getDate() + 1)
        compiled.to.setDate(compiled.to.getDate() + 1)

        setDateState({
          resolution: 'hour',
          from: compiled.from,
          to: compiled.to
        })
        break
      case 'month':
        if (!showEstimatedView) {
          setLoadingChart(true)

          let daysInMonth = getDaysInMonth(new Date(date.getFullYear(), value))

          setDateState({
            resolution: 'day',
            from: new Date(date.getFullYear(), value, 1),
            to: new Date(date.getFullYear(), value, daysInMonth)
          })

          filterDate.setMonth(value)
        }
        break
    }
  }

  const _handleDateFilter = (state: IFilterDate) => {
    let from = new Date()
    let to = new Date()
    filterDate = state.date

    let compiledDates: any = _compileDates(
      state.resolution,
      state.date.getFullYear(),
      state.date.getMonth(),
      state.date.getDate()
    )

    from = compiledDates.from
    to = compiledDates.to

    setSelectedYears(undefined)
    setDateState({ resolution: state.resolution, from, to })
  }

  const _formatX = (value: any, opt: any) => {
    let dateObj = chartData.series[opt.seriesIndex].data[opt.dataPointIndex].date
    let text = ''

    if (dateState.resolution === 'day') {
      let tooltipDate = new Date(dateState.to.getFullYear(), dateState.to.getMonth(), parseInt(value))

      let prefix = `${capitalizeFirstLetter(
        format(tooltipDate, 'EEEE', {
          locale: nb
        })
      )} `

      text = `${prefix}${value}`
    } else if (dateState.resolution === 'hour') {
      let toValue: number | string = parseInt(value) + 1
      toValue = toValue < 9 ? `0${toValue}` : toValue
      toValue = toValue === 24 ? `00` : toValue
      text = `${value}-${toValue}:00`
    } else if (dateState.resolution === 'month') {
      text = `${capitalizeFirstLetter(value)} ${dateObj.getFullYear()}`
    }

    return `${text}`
  }

  const _formatY = (value: any, opt: any, dateState: IDateState) => {
    let consumptionUse = `${tNumber(value, 'no-NO', 2)} ${getText('powerMeasurement', translations)}`

    let text: string = `${consumptionUse}`

    if (dateState.resolution === 'hour') {
      let cost = chartData.series[opt.seriesIndex].data[opt.dataPointIndex].cost
      if (cost) {
        // The cost is negative from the API, so we invert it.
        cost *= -1
        let consumptionCost = `${getText('powerCost', translations)} ${tNumber(cost, 'no-NO', 2)} ${getText(
          'currency',
          translations
        )}`

        text += `\n${consumptionCost}`
      }
    }

    return text
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderChartFallback = () => {
    return (
      <ContentGrid
        className={`${classPrefix}__fallback`}
        brand={brand}
        direction='column'
        alignment='center'
        tagType='section'
      >
        <Text
          brand={brand}
          theme={getCounterTheme(theme)}
          weight={400}
          type={'p'}
          family={'main'}
          size={'regular'}
        >
          {getText('noData', translations)}
        </Text>
      </ContentGrid>
    )
  }

  const _renderDateFilter = useMemo(() => {
    if (installation && dateState && translations) {
      return (
        <DateFilter
          className={`${classPrefix}__filter`}
          initialDate={dateState.to}
          installation={installation}
          resolution={dateState.resolution}
          translation={translations}
          disabled={loadingChart}
          desktopView={desktopView}
          theme={theme}
          brand={brand}
          showEstimatedView={showEstimatedView}
          onDateChange={filterDate => {
            setLoadingChart(true)
            _handleDateFilter(filterDate)
          }}
        />
      )
    }
  }, [installation, translations, brand, theme, dateState, desktopView, loadingChart])

  const _renderChart = useMemo(() => {
    if (chartData && dateState && installation && !loadingChart) {
      let showFallback: boolean = false

      if (chartData.series[0].totalYAmount) {
        showFallback = chartData.series[0].totalYAmount === 0
      }

      return (
        <ColumnChartCard
          theme={theme}
          brand={brand}
          className={`${classPrefix}__chart`}
          series={chartData.series}
          categories={[]}
          showEstimatedView={showEstimatedView}
          fallback={{
            show: showFallback,
            content: _renderChartFallback()
          }}
          onColumnClick={(x, y, dataPoint) => {
            if (!dataPoint.estimated && desktopView) {
              _handleColumnClick(x, dataPoint.date, dateState)
            }
          }}
          cardTopLeftText={chartData.leftDescription}
          cardTopRightText={chartData.rightDescription}
          legends={chartData.legends}
          csvData={{
            fetchCsvFunc: async () => {
              return fetchConsumptionCsv(
                installation?.meterId,
                dateState,
                getText('downloadExcelFilename', translations),
                GETTYPED
              )
            },
            buttonTitle: getText('downloadExcelButtonTitle', translations)
          }}
          chartTooltip={{
            show: true,
            x: {
              formatter: (value: any, opt: any) => {
                return _formatX(value, opt)
              }
            },
            y: {
              formatter: (value: any, opt: any) => {
                return _formatY(value, opt, dateState)
              },
              suffix: getText('powerMeasurement', translations),
              showOnAxisLabels: true
            }
          }}
        />
      )
    }
  }, [chartData, desktopView, dateState, brand, theme, installation, token, loadingChart])

  const _renderYearComparisonBar = useMemo(() => {
    if (
      installation?.consumptionYears &&
      installation.consumptionYears.length > 1 &&
      dateState?.resolution === 'month'
    ) {
      return (
        <InstallationYearsBar
          className={`${classPrefix}__year-comparsion`}
          theme={theme}
          brand={brand}
          disabled={loadingChart}
          translations={translations}
          primaryActiveYear={dateState.from.getFullYear()}
          onChange={(selectedYears: number[]) => {
            setLoadingChart(true)
            setSelectedYears(selectedYears)
          }}
        />
      )
    }
  }, [brand, theme, installation, dateState, translations, loadingChart])

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      direction='column'
      alignment='top-left'
      gap={2}
      gapType={desktopView ? 'rem' : 'px'}
      boxSizing='border-box'
    >
      {_renderDateFilter}
      <ContentGrid
        className={classNames(`${classPrefix}__chart-wrapper`, {
          [`${classPrefix}__chart-wrapper--background`]: !chartData,
          [`${classPrefix}__chart-wrapper--use`]: true
        })}
        direction='column'
        alignment={'center'}
        tagType={'section'}
      >
        {loadingChart && (
          <LoaderGraphic
            className={`${classPrefix}__chart-wrapper__loader`}
            size={4}
            scaleThickness={0.5}
            colors={{
              backgroudColor: BrandColors['background-shade-light-1'],
              fillColor: BrandColors['primary-shade-light-2']
            }}
            theme={theme}
            brand={brand}
          />
        )}
        {_renderChart}
        {!chartData && !loadingChart && translations && (
          <Text
            weight={400}
            type={'p'}
            size={'regular'}
            family={'main'}
            theme={getCounterTheme(theme)}
          >
            {getText('noData', translations)}
          </Text>
        )}
      </ContentGrid>
      {_renderYearComparisonBar}
    </StyleGrid>
  )
}
