import {
  BrandColor,
  BrandColors,
  IComponentTemplate,
  IComponentTransform,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  height: 'auto',
  width: '100%',
  border: {
    radiusType: 'curved'
  },
  padding: '2rem',
  margin: undefined,
  grid: {
    direction: 'column',
    alignment: 'top-left',
    gap: 1,
    tagType: 'article',
    boxSizing: 'border-box'
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = (background: BrandColor) => {
  return {
    Light: {
      background,
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['none'] }
    },
    Dark: {
      background,
      text: { color: BrandColors['action-shade-light-1'] },
      border: { color: BrandColors['none'] },
      icon: { color: BrandColors['none'] }
    }
  } as IThemedColors
}

// ************************************
// Export
// ************************************

export const GenericColorCardTemplate = (
  theme: Theme = 'Light',
  background: BrandColor = { color: BrandColors['background-shade-light-1'] }
) => {
  return {
    palette: _palette(background)[theme],
    transform: _transform
  } as IComponentTemplate
}
