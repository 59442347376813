import { useContext, createContext } from 'react'
import { IConsent } from '../../models'

interface IConsentContext {
  consents?: IConsent[]
  PUT_CONSENTS: (consents: IConsent[], translations: any) => void
  updateConsents: () => Promise<IConsent[] | null>
  useNewConsents?: boolean
  showConsentModalForCustomer?: boolean
  isReservedAgainstMarketing?: boolean
  hasAnsweredNewConsent?: boolean
  pageHasPopup: (pathname: string) => boolean
}

export const ConsentContext = createContext<IConsentContext>({
  consents: undefined,
  PUT_CONSENTS: () => {},
  updateConsents: () => new Promise(() => {}),
  useNewConsents: false,
  showConsentModalForCustomer: false,
  isReservedAgainstMarketing: false,
  hasAnsweredNewConsent: false,
  pageHasPopup: () => false
})

export const useConsentContext = () => useContext(ConsentContext)
