import {
  BrandColors,
  IClampedDropdown,
  IComponentTransform,
  ITabSelectDropdownTemplate,
  TabSelectDropdownTemplate,
  Theme
} from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../../data'

// ************************************
// Transform
// ************************************

const _transform = {
  width: '100%',
  height: '3.5rem',
  border: undefined,
  outline: undefined,
  padding: '1rem 0.5rem 1rem 0.5rem',
  grid: {
    boxSizing: 'border-box',
    direction: 'row',
    alignment: 'center',
    gap: 1
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['border-shade-light-1'] },
      icon: { color: BrandColors['primary-shade-light-2'] },
      outline: { color: BrandColors['transparent'] },
      hover: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.8
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-1'] },
        icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 },
        outline: { color: BrandColors['transparent'] }
      },
      focus: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.5
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-1'] },
        icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.8 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.2
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.2 },
        border: { color: BrandColors['border-shade-light-1'], opacity: 0.2 },
        icon: { color: BrandColors['primary-shade-light-2'], opacity: 0.2 },
        outline: { color: BrandColors['transparent'] }
      }
    },
    Dark: {}
  },
  'brand-trondelagkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-1'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['border-shade-light-1'] },
      icon: { color: BrandColors['secondary-shade-light-1'] },
      outline: { color: BrandColors['transparent'] },
      hover: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.8
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-1'] },
        icon: { color: BrandColors['secondary-shade-light-1'], opacity: 0.8 },
        outline: { color: BrandColors['transparent'] }
      },
      focus: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.5
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-1'] },
        icon: { color: BrandColors['secondary-shade-light-1'], opacity: 0.8 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-1'],
          opacity: 0.2
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.2 },
        border: { color: BrandColors['border-shade-light-1'], opacity: 0.2 },
        icon: { color: BrandColors['secondary-shade-light-1'], opacity: 0.2 },
        outline: { color: BrandColors['transparent'] }
      }
    },
    Dark: {}
  }
}

// ************************************
// Export
// ************************************

export const MS_TabSelectDropdownTemplate = (
  theme: Theme,
  tabScaling: IClampedDropdown,
  bordered: boolean,
  placement?: 'middle' | 'first' | 'last' | undefined,
  brand: string = Constants.uiBrand
) => {
  let base: ITabSelectDropdownTemplate = TabSelectDropdownTemplate(theme, tabScaling, bordered, placement)

  if (brand === 'brand-trondelagkraft') {
    if (base.button.palette?.active) {
      base.button.palette.active.extra = [
        { key: 'textBackground', value: { color: BrandColors['primary-shade-light-1'] } }
      ]
    }
  }

  return base
}
