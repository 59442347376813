import { BrandColors, ITabSelectFilterItem } from '@fjordkraft/fjordkraft.component.library'
import { IColumnChartBlock } from '../../../../blocks'
import { IChartParsedChartData } from '../ServiceChartPageData'
import { IResponse, getText, tNumber } from '../../../../services'
import { format } from 'date-fns'
import { nb } from 'date-fns/locale'
import { v4 as uuid4 } from 'uuid'
import { ITrumfMonth } from '../../ServicePage/Datahandling/UniqueServices'
import _ from 'lodash'
import { IServicePage } from '../../../../models'

export const getServiceChartDataTrumf = async (config: IChartParsedChartData) => {
  const { page, GET, date } = config

  let resp: IResponse = await GET('Trumf/transactions')
  let fromJanuary: string = _formatDateMonth(new Date(new Date().getFullYear(), 0, 1))
  let toDescember: string = _formatDateMonth(new Date(new Date().getFullYear(), 11, 1))
  let yearFilter = _getYearItems(resp.data.monthly, date.getFullYear())

  if (resp.data && yearFilter) {
    let chartData: IColumnChartBlock = {
      brand: 'brand-trumf',
      translations: page,
      chartColors: [BrandColors['primary-shade-light-2']],
      chartData: {
        cardHeader: {
          title: getText('chartTitle', page)
        },
        cardTopLeftText: {
          title: tNumber(yearFilter.total),
          titleSuffix: getText('valueSuffix', page),
          subTitle: `${fromJanuary} → ${toDescember}`
        },
        series: _getParsedSeries(resp.data.monthly, date.getFullYear(), page),
        chartColors: [BrandColors['primary-shade-light-2']]
      }
    }

    return { chartData, yearFilter }
  }
}

// ************************************
// Helpers
// ************************************

const _getParsedSeries = (months: ITrumfMonth[], year: number, page: IServicePage) => {
  let series: any[] = [
    {
      name: getText('series1Name', page),
      data: _preFillData(year)
    }
  ]

  if (months?.length > 0) {
    months.forEach((d: ITrumfMonth) => {
      if (d.year === year) {
        let monthName: string = format(new Date(d.year, d.month - 1), 'MMMM', {
          locale: nb
        })

        let foundSpot = _.findIndex(series[0].data, (datum: any) => {
          return datum.x === monthName
        })
        if (series[0].data[foundSpot]) {
          series[0].data[foundSpot].y = d.accumulatedPointsForYear
        }
      }
    })
  }

  return series
}

const _preFillData = (year: number) => {
  let data = []

  for (let i = 0; i <= 11; i++) {
    let date: Date = new Date(year, i, 1)

    data.push({
      x: format(date, 'MMMM', {
        locale: nb
      }),
      y: 0
    })
  }

  return data
}

const _getYearItems = (months: ITrumfMonth[], activeYear: number) => {
  let items: ITabSelectFilterItem[] = []
  let total: number = 0

  if (months?.length > 0) {
    months = _.orderBy(months, ['year'], ['desc'])

    months.forEach((d: ITrumfMonth) => {
      let foundYear = _.find(items, (item: ITabSelectFilterItem) => {
        return item.text === `${d.year}`
      })

      if (activeYear === d.year) {
        total += d.sumPoints
      }

      if (!foundYear) {
        items.push({
          id: uuid4(),
          value: new Date(d.year, d.month),
          text: `${d.year}`,
          active: activeYear === d.year
        })
      }
    })
  }

  return { items, total }
}

const _formatDateMonth = (date: Date) => {
  return format(date, 'MMMM', { locale: nb })
}
