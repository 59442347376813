import React from 'react'
import { Button, IButton, IBaseComponentColorState, IComponentTemplate } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { isExternalUrl } from '../../services'
import { RouterButton } from './RouterButton'
import './Button.scss'

export interface IMsButton extends IButton {
  stateChange?: (state: IBaseComponentColorState) => void
  forceExternalLink?: boolean
}

export const MsButton = (props: IMsButton) => {
  // ************************************
  // Properties
  // ************************************

  const { className, action, children, theme = 'Light', template, stateChange, forceExternalLink } = props
  const classPrefix = 'ms-button'

  const _requiresRouterButton = (): boolean => {
    if (action?.link && !isExternalUrl(action.link) && !forceExternalLink) {
      return true
    } else {
      return false
    }
  }

  const _parsedProps = (): IButton => {
    return {
      ...props,
      ...({
        className: classNames(classPrefix, {
          [`${className}`]: className
        }),
        template: _handleTemplate(),
        action: action,
        colorStateChange: (state: IBaseComponentColorState) => {
          if (stateChange) {
            stateChange(state)
          }
        }
      } as IButton)
    } as IButton
  }

  // ************************************
  // Helper Functionality
  // ************************************

  const _handleTemplate = (): IComponentTemplate | undefined => {
    let base: IComponentTemplate | undefined = template

    if (base?.transform?.grid) {
      base.transform.grid.tagType = action.link ? 'a' : 'button'
    }

    return base
  }

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {_requiresRouterButton() && <RouterButton {..._parsedProps()} />}
      {!_requiresRouterButton() && <Button {..._parsedProps()}>{children}</Button>}
    </>
  )
}
