import {
  IAddonData,
  ICustomerAccountInformation,
  ICustomerInstallation,
  IServiceEpiPage,
  IServicePage,
  IServiceStatus,
  OnClickServiceOrderAction,
  ServiceStatus
} from '../../models'
import { IResponse } from '../BaseService'
import { getRequest, postRequest } from '../../contexts'
import { IServiceOrderOrCancel, ServiceOrderAndCancelSteppes } from '../../modals'
import { BrandColors, CallState } from '@fjordkraft/fjordkraft.component.library'
import { isWhiteListed, logger } from './HelperService'
import { handleCreateServiceDataLayerEvent } from '../analytics/ServicesDataLayer'
import { IServiceBasePageData } from '../../pages/ServicesPagesWrapper/ServicePage/ServiceBasePageData'
import { getSteddiStatus } from '../../pages/ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices'

export const fetchTrumfOrderHandling = async (
  orderAction: OnClickServiceOrderAction,
  GET: getRequest,
  POST: postRequest
): Promise<ServiceOrderAndCancelSteppes> => {
  if (orderAction === 'ORDER') {
    let resp: IResponse = await GET('Services/order/trumf')

    if (resp.callState === 'success' && resp.data) {
      window.location.href = resp.data
    }

    return 'LOADING'
  } else {
    let resp: IResponse = await POST('Services/terminate/trumf')
    return resp.callState === 'success' ? 'SUCCESS' : 'FAILURE'
  }
}

interface IOrderService {
  meterId: string
  productDefinitionId: string
  POST: postRequest
  contentId?: number
  sendSms?: boolean
  extraProducts?: number[]
}

export const orderService = async (config: IOrderService) => {
  const { meterId, productDefinitionId, POST, contentId, sendSms = false, extraProducts } = config

  let query = `Services/order/${productDefinitionId}`

  if (sendSms) {
    query += `&sendSms=${sendSms}`
  }

  let body = {
    meterId,
    sendSms,
    campaignContentId: contentId,
    extraProducts
  }

  let resp: IResponse = await POST(query, body)

  return { data: resp.data as ServiceStatus, callState: resp.callState }
}

export const cancelService = async (
  meterId: string,
  productDefinitionId: string,
  extraProducts: number[] | undefined,
  POST: postRequest
) => {
  const body = {
    meterId,
    extraProducts
  }

  let resp: IResponse = await POST(`Services/terminate/${productDefinitionId}`, body)

  return { data: resp.data as ServiceStatus, callState: resp.callState }
}

export const redirectToSteddiOrderAndCancelUrl = async (
  account: ICustomerAccountInformation,
  orderAction: OnClickServiceOrderAction,
  GET: getRequest
): Promise<'ERROR' | 'LOADING'> => {
  let type = orderAction == 'ORDER' ? 'orderlink' : 'cancelLink'

  let resp = await GET(`Steddi/${type}/${account.accountId}`)

  if (resp.callState === 'success' && resp.data) {
    window.location.href = resp.data
    return 'LOADING'
  } else {
    return 'ERROR'
  }
}

export const redirectToSteddiPaymentFreeUrl = async (accountNumber: string, GET: getRequest): Promise<CallState> => {
  let resp = await GET(`Steddi/paymentfreelink/${accountNumber}`)

  if (resp.callState === 'success' && resp.data) {
    window.location.href = resp.data
  }

  return resp.callState
}

const getAddonStates = async (serviceIds: string[], GET: getRequest, refetch: boolean = false) => {
  let resp = await GET(`Services/addonStates?addonIds=${serviceIds.toString()}`, refetch)

  return resp
}

export const getParsedAddonStates = async (
  epiChildren: any,
  GET: getRequest,
  refetch: boolean = false
): Promise<IAddonData[] | undefined> => {
  let resp: IResponse = { data: undefined, callState: 'idle' }
  let addonData: IAddonData[] | undefined = undefined

  if (epiChildren) {
    let serviceEpiPages: IServiceEpiPage[] = epiChildren.HeadlessServicesPage.children

    if (serviceEpiPages?.length > 0) {
      let serviceIds: string[] = []
      addonData = []

      serviceEpiPages.forEach((servicePage: IServiceEpiPage) => {
        if (servicePage.data.productDefinitionId && servicePage.data.servicePageId !== 'forutsigbar') {
          serviceIds.push(servicePage.data.productDefinitionId)

          if (addonData) {
            addonData.push({
              id: servicePage.data.productDefinitionId,
              page: servicePage,
              hasActiveInstallation: false,
              allInstallationsActive: false,
              state: {}
            })
          }
        } else if (servicePage.data.servicePageId === 'forutsigbar') {
          addonData?.push({
            id: servicePage.data.servicePageId,
            page: servicePage,
            hasActiveInstallation: false,
            allInstallationsActive: false,
            state: {}
          })
        }
      })

      resp = await getAddonStates(serviceIds, GET, refetch)
      addonData = _mapAddonData(resp.data, addonData)
    }
  }
  return addonData
}

const _mapAddonData = (states?: any, addonData?: IAddonData[]): IAddonData[] | undefined => {
  let data: IAddonData[] | undefined = addonData

  if (states && addonData) {
    addonData.forEach((addon: IAddonData) => {
      if (addon.page.data.servicePageId !== 'forutsigbar') {
        addon.state = states[addon.id]
        addon.hasActiveInstallation = _getAddonHasActive(addon)
        addon.allInstallationsActive = _getAddonAllActive(addon)
      }
    })
  }

  return data
}

const _getAddonAllActive = (addon: IAddonData): boolean => {
  return Object.keys(addon.state).find((meterId: string) => {
    return narrowDownResultStatusForPage(addon.state[meterId]?.state) === 'INACTIVE'
  })
    ? false
    : true
}

const _getAddonHasActive = (addon: IAddonData): boolean => {
  return Object.keys(addon.state).find((meterId: string) => {
    return addon.state[meterId]?.state === 'ACTIVE'
  })
    ? true
    : false
}

// ************************************
// Handle Service Order and Cancel
// ************************************

export const handleServiceOrderAndCancel = async (
  config: IServiceOrderOrCancel,
  GET: getRequest,
  POST: postRequest
): Promise<ServiceOrderAndCancelSteppes> => {
  const { installation, page, addonStateResponse } = config

  if (installation) {
    // If the service is currently ACTIVE, we cancel it:
    if (config.status === 'ACTIVE') {
      let resp = await cancelService(
        installation.meterId,
        page.productDefinitionId,
        parseActiveExtraProducts(installation, page, addonStateResponse),
        POST
      )

      if (resp.callState === 'success') {
        return 'SUCCESS'
      } else {
        return 'FAILURE'
      }
    }
    // Else, if the service is currently TERMINATED or INACTIVE, we order it:
    else if (
      config.status === 'TERMINATED' ||
      config.status === 'INACTIVE' ||
      config.status === 'ORDER_CANCELLED_BY_CUSTOMER'
    ) {
      let resp = await orderService({
        meterId: installation.meterId,
        productDefinitionId: page.productDefinitionId,
        POST,
        contentId: page.epiContentId,
        extraProducts: _parseSelectedExtraProduct(config)
      })

      if (resp.callState === 'success') {
        if (resp.data === 'ORDER_IN_PROGRESS') {
          // Waiting 5 seconds to check if the order became active since ordering (99% of the time this is the case).
          await new Promise(r => setTimeout(r, 5000))
          let confirmResponse = await getAddonStates([page.productDefinitionId], GET, true)
          if (confirmResponse.callState === 'success') {
            let state = _tryGetStatusFromAddonResponse(
              confirmResponse.data,
              page.productDefinitionId,
              installation.meterId
            )?.state

            if (state) {
              let orderResult = mapServiceStatusToOrderResult(state)

              if (orderResult === 'SUCCESS' || orderResult === 'IN_PROGRESS') {
                handleCreateServiceDataLayerEvent(config)
              }
            }
          }
          return 'IN_PROGRESS'
        } else {
          return mapServiceStatusToOrderResult(resp.data)
        }
      }
      return 'FAILURE'
    }
  }

  return 'DEFAULT'
}

const _parseSelectedExtraProduct = (config: IServiceOrderOrCancel): number[] | undefined => {
  const { selectedSubService } = config

  if (selectedSubService && !isNaN(parseInt(selectedSubService.value))) {
    return [parseInt(selectedSubService.value)]
  }
}

// ************************************
// Handle Service Order and Cancel :: Helpers
// ************************************

export const getSpecificAddonStateForInstallation = (
  productId: string,
  meterId: string,
  addonStates?: IAddonData[]
): IServiceStatus | undefined => {
  let status: IServiceStatus | undefined = undefined

  if (addonStates && addonStates.length > 0) {
    for (let data of addonStates) {
      if (data.id === productId) {
        return data.state[meterId] as IServiceStatus
      }
    }
  }

  return status
}

const _tryGetStatusFromAddonResponse = (
  responseData: any,
  productDefinitionId: string,
  meterId: string
): IServiceStatus | void => {
  try {
    return responseData[productDefinitionId][meterId] as IServiceStatus
  } catch {
    logger(`Found no status for [${productDefinitionId}][${meterId}]`, 'warn')
  }
}

export interface IGetStatusFromInstallationOrGlobal {
  installationOnly: boolean
  translations: IServicePage
  installation?: ICustomerInstallation
  addonStates?: IAddonData[]
}

export const getStatusFromInstallationOrGlobal = (config: IGetStatusFromInstallationOrGlobal): ServiceStatus => {
  const { addonStates, translations, installationOnly, installation } = config

  if (addonStates && addonStates.length > 0) {
    for (const data of addonStates) {
      if (translations.productDefinitionId === data.id) {
        if (installation && installationOnly && data?.state) {
          return data.state[installation.meterId].state
        } else {
          return data.hasActiveInstallation ? 'ACTIVE' : 'INACTIVE'
        }
      }
    }
  }

  return 'INACTIVE'
}

export const showingDropdownOrder = (config: IServiceBasePageData, status: ServiceStatus): boolean => {
  const { translations } = config
  const hasExtraServ: boolean =
    translations.serviceAdditionalAddonBlocks && translations.serviceAdditionalAddonBlocks.length > 0
  const isInactive = narrowDownResultStatusForPlank(status) === 'INACTIVE'

  return hasExtraServ && isInactive
}

export const getClampedStatusFromInstallation = (config: IGetStatusFromInstallationOrGlobal): 'ACTIVE' | 'INACTIVE' => {
  const { addonStates, translations, installationOnly, installation } = config

  if (addonStates && addonStates.length > 0) {
    for (const data of addonStates) {
      if (translations.productDefinitionId === data.id) {
        if (installation && installationOnly) {
          return narrowDownResultStatusForPage(data.state[installation.meterId].state)
        } else {
          return data.hasActiveInstallation ? 'ACTIVE' : 'INACTIVE'
        }
      }
    }
  }

  return 'INACTIVE'
}

export const parseActiveExtraProducts = (
  installation: ICustomerInstallation,
  page: IServicePage,
  addonStateResponse: any
): number[] | undefined => {
  const extraProductIds = page.serviceAdditionalAddonBlocks?.map(addon => addon.serviceId)

  if (installation && extraProductIds && extraProductIds.length > 0) {
    let activeExtraProductIds = extraProductIds.filter(
      id => getSpecificAddonStateForInstallation(id, installation?.meterId, addonStateResponse)?.state == 'ACTIVE'
    )

    return activeExtraProductIds.map(id => parseInt(id))
  }
}

export const narrowDownResultStatusForPage = (status: ServiceStatus): 'INACTIVE' | 'ACTIVE' => {
  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
    case 'TERMINATING':
      return 'ACTIVE'
    default:
      return 'INACTIVE'
  }
}

export const narrowDownResultStatusForPlank = (status: ServiceStatus): 'INACTIVE' | 'ACTIVE' | 'ORDER_IN_PROGRESS' => {
  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
      return 'ACTIVE'
    case 'INACTIVE':
    case 'ORDER_FAILED':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
      return 'INACTIVE'
    case 'ACTIVATING':
    case 'TERMINATING':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
      return 'ORDER_IN_PROGRESS'
    default:
      return 'INACTIVE'
  }
}

export const mapServiceStatusToOrderResult = (status: ServiceStatus): ServiceOrderAndCancelSteppes => {
  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
    case 'ACTIVATING':
    case 'TERMINATING':
    case 'TERMINATED':
      return 'SUCCESS'
    case 'INACTIVE':
    case 'ORDER_FAILED':
      return 'FAILURE'
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
      return 'IN_PROGRESS'
    default:
      return 'DEFAULT'
  }
}

export const narrowDownPopupState = (status: ServiceStatus): ServiceOrderAndCancelSteppes => {
  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
    case 'TERMINATED':
      return 'SUCCESS'
    case 'INACTIVE':
    case 'ORDER_FAILED':
      return 'FAILURE'
    case 'ORDER_IN_PROGRESS':
    case 'ACTIVATING':
    case 'TERMINATING':
    case 'ORDER_WAITING_FOR_CUSTOMER':
      return 'IN_PROGRESS'
    default:
      return 'DEFAULT'
  }
}

export const getServiceStatusColor = (status: ServiceStatus | undefined) => {
  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
      return BrandColors['status-shade-light-1']
    case 'ACTIVATING':
    case 'TERMINATING':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
      return BrandColors['status-shade-light-2']
    case 'ORDER_FAILED':
    case 'TERMINATED':
    case 'INACTIVE':
      return BrandColors['status-shade-light-3']
    default:
      return BrandColors['text-shade-light-1']
  }
}

export const shouldServiceBeAvailable = (
  page: IServicePage,
  user: any,
  loggedInAsCustomerService: boolean,
  status: ServiceStatus
) => {
  if (page.servicePageShow) {
    // Check for whitelist feature first
    if (page.whitelistFeature) {
      return isWhiteListed(user)
    }
    // Check for customer service feature
    if (page.customerServiceFeature) {
      return loggedInAsCustomerService
    }
    // Check for active customers only
    if (page.showForActiveCustomersOnly) {
      return hasServiceActivated(status)
    }
    // If none of the above, make visible for all
    return true
  } else {
    return false
  }
}

export const hasServiceActivated = (status: ServiceStatus) => {
  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
    case 'ACTIVATING':
    case 'TERMINATING':
      return true
    default:
      return false
  }
}

export const getStatus = async (config: IServiceBasePageData): Promise<ServiceStatus> => {
  const { translations, user, addonStates, GETTYPED } = config

  let status: ServiceStatus | undefined = undefined

  if (translations.servicePageId === 'forutsigbar') {
    status = await getSteddiStatus(user.userData.accounts, undefined, GETTYPED)
  } else {
    status = getStatusFromInstallationOrGlobal({
      installationOnly: translations.orderMethodType === 'installation',
      translations: translations,
      installation: user.installation,
      addonStates
    })
  }

  return status ?? 'INACTIVE'
}
