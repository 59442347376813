import { BrandColors, IComponentTransform } from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _TRANSFORM = {
  width: '5.645rem',
  height: '3.375rem',
  padding: '0.75rem 0.81rem',
  outline: {
    type: 'dashed',
    thickness: 2
  },
  border: {
    type: 'solid',
    thickness: 2,
    radiusType: 'curved'
  },
  grid: {
    boxSizing: 'border-box',
    direction: 'column',
    alignment: 'center',
    gap: 1
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _BUTTON_PALETTE_RATE = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-1'], opacity: 0.3 },
      text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
      icon: { color: BrandColors['status-shade-light-2'], opacity: 0.3 },
      border: { color: BrandColors['border-shade-light-2'], opacity: 0.3 },
      hover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      active: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      activeHover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.8 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.8 }
      },
      focus: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.25 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.25 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.25 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.25 }
      }
    },
    Dark: {
      background: { color: BrandColors['background-shade-light-1'], opacity: 0.3 },
      text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
      icon: { color: BrandColors['status-shade-light-2'], opacity: 0.3 },
      border: { color: BrandColors['border-shade-light-2'], opacity: 0.3 },
      hover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      active: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      activeHover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.8 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.8 }
      },
      focus: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.25 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.25 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.25 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.25 }
      }
    }
  },
  'brand-trondelagkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-1'], opacity: 0.3 },
      text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
      icon: { color: BrandColors['status-shade-light-2'], opacity: 0.3 },
      border: { color: BrandColors['border-shade-light-2'], opacity: 0.3 },
      hover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      active: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      activeHover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.8 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.8 }
      },
      focus: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.25 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.25 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.25 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.25 }
      }
    },
    Dark: {
      background: { color: BrandColors['background-shade-light-1'], opacity: 0.3 },
      text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
      icon: { color: BrandColors['status-shade-light-2'], opacity: 0.3 },
      border: { color: BrandColors['border-shade-light-2'], opacity: 0.3 },
      hover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      active: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 }
      },
      activeHover: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.8 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.8 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.8 }
      },
      focus: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 1 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 1 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 1 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 1 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: { color: BrandColors['background-shade-light-1'], opacity: 0.25 },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.25 },
        icon: { color: BrandColors['status-shade-light-2'], opacity: 0.25 },
        border: { color: BrandColors['border-shade-light-2'], opacity: 0.25 }
      }
    }
  }
}

export const BUTTON_TEMPLATE_RATE = {
  themedPalette: _BUTTON_PALETTE_RATE,
  transform: _TRANSFORM
}
