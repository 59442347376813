import React, { useEffect } from 'react'
import {
  Alignment,
  BaseComponent,
  BrandColors,
  IComponent,
  IComponentTemplate,
  Icon,
  IconType,
  IText,
  StyleGrid,
  StyleLoopLimit,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import { MS_BareDropdownTemplate, paragraphTextPrefab } from '../../Prefabs'
import classNames from 'classnames'
import { MsButton } from '..'
import { useApplicationContext } from '../../contexts'
import './BareDropdown.scss'

export interface IBareDropdownTemplate {
  dropdown: IComponentTemplate
  container: IComponentTemplate
}

export interface IBareDropdown extends Omit<IComponent, 'palette' | 'template' | 'id'> {
  id: string
  icon?: IconType
  text?: string
  gridPlacement: Alignment
  template?: IBareDropdownTemplate
  genericDropdownStyle?: boolean
  customization?: {
    text?: IText
    gap?: StyleLoopLimit
    gapType?: 'px' | 'rem'
    iconColor?: BrandColors
    iconBoxed?: boolean
  }
  active?: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const BareDropdown = (props: IBareDropdown) => {
  // ************************************
  // Properties
  // ************************************

  const { desktopView } = useApplicationContext()
  const {
    id,
    theme = 'Light',
    brand,
    className,
    children,
    icon,
    text,
    template = MS_BareDropdownTemplate(theme, desktopView),
    gridPlacement,
    genericDropdownStyle = false,
    customization = {
      text: paragraphTextPrefab(),
      gap: 1,
      gapType: 'rem',
      iconBoxed: false
    },
    active,
    isOpen,
    setIsOpen
  } = props

  const classPrefix = 'bare-dropdown'

  // ************************************
  // Lifecycle
  // ************************************

  useEffect(() => {
    document.addEventListener('mousedown', _handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', _handleClickOutside)
    }
  }, [])

  // ************************************
  // Helper Functionality
  // ************************************

  const _handleClickOutside = (event: any) => {
    if (event?.target?.id !== id) {
      setIsOpen(false)
    }
  }

  const _renderIcon = () => {
    let iconToUse: IconType | undefined

    if (icon) {
      iconToUse = icon
    } else if (genericDropdownStyle) {
      iconToUse = isOpen ? IconType.ChevronUp : IconType.ChevronDown
    }

    if (iconToUse) {
      return (
        <Icon
          className={classNames(`${classPrefix}__main-button__icon`, {
            [`${classPrefix}__main-button__icon--boxed`]: customization.iconBoxed
          })}
          type={iconToUse}
          width={1.438}
          height={1.438}
          color={customization.iconColor}
        />
      )
    }
  }

  // ************************************
  // Template handling
  // ************************************

  const _handleContainerTemplate = (): IComponentTemplate => {
    let base: IComponentTemplate = template.container

    if (base?.transform?.grid?.gapType && customization.gapType) {
      base.transform.grid.gapType = customization.gapType
    }

    if (base?.transform?.grid?.gap && customization.gap) {
      base.transform.grid.gap = customization.gap
    }

    return base
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      className={classNames(`${classPrefix}`, {
        [`${className}`]: className
      })}
      alignment='center'
      direction='column'
      boxSizing='border-box'
      brand={brand}
    >
      <MsButton
        id={id}
        className={`${classPrefix}__main-button`}
        template={template.dropdown}
        active={active}
        action={{
          onClick: () => setIsOpen(!isOpen)
        }}
      >
        {text && (
          <Text
            id={id}
            className={`${classPrefix}__main-button__text`}
            {...paragraphTextPrefab()}
            {...customization.text}
            weight={500}
          >
            {text}
          </Text>
        )}
        {_renderIcon()}
      </MsButton>
      {isOpen && (
        <BaseComponent
          id={id}
          className={classNames(`${classPrefix}__grid`, {
            [`${classPrefix}__grid--${gridPlacement}`]: gridPlacement
          })}
          template={_handleContainerTemplate()}
          brand={brand}
        >
          {children}
        </BaseComponent>
      )}
    </StyleGrid>
  )
}
