import React from 'react'
import { IComponent, Text, Icon, IconType, IComponentTemplate, IIcon } from '@fjordkraft/fjordkraft.component.library'
import { useApplicationContext } from '../../../contexts'
import { Link } from 'react-router-dom'
import { MsButton } from '../..'
import { LinkButtonTemplate } from '../../../Prefabs'
import { isExternalUrl } from '../../../services'
import classNames from 'classnames'
import './LinkButton.scss'

/**
 *    ILinkButton
 *    @type {string} to
 *    @type {string} title
 *    @type {object} icon
 *    @type {string} iconPlacement
 *    @type {React.HTMLAttributeAnchorTarget} target
 */
export interface ILinkButton extends IComponent {
  to: string
  title: string
  icon?: IconType
  iconPlacement?: 'left' | 'right'
  target?: React.HTMLAttributeAnchorTarget
}

/**
 *    The LinkButton is either a basic a tag link or router link.
 *    @type {ILinkButton} props
 */
export const LinkButton = (props: ILinkButton) => {
  // ************************************
  // Properties
  // ************************************
  const { activeBrand, activeTheme } = useApplicationContext()
  const { id, className, brand, theme = 'Light', to, title, iconPlacement = 'left', icon, target = '_self' } = props
  const classPrefix = 'link-button'

  // ************************************
  // Render Functionality
  // ************************************

  const renderContent = (template: IComponentTemplate) => {
    const iconConfig = {
      id: id,
      brand: brand,
      className: `${classPrefix}__button__icon-${iconPlacement}`,
      type: icon,
      palette: template.palette
    } as IIcon
    return (
      <>
        {icon && iconPlacement === 'left' && <Icon {...iconConfig} />}
        <Text
          id={id}
          brand={brand}
          theme={theme}
          className={`${classPrefix}__text`}
          type={'label'}
          size={'regular'}
          weight={500}
          family={'main'}
          palette={template.palette}
          underline={true}
        >
          {title}
        </Text>
        {icon && iconPlacement === 'right' && <Icon {...iconConfig} />}
      </>
    )
  }

  // ************************************
  // Render
  // ************************************
  return (
    <>
      {!isExternalUrl(to) && (
        <Link
          id={id}
          className={classNames(`${classPrefix}`, {
            [`${className}`]: className
          })}
          to={to}
          target={target}
        >
          {renderContent(LinkButtonTemplate(theme))}
        </Link>
      )}
      {isExternalUrl(to) && (
        <MsButton
          id={id}
          className={className}
          brand={activeBrand}
          theme={activeTheme}
          template={LinkButtonTemplate(theme)}
          action={{
            link: to
          }}
        >
          {renderContent(LinkButtonTemplate(theme))}
        </MsButton>
      )}
    </>
  )
}
