import React, { useState } from 'react'
import { PopupCard } from '../PopupCard/PopupCard'
import {
  CallState,
  ContentGrid,
  getCounterTheme,
  IAction,
  IComponent,
  LoaderGraphic
} from '@fjordkraft/fjordkraft.component.library'
import { useApplicationCoreDataContext, useApplicationDefaultContext } from '../../contexts'
import { createString, getText } from '../../services'
import { MSRichText } from '../../components'
import { IGuestRelationship, IRelationSentInvitation } from '../../models'
import { CustomerInfoEditor, ICustomerEditorInformation } from '../CustomerInfoModal'
import SuccessSVG from '../../assets/art/service-order-success.svg?react'
import FailureSVG from '../../assets/art/service-order-failure.svg?react'
import { ButtonTypes } from '../../Prefabs'
import './GuestAdminPopup.scss'
import { useAuth } from 'react-oidc-context'

export interface IGuestAdminPopupData extends Omit<IGuestAdminPopup, 'onClose'> {}
export type IGuestPopupType = 'remove-guest' | 'remove-host' | 'add' | 'approve-invitation'

export interface IGuestAdminPopup extends IComponent {
  purpose: IGuestPopupType
  translations: any
  selectedUser?: IGuestRelationship
  onClose: () => void
  pendingGuests?: IRelationSentInvitation[]
  maxGuests?: number
}

export const GuestAdminPopup = (config: IGuestAdminPopup) => {
  // ************************************
  // Properties
  // ************************************

  const { theme, brand, purpose, translations, selectedUser, onClose, maxGuests, pendingGuests } = config
  const { updateCustomerData } = useApplicationCoreDataContext()
  const { defaultProps } = useApplicationDefaultContext()
  const { signoutRedirect } = useAuth()

  const classPrefix = 'guest-admin-popup'

  // ************************************
  // Lifecycle
  // ************************************

  const [state, setState] = useState<CallState>('idle')
  const [customerInputInfo, setCustomerInputInfo] = useState<ICustomerEditorInformation>()
  const [validInput, setValidInput] = useState<boolean>(false)

  // ************************************
  // Handling
  // ************************************

  const _getPrimaryAction = (): IAction | undefined => {
    if (state === 'idle') {
      if (purpose === 'add') {
        return {
          text: getText(`popup-primary-${purpose}`, translations),
          disabled: !validInput,
          onClick: () => {
            if (validInput && customerInputInfo) {
              _inviteGuest(customerInputInfo)
            }
          }
        }
      } else if (purpose === 'remove-guest' || purpose === 'remove-host') {
        return {
          text: getText(`popup-primary-${purpose}`, translations),
          onClick: () => {
            _deleteHostOrGuest(purpose)
          }
        }
      } else if (purpose === 'approve-invitation') {
        return {
          text: getText(`popup-approve-invitation-${purpose}`, translations),
          link: ''
        }
      }
    }
  }

  const _getSecondaryAction = (): IAction => {
    return {
      text: getText('close', translations),
      onClick: onClose
    }
  }

  const _getTitle = (): string => {
    if (state === 'idle') {
      return getText(`popup-title-${purpose}`, translations)
    } else {
      return getText(`popup-title-${state}-${purpose}`, translations)
    }
  }

  const _getDescription = (): string | undefined => {
    if (state === 'idle') {
      if (selectedUser) {
        return createString(getText(`popup-description-${purpose}`, translations), {
          user: selectedUser.firstName
        })
      } else {
        return getText(`popup-description-${purpose}`, translations)
      }
    } else {
      return createString(getText(`popup-description-${state}-${purpose}`, translations), {
        phoneNumber: customerInputInfo?.phoneNumber ?? selectedUser?.phoneNumber,
        firstName: customerInputInfo?.firstName ?? selectedUser?.firstName
      })
    }
  }

  const _alignmentCheck = () => {
    switch (state) {
      case 'aborted':
      case 'error':
      case 'pending':
      case 'success':
        return 'align-center'
      default:
        return 'align-left'
    }
  }

  // ************************************
  // Async Functionality
  // ************************************

  const _inviteGuest = async (info: ICustomerEditorInformation) => {
    if (defaultProps) {
      const { services, translations } = defaultProps
      const { POST } = services

      setState('pending')

      if (info.birthday) {
        let resp = await POST('Guest/invite', {
          firstName: 'Anita test',
          lastName: 'Testinsdottr',
          birthDate: info.birthday,
          phoneNumber: info.phoneNumber,
          guestCustomerId: '100228641', // Hardcoded for test: Anita,
          smsText: createString(getText('inviteSmsText', translations), {
            name: defaultProps?.user?.mainUser?.firstName
          })
        })

        setState(resp.callState)
        updateCustomerData(true)
      }
    }
  }

  const _deleteHostOrGuest = async (purpose: IGuestPopupType) => {
    if (defaultProps) {
      const { services, relationship } = defaultProps
      const { DELETE } = services
      const { chosenHost, isGuest } = relationship

      setState('pending')

      if (selectedUser?.relationshipId) {
        let resp = await DELETE('Guest/relationship', {
          relationshipId: selectedUser.relationshipId,
          guestRelationshipType: purpose === 'remove-guest' ? 'GUEST' : 'HOST'
        })

        if (isGuest && selectedUser.customerId === chosenHost?.customerId) {
          signoutRedirect()
        }

        setState(resp.callState)
        updateCustomerData(true)
      }
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderDescription = () => {
    if (_getDescription() && defaultProps) {
      const { activeBrand, activeTheme } = defaultProps

      return (
        <MSRichText
          className={`${classPrefix}__desc`}
          theme={getCounterTheme(activeTheme)}
          brand={activeBrand}
          text={_getDescription()}
          alignment='lawful'
          customization={{
            type: 'p',
            align: _alignmentCheck()
          }}
        />
      )
    }
  }

  const _renderInputs = () => {
    if (purpose === 'add' && state === 'idle' && defaultProps) {
      return (
        <CustomerInfoEditor
          className={`${classPrefix}__user-editor`}
          brand={brand}
          theme={theme}
          translation={translations}
          maxGuests={maxGuests}
          pendingGuests={pendingGuests}
          customerInformation={{
            birthday: '',
            phoneNumber: ''
          }}
          onChange={(value: ICustomerEditorInformation, valid: boolean) => {
            setCustomerInputInfo(value)
            setValidInput(valid)
          }}
        />
      )
    }
  }

  const _renderStateGraphic = () => {
    let stateGraphic = undefined

    switch (state) {
      case 'aborted':
      case 'error':
        stateGraphic = <FailureSVG />
        break
      case 'pending':
        stateGraphic = (
          <LoaderGraphic
            theme={theme}
            brand={brand}
            thickness={'regular'}
            scaleThickness={0.5}
            size={4}
          />
        )
        break
      case 'success':
        stateGraphic = <SuccessSVG />
        break
    }

    if (stateGraphic) {
      return (
        <ContentGrid
          className={`${classPrefix}__state-content`}
          direction='column'
          alignment='center'
          boxSizing='border-box'
          tagType='section'
          gap={2}
        >
          {stateGraphic}
        </ContentGrid>
      )
    }
  }

  const _getPrimaryButtonStyle = (): ButtonTypes => {
    switch (purpose) {
      case 'remove-guest':
      case 'remove-host':
        return 'urgent'
      case 'add':
      case 'approve-invitation':
        return 'primary'
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <PopupCard
      theme={theme}
      brand={brand}
      primaryActionStyle={_getPrimaryButtonStyle()}
      primaryAction={_getPrimaryAction()}
      secondaryAction={_getSecondaryAction()}
      contentTitle={_getTitle()}
      onClose={onClose}
      contentGap={2}
      contentTitleAlignment={_alignmentCheck()}
      desktopView={defaultProps?.desktopView}
    >
      {_renderStateGraphic()}
      {_renderDescription()}
      {_renderInputs()}
    </PopupCard>
  )
}
