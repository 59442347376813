import { useContext, createContext } from 'react'
import { ICustomerDataToEdit } from '../../pages'
import { ICustomerEditorInformation } from '../../modals'

interface IApplicationUserEditWrapperContext {
  customerDataToEdit: ICustomerDataToEdit | undefined
  setCustomerDataToEdit: (data: ICustomerDataToEdit) => void
  onSubmitFormChange: (customerInformation: ICustomerEditorInformation) => void
}

export const ApplicationUserEditWrapperContext = createContext<IApplicationUserEditWrapperContext>({
  customerDataToEdit: undefined,
  setCustomerDataToEdit: () => {},
  onSubmitFormChange: () => {}
})

export const useApplicationUserEditWrapperContext = () => useContext(ApplicationUserEditWrapperContext)
