import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { IChartCard, ChartCard } from '../ChartCard'
import {
  getColorCode,
  IChartHighlightIndicator,
  IChartTooltip,
  Chart,
  BrandColors
} from '@fjordkraft/fjordkraft.component.library'
import { useApplicationContext } from '../../../../contexts'
import { tNumber } from '../../../../services'

export interface IAreaChartCard extends IChartCard {
  series: any[]
  categories?: string[]
  chartTooltip?: IChartTooltip
  highlights?: IChartHighlightIndicator[]
}

export const AreaChartCard = (props: IAreaChartCard) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id = '6afe4981-f5d5-4a2f-9573-d08bef4f1a5c',
    className,
    brand,
    theme,
    series,
    chartTooltip,
    highlights,
    categories
  } = props
  const { desktopView } = useApplicationContext()
  const classPrefix = 'ms-area-chart-card'

  // ************************************
  // Lifecycle
  // ************************************

  const [clampValues, setClampValues] = useState<any>({ min: 0, max: 1 })

  useEffect(() => {
    _handleClampValues(series)
  }, [series])

  // ************************************
  // Clamp handling
  // ************************************

  const _handleClampValues = (series: any) => {
    let min = 9999
    let max = 0.1

    if (series.length > 0) {
      series.forEach((serie: any) => {
        if (serie.data.length > 0) {
          serie.data.forEach((val: number) => {
            if (val < min) {
              min = val
            }

            if (val > max) {
              max = val
            }
          })
        }
      })
    }

    setClampValues({
      min: _clampMin(min),
      max: _clampMax(max)
    })
  }

  const _clampMax = (max: number) => {
    return Math.ceil(max * 10) / 10
  }

  const _clampMin = (min: number) => {
    if (min < 0) {
      return -Math.abs(min) * 1.1
    } else {
      return 0
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ChartCard
      {...props}
      id={id}
      className={classnames(classPrefix, {
        [`${className}`]: className
      })}
      fallback={{
        show: false,
        content: <></>
      }}
    >
      {series && series.length > 0 && (
        <Chart
          className={`${classPrefix}__chart`}
          brand={brand}
          theme={theme}
          series={series}
          categories={categories}
          chartTooltip={chartTooltip}
          highlights={highlights}
          type='area'
          height='300px'
          strokeCustomization={{
            type: ['smooth', 'smooth'],
            dash: [0, 0]
          }}
          fillCustomization={{
            type: ['gradient', 'none'],
            opacity: [1, 0]
          }}
          colors={[
            getColorCode({
              color: BrandColors['primary-shade-light-2'],
              type: 'hex',
              element: document.getElementById(id)
            }),
            getColorCode({
              color: BrandColors['primary-shade-light-2'],
              type: 'hex',
              element: document.getElementById(id)
            })
          ]}
          xAxisCustomization={{
            tickAmount: desktopView ? 9 : 5,
            formatter: (value: any) => {
              return value
            }
          }}
          yAxisCustomization={{
            tickAmount: 4,
            max: clampValues.max,
            min: clampValues.min,
            formatter: (value: any) => {
              return `${tNumber(value, 'no-NO', 2)} ${chartTooltip?.y?.suffix}`
            }
          }}
        />
      )}
    </ChartCard>
  )
}
