import { IAction, IconType } from '@fjordkraft/fjordkraft.component.library'
import {
  IPointPlankPrefab,
  ITextPlankPrefab,
  MS_ButtonTemplate,
  TrumfPlankTemplate,
  getPlankPrefab
} from '../../../../../Prefabs'
import { ICustomerInstallation, IServicePage, ServiceStatus } from '../../../../../models'
import {
  IResponse,
  createString,
  getServiceStatusColor,
  getSpecificAddonStateForInstallation,
  getText,
  mapServiceStatusToOrderResult,
  narrowDownPopupState,
  narrowDownResultStatusForPlank
} from '../../../../../services'
import { format } from 'date-fns'
import { IServiceBasePageData } from '../../ServiceBasePageData'
import { IStatePlank } from '../../../../../components'
import { IServiceOrderOrCancel, ServiceOrderAndCancelSteppes } from '../../../../../modals'
import { IMSPlankWall } from '../../../../../blocks'

export interface ITrumfTransactions {
  sumPoints: number
  periodStart: string
  periodEnd: string
  monthly: ITrumfMonth[]
  transactions: ITrumfTransaction[]
}

export interface ITrumfMonth {
  sumPoints: number
  accumulatedPointsForYear: number
  year: number
  month: number
}

export interface ITrumfTransaction {
  id: string
  trumfId: string
  points: number
  date: string
}

export const getTrumfWalls = async (config: IServiceBasePageData): Promise<IMSPlankWall[]> => {
  let walls: IMSPlankWall[] = []

  let trumfWalls: IMSPlankWall[] | undefined = await _getTrumfPlankWalls(config)

  if (trumfWalls) {
    walls = walls.concat(trumfWalls)
  }

  return walls
}

const _getTrumfPlankWalls = async (config: IServiceBasePageData): Promise<IMSPlankWall[]> => {
  let walls: IMSPlankWall[] = []

  let detailPlanks: IStatePlank[] | undefined = await _getTrumfDetailPlanks(config)
  let orderAndCancelPlanks: IStatePlank[] = _getOrderAndCancelPlanks(config)

  if (detailPlanks) {
    walls.push({ planks: detailPlanks })
  }

  walls = walls.concat({ planks: orderAndCancelPlanks })

  return walls
}

const _getOrderAndCancelPlanks = (config: IServiceBasePageData): IStatePlank[] => {
  const { user, relationship, translations, onClickServiceHandling, activeTheme, desktopView } = config
  const { userData } = user
  const { isGuest } = relationship

  let statusData = getCustomerInstallationStatusData(config)
  let planks: IStatePlank[] = []
  let title: string = `${userData.firstName} ${userData.lastName}`
  let description: string = createString(getText('customerNumber', translations), {
    customerNumber: userData.customerId
  })
  let action: IAction = {
    text: desktopView ? getText('configure', translations) : undefined,
    icon: IconType.Edit,
    disabled: !statusData.allowOrder || isGuest,
    onClick: () => {
      onClickServiceHandling({
        status: statusData.status,
        userData,
        page: translations
      } as IServiceOrderOrCancel)
    }
  }

  if (_showActionButton(statusData.status, translations)) {
    planks.push(
      getPlankPrefab('Action', {
        action,
        actionButtonPadding: desktopView ? 'small' : 'default',
        actionIconPlacement: 'Right',
        left: {
          title,
          description
        },
        right: {
          template: MS_ButtonTemplate(activeTheme, 'primary')
        }
      })
    )
  } else {
    planks.push(
      getPlankPrefab('Text', {
        left: {
          title,
          description
        },
        right: translations.allowOrder && {
          title: undefined,
          customization: {
            title: {
              color: getServiceStatusColor(statusData.status)
            }
          }
        }
      } as ITextPlankPrefab)
    )
  }

  planks = planks.concat(_getInstallationStatusPlanks(statusData.installationStatuses))

  return planks
}

interface IInstallationStatus {
  status: ServiceStatus
  text: string
  streetAddress: string
}

export const getCustomerInstallationStatusData = (config: IServiceBasePageData) => {
  const { user, translations, addonStates } = config
  const { userData } = user

  let installationStatuses: IInstallationStatus[] = []

  if (userData.installations && userData.installations.length > 0) {
    installationStatuses = userData.installations.map((installation: ICustomerInstallation) => {
      const status = narrowDownResultStatusForPlank(
        getSpecificAddonStateForInstallation(translations.productDefinitionId, installation.meterId, addonStates)
          ?.state ?? 'INACTIVE'
      )

      return {
        text: _getInstallationStatusText(status, translations),
        status: status,
        streetAddress: installation.address.streetAddress
      }
    })
  }

  let status: ServiceStatus = installationStatuses.find(
    instStatus => mapServiceStatusToOrderResult(instStatus.status) === 'FAILURE'
  )
    ? 'INACTIVE'
    : 'ACTIVE'

  let allowOrder = _allowOrderTrumfBasedOnInstallationStatuses(installationStatuses)

  return { allowOrder, status, installationStatuses }
}

const _allowOrderTrumfBasedOnInstallationStatuses = (installationStatuses: IInstallationStatus[]): boolean => {
  if (installationStatuses && installationStatuses.length > 0) {
    for (let installationStatus of installationStatuses) {
      let statusAsStep: ServiceOrderAndCancelSteppes = narrowDownPopupState(installationStatus.status)

      if (statusAsStep === 'IN_PROGRESS') {
        return false
      }
    }
  }

  return true
}

const _getInstallationStatusText = (status: ServiceStatus, transactions: IServicePage) => {
  if (status === 'ACTIVE') {
    return getText('plankActivationStatusActive', transactions)
  } else if (status === 'INACTIVE') {
    return getText('plankActivationStatusNotActive', transactions)
  } else {
    return getText('plankActivationStatusInProgress', transactions)
  }
}

const _getInstallationStatusPlanks = (statuses: IInstallationStatus[]): IStatePlank[] => {
  let planks: IStatePlank[] = []

  statuses.forEach((status: IInstallationStatus) => {
    planks.push(
      getPlankPrefab('Text', {
        left: {
          title: status.streetAddress
        },
        right: {
          title: status.text,
          customization: {
            title: {
              color: getServiceStatusColor(status.status)
            }
          }
        }
      })
    )
  })

  return planks
}

const _getTrumfDetailPlanks = async (config: IServiceBasePageData) => {
  const { services, translations } = config
  const { GET } = services

  let resp: IResponse = await GET('Trumf/transactions')

  if (resp.callState === 'success' && resp.data) {
    let trumfData: ITrumfTransactions = resp.data

    return [
      getPlankPrefab('Point', {
        brand: 'brand-trumf',
        theme: 'Dark',
        template: TrumfPlankTemplate('Dark', 'top'),
        useDecimals: true,
        left: {
          title: getText('plankOverviewTitle', translations),
          description: `${format(new Date(trumfData.periodStart), 'dd.MM.yyyy')} - ${format(
            new Date(trumfData.periodEnd),
            'dd.MM.yyyy'
          )}`,
          dontUseCounterTheme: true
        },
        points: trumfData.sumPoints,
        pointsLabel: getText('currency', translations)
      } as IPointPlankPrefab),
      getPlankPrefab('Text', {
        left: {
          title: getText('plankTransactionsTitle', translations),
          description: getText('plankTransactionsDesc', translations)
        },
        right: {
          icon: IconType.ChevronRight
        },
        action: {
          link: 'transaksjoner',
          useRouterLink: true
        }
      })
    ] as IStatePlank[]
  }
}

const _showActionButton = (status: ServiceStatus, servicePage: IServicePage): boolean => {
  let showButton: boolean = false

  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
      showButton = true
      break
    case 'INACTIVE':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
    case 'TERMINATED':
    case 'ORDER_FAILED':
      showButton = servicePage.allowOrder ?? true
      break
  }

  return showButton
}
