import { Theme } from '@fjordkraft/fjordkraft.component.library'
import { ICustomer, ICustomerInstallation, IServicePage } from '../../../models'
import { IServiceChartPage } from './ServiceChartPage'
import { getText } from '../../../services'
import { getServiceChartDataTrumf } from './ServiceChartPageDataHandlers/ServiceChartDataTrumf'
import { getRequest } from '../../../contexts'

export interface IServiceChartPageData {
  page: IServicePage
  userData: ICustomer
  installation: ICustomerInstallation
  serviceId: string
  theme: Theme
  GET: getRequest
}

// ************************************
// Public
// ************************************

export const getPageContent = async (config: IServiceChartPageData) => {
  const { page, serviceId } = config

  return {
    title: getText('chartPageTitle', page),
    back: {
      link: `${serviceId}`,
      text: getText('pageTitle', page)
    },
    description: getText('pageDescription', page),
    translations: page
  } as IServiceChartPage
}

// ************************************
// Chart
// ************************************

export interface IChartParsedChartData {
  serviceId: string
  page: IServicePage
  date: Date
  GET: getRequest
}

export const getParsedChartData = async (config: IChartParsedChartData) => {
  const { serviceId } = config

  let data: any = undefined

  if (serviceId === 'trumf') {
    data = await getServiceChartDataTrumf(config)
  }

  return data
}
