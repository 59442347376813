import React from 'react'
import { HomePage } from './HomePage'
import { useApplicationGuestsAndHostsContext } from '../../contexts/variations/ApplicationGuestsAndHostsContext'
import { HomePageNoAccess } from './HomePageNoAccess'

export const HomePageWrapper = () => {
  const { chosenHost, mainUser } = useApplicationGuestsAndHostsContext()
  const showNoAccess = !chosenHost && !mainUser?.customerId
  return showNoAccess ? <HomePageNoAccess /> : <HomePage />
}
