import React from 'react'
import { IComponent, IComponentTemplate } from '@fjordkraft/fjordkraft.component.library'
import { Card } from '../Card'
import { AlertCardTemplate } from '../../../Prefabs'
import './AlertCard.scss'

export interface IAlertCard extends IComponent {
  template: IComponentTemplate
}

export const AlertCard = (props: IAlertCard) => {
  // ************************************
  // Properties
  // ************************************

  const { id, children, brand, theme = 'Light', template = AlertCardTemplate(theme) } = props
  const classPrefix = 'alert-card'

  // ************************************
  // Render
  // ************************************

  return (
    <Card
      id={id}
      brand={brand}
      className={`${classPrefix}`}
      template={template}
    >
      {children}
    </Card>
  )
}
