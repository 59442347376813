import React, { useState, useMemo, useEffect } from 'react'
import { SubPageLayout } from '../Base/SubPageLayout/SubPageLayout'
import {
  AddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext
} from '../../contexts'
import { getParsedAddonStates } from '../../services'
import { IAddonData } from '../../models'

export const ServiceWrapperPage = () => {
  // ************************************
  // Lifecycle
  // ************************************

  const { epiChildren } = useApplicationCoreDataContext()
  const { GET } = useApplicationServicehandlerContext()

  // ************************************
  // Lifecycle
  // ************************************

  const [addonStates, setAddonStates] = useState<IAddonData[]>()

  useEffect(() => {
    _setAddonStates()
  }, [epiChildren])

  const updateAddonStates = async () => {
    let addonData: IAddonData[] | undefined = undefined

    if (epiChildren) {
      addonData = await getParsedAddonStates(epiChildren, GET, true)
    }

    if (addonData !== addonStates) {
      setAddonStates(addonData)
    }
  }

  const _setAddonStates = () => {
    if (!addonStates) {
      updateAddonStates()
    }
  }

  const context = useMemo(() => {
    return {
      addonStates,
      updateAddonStates
    }
  }, [addonStates])

  // ************************************
  // Render
  // ************************************

  return (
    <AddonServicesContext.Provider value={context}>
      <SubPageLayout autoAdjust={false} />
    </AddonServicesContext.Provider>
  )
}
