import { getRequest, typedPutRequest } from '../../contexts'
import { ICustomerInstallationPowerPlant } from '../../models'

// ************************************
// GET
// ************************************

export const fetchMeterType = async (GET: getRequest, meterId: string) => {
  let resp = await GET(`installations/${meterId}/metertype`)
  return resp?.data
}

export const fetchPowerPlant = async (GET: getRequest, priceArea: string) => {
  let resp = await GET(`installations/powerplant/${priceArea}`)
  return resp?.data as ICustomerInstallationPowerPlant
}

// ************************************
// PUT
// ************************************

export interface UpdateMeterNumberRequest {
  meterNumber: string
  postalCode: string
  streetAddress: string
  lastUpdatedDate: Date
}

export const updateMeterNumber = async (
  PUTTYPED: typedPutRequest,
  orderModuleId: number,
  body: UpdateMeterNumberRequest
) => {
  let resp = await PUTTYPED(`installations/updateMeterNumber/${orderModuleId}`, body)
  return resp
}
