import React from 'react'
import {
  Accordion,
  Text,
  BrandColors,
  IAccordionItem,
  StyleGrid,
  MasterPlank
} from '@fjordkraft/fjordkraft.component.library'
import { IPointPlankPrefab, ITextPlankPrefab, getPlankPrefab, paragraphTextPrefab } from '../../Prefabs'
import { IBlock } from '../../models'
import classNames from 'classnames'
import { ColumnChartCard } from '../../components'
import { capitalizeFirstLetter, getText, tNumber } from '../../services'
import './ChartAccordion.scss'

export interface IChartAccordionItem {
  leftText: string
  rightText: string
  rightColor: BrandColors
  chartData: any
}

export interface IChartAccordion extends IBlock {
  overview: IPointPlankPrefab
  header: {
    leftText: string
    rightText: string
  }
  items: IChartAccordionItem[]
  translations: any
  forceNiceScale?: boolean
}

export const ChartAccordion = (props: IChartAccordion) => {
  // ************************************
  // Render
  // ************************************

  const { id, className, brand, theme, items, overview, header, translations, forceNiceScale = true } = props
  const classPrefix = 'chart-accordion'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderAccordionItems = () => {
    return items.map((item: IChartAccordionItem, index: number) => {
      return {
        theme,
        brand,
        className: index === 0 ? `${classPrefix}__chart-accordion__item--top` : undefined,
        content: _renderChartContent(item.chartData, translations),
        leftTitleContent: (
          <Text
            {...paragraphTextPrefab()}
            weight={500}
          >
            {item.leftText}
          </Text>
        ),
        rightTitleContent: (
          <Text
            {...paragraphTextPrefab()}
            color={item.rightColor}
            weight={500}
          >
            {item.rightText}
          </Text>
        )
      } as IAccordionItem
    })
  }

  const _renderChartContent = (chartData: any, translations: any) => {
    return (
      <ColumnChartCard
        series={chartData.series}
        categories={[]}
        className={`${classPrefix}__chart`}
        theme={theme}
        brand={brand}
        forceNiceScale={forceNiceScale}
        chartTooltip={{
          show: true,
          x: {
            formatter: (value: any, opt: any) => {
              let dateObj = chartData.series[opt.seriesIndex].data[opt.dataPointIndex].date
              let text = ''

              text = `${capitalizeFirstLetter(value)} ${dateObj.getFullYear()}`

              return `${text}`
            }
          },
          y: {
            formatter: (value: any, opt: any) => {
              let consumptionUse = `${tNumber(value, 'no-NO', 2)} ${getText('currency', translations)}`

              let text: string = `${consumptionUse}`

              return text
            },
            suffix: getText('currency', translations),
            showOnAxisLabels: true
          }
        }}
      />
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      direction='column'
      alignment='top-left'
      gap={1}
      gapType='px'
    >
      <MasterPlank
        {...getPlankPrefab('Point', overview)}
        brand={brand}
        theme={theme}
        className={`${classPrefix}__overview`}
        location='top'
      />
      <MasterPlank
        {...getPlankPrefab('Text', {
          left: {
            title: header.leftText,
            customization: {
              title: {
                weight: 500
              }
            }
          },
          right: {
            title: header.rightText,
            customization: {
              title: {
                weight: 500
              }
            }
          }
        } as ITextPlankPrefab)}
        brand={brand}
        theme={theme}
        className={`${classPrefix}__header`}
        location='middle'
      />
      {translations && (
        <Accordion
          className={`${classPrefix}__accordion`}
          theme={theme}
          brand={brand}
          elements={_renderAccordionItems()}
        />
      )}
    </StyleGrid>
  )
}
