import React, { useState } from 'react'
import classname from 'classnames'
import { ContentGrid, StyleGrid, IconType } from '@fjordkraft/fjordkraft.component.library'
import { getPageContent } from './BenefitsOverviewPageData'
import { useApplicationServicehandlerContext, useDefaultPageContext, useSubPageLayoutContext } from '../../../contexts'
import BenefitsArt from '../../../assets/art/benefits/benefits.png'
import BackgroundArt from '../../../assets/art/benefits/benefits-background.svg'
import { useNavigate } from 'react-router-dom'
import { Constants } from '../../../data'
import { IPlankHouse, PitchCardsBlock, PlankHouseBlock } from '../../../blocks'
import { getText } from '../../../services'
import { MS_ButtonTemplate } from '../../../Prefabs'
import { IFlexiblePitchCard } from '../../../blocks/PitchCardsBlock/FlexiblePitchCard'
import { ILocationNavigationInfoBoxContent } from '../../../models'
import { ActionButton } from '../../../components'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import { fetchKickbackSummary } from '../../../services/collection/BenefitService'
import './BenefitsOverviewPage.scss'

export interface IBenefitsOverviewPage extends IDefaultViewProps {
  info: ILocationNavigationInfoBoxContent
  listedOptions: IPlankHouse
  benefitButtonCardOptions: IFlexiblePitchCard[]
  hasOnboarded: boolean
}

export const BenefitsOverviewPage = () => {
  // ************************************
  // Properties
  // ************************************

  const { setInfoBoxContent } = useSubPageLayoutContext()
  const { setMainContentClamping } = useDefaultPageContext()
  const { GET } = useApplicationServicehandlerContext()
  const navigate = useNavigate()
  const classPrefix = 'benefits-overview-page'

  // ************************************
  // Lifecycle
  // ************************************

  const [summary, setSummary] = useState<any>()

  // ************************************
  // Summary handling
  // ************************************

  const _checkKickbackSummary = async () => {
    let summary = await fetchKickbackSummary(GET)

    setSummary(summary)

    if (!summary.hasOnboarded) {
      navigate(Constants.paths.benefitsIntroPage)
    }
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.benefits.type,
      usesSubPage: true
    },
    dependencies: [{ navigateFunc: navigate, summary }],
    startedRender: () => {
      _checkKickbackSummary()
    },
    renderUpdate: () => {
      setMainContentClamping('clamp')
    },
    onDataChange: (config: IBenefitsOverviewPage) => {
      if (setInfoBoxContent) {
        setInfoBoxContent(config.info)
      }
    },
    handleData: getPageContent,
    render: (config: IBenefitsOverviewPage) => {
      const { activeBrand, activeTheme, translations, benefitButtonCardOptions, listedOptions } = config

      return (
        <StyleGrid
          className={`${classPrefix}`}
          direction='column'
          alignment={'top-left'}
          boxSizing='border-box'
          gap={4}
        >
          {listedOptions && (
            <StyleGrid
              alignment='top-center'
              direction='column'
              className={`${classPrefix}__middle`}
            >
              <img
                className={`${classPrefix}__middle__image-background`}
                src={BackgroundArt}
                alt={'Orange baggrund'}
              />
              <img
                className={`${classPrefix}__middle__image-benefits`}
                src={BenefitsArt}
                alt={'Fordele'}
              />
              <PlankHouseBlock
                className={classname(`${classPrefix}__button-list`, {
                  [`${classPrefix}__button-list--${activeTheme}`]: activeTheme
                })}
                {...listedOptions}
                theme={activeTheme}
                brand={activeBrand}
                titleGap={0}
              />
            </StyleGrid>
          )}
          {benefitButtonCardOptions && (
            <PitchCardsBlock
              className={`${classPrefix}__button-cards`}
              overrideScaling={true}
              brand={activeBrand}
              theme={activeTheme}
              items={benefitButtonCardOptions}
            />
          )}
          <ContentGrid
            className={`${classPrefix}__terms-link-container`}
            alignment={'center'}
            direction={'column'}
            tagType={'nav'}
          >
            <ActionButton
              brand={activeBrand}
              template={MS_ButtonTemplate(activeTheme, 'link')}
              action={{
                link: getText('termsLink', translations),
                text: getText('termsLinkText', translations),
                icon: IconType.ExternalLinkThick
              }}
              iconPlacement='Right'
              padding='default'
            />
          </ContentGrid>
        </StyleGrid>
      )
    }
  })
}
