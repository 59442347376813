import { IAction, IconType, IImage, IOption } from '@fjordkraft/fjordkraft.component.library'
import { IDetailsDescriptionBlock, IHighlightedDropdownBlock, IPlankHouse, ISectionBlock } from '../../../blocks'
import {
  IAddonData,
  ICustomerAccountInformation,
  ICustomerInstallation,
  IDefaultProps,
  ServiceStatus
} from '../../../models'
import { IServiceBasePageView } from './ServiceBasePage'
import { showingDropdownOrder, createString, getText, parseActiveExtraProducts, getStatus } from '../../../services'
import { Constants } from '../../../data'
import {
  IServicePageDataDescriptions,
  getCoreDetails,
  getServicePageDataDescriptions,
  getServicePageDataPitchCards,
  getServicePageDataSections,
  getServicePageHouseData
} from './Datahandling'
import { IServiceOrderOrCancel } from '../../../modals'
import { v4 as uuid4 } from 'uuid'
import { typedGetRequest } from '../../../contexts'

export interface IServiceBasePageData extends IDefaultProps {
  onClickServiceHandling: (value: IServiceOrderOrCancel) => void
  setInstallation?: (installation?: ICustomerInstallation) => void
  account?: ICustomerAccountInformation
  addonStates?: IAddonData[]
  GETTYPED: typedGetRequest
}

// ************************************
// Main
// ************************************

export const getServiceBasePageData = async (
  config: IServiceBasePageData
): Promise<IServiceBasePageView | undefined> => {
  if (config?.translations) {
    return {
      ...config,
      sub: {
        title: _getTitle(config),
        back: _getBack(config)
      },
      localBrand: _getLocalBrand(config),
      banner: _getBanner(config),
      house: await _getHouse(config),
      sections: await _getSections(config),
      pitchCards: await _getPitchCards(config),
      coreDetails: await _getCoreDetails(config),
      descriptions: await _getDescriptions(config),
      dropOrder: await _getDropOrder(config)
    } as IServiceBasePageView
  }
}

// ************************************
// PRIVATE: Local brand
// ************************************

const _getLocalBrand = (config: IServiceBasePageData): string => {
  const { translations } = config
  return `brand-${translations.servicePageType?.toLocaleLowerCase() ?? Constants.uiBrand}`
}

// ************************************
// PRIVATE: Descriptions
// ************************************

const _getDescriptions = async (config: IServiceBasePageData): Promise<IServicePageDataDescriptions | undefined> => {
  const { translations, user } = config
  const { userData } = user

  if (translations && userData) {
    return getServicePageDataDescriptions(translations, await getStatus(config))
  }
}

// ************************************
// PRIVATE: Core Details
// ************************************

const _getCoreDetails = async (config: IServiceBasePageData): Promise<IDetailsDescriptionBlock[] | undefined> => {
  const { translations, user } = config
  const { userData } = user

  if (userData && translations) {
    let details: IDetailsDescriptionBlock[] = await getCoreDetails(config, await getStatus(config))
    return details
  }
}

// ************************************
// PRIVATE: Sections
// ************************************

const _getSections = async (config: IServiceBasePageData): Promise<ISectionBlock[] | undefined> => {
  const { translations, user } = config
  const { userData } = user

  if (translations && userData) {
    let sections: ISectionBlock[] | undefined = await getServicePageDataSections(config, await getStatus(config))

    return sections
  }
}

// ************************************
// PRIVATE: Pitch Cards
// ************************************

const _getPitchCards = async (config: IServiceBasePageData) => {
  const { translations, user } = config
  const { userData } = user

  if (translations && userData) {
    return getServicePageDataPitchCards(config, await getStatus(config))
  }
}

// ************************************
// PRIVATE: Title
// ************************************

const _getTitle = (config: IServiceBasePageData): string => {
  return getText('pageTitle', config.translations)
}

// ************************************
// PRIVATE: Banner
// ************************************

const _getBanner = (config: IServiceBasePageData): IImage => {
  return {
    src: config.translations.imageSrc,
    alt: `${getText('pageTitle', config.translations)}`
  } as IImage
}

// ************************************
// PRIVATE: Back
// ************************************

const _getBack = (config: IServiceBasePageData): IAction => {
  return {
    text: getText('back', config.translations),
    link: `${Constants.paths.servicesPage}`
  } as IAction
}

// ************************************
// PRIVATE: House
// ************************************

const _getHouse = async (config: IServiceBasePageData): Promise<IPlankHouse | undefined> => {
  if (config) {
    return getServicePageHouseData(config)
  }
}

// ************************************
// PRIVATE: Drop Order
// ************************************

const _getDropOrder = async (config: IServiceBasePageData): Promise<IHighlightedDropdownBlock | undefined> => {
  const { translations, onClickServiceHandling, addonStates, user, relationship } = config
  const { isGuest } = relationship
  const { installation, userData } = user

  if (
    user.installation &&
    translations?.serviceAdditionalAddonBlocks &&
    translations.serviceAdditionalAddonBlocks.length > 0 &&
    translations.allowOrder
  ) {
    let status: ServiceStatus = await getStatus(config)
    let options: IOption[] = []

    if (showingDropdownOrder(config, status)) {
      const activeExtraProductIds = parseActiveExtraProducts(user.installation, config.translations, addonStates)

      for (let additionalAddonService of translations.serviceAdditionalAddonBlocks) {
        if (additionalAddonService.active) {
          const serviceAlreadyActiveOnContract = activeExtraProductIds?.includes(
            Number(additionalAddonService.serviceId)
          )

          const optionText = `${getText('name', additionalAddonService, 'translationItems')}${
            serviceAlreadyActiveOnContract
              ? '\n' + getText('disabledDueToServiceAlreadyActive', additionalAddonService, 'translationItems')
              : ''
          }`

          options.push({
            id: uuid4(),
            text: optionText,
            value: additionalAddonService.serviceId,
            disabled: serviceAlreadyActiveOnContract
          })
        }
      }

      // Sorting the disabled addons last:
      options.sort((a, b) => {
        return Number(a.disabled) - Number(b.disabled)
      })

      let activeChoice: IOption = options[0]

      return {
        title: getText('dropOrderTitle', translations),
        description: createString(getText('dropOrderSubTitle', translations), {
          address: installation?.address.streetAddress,
          meterId: installation?.meterId
        }),
        dropDownPlaceholder: getText('dropOrderPlaceholderText', translations),
        action: {
          disabled: isGuest,
          text: getText('order', translations),
          onClick: () => {
            onClickServiceHandling({
              status: status,
              userData,
              page: translations,
              installation,
              selectedSubService: activeChoice
            })
          },
          icon: IconType.ExternalLinkThick
        },
        onChange: (option: IOption) => {
          activeChoice = option
        },
        options
      }
    }
  }

  return undefined
}
