import { IDetailCheckboxCollectionBlockData } from '../../../blocks'
import {
  IDetailCheckboxItemData,
  IDetailCheckboxListData,
  IDetailCheckboxListItem
} from '../../../blocks/DetailCheckboxCollectionBlock/DetailCheckboxItem/DetailCheckboxItem'
import { Constants } from '../../../data'
import { ConsentState, ConsentStateEnum, IConsent, IDefaultProps } from '../../../models'
import { getMarketingConsentText, getText, getTextV2 } from '../../../services'

// ************************************
// Interface
// ************************************

export interface IConsentPageData extends IDefaultProps {
  consents: IConsent[]
  isReservedAgainstMarketing: boolean
}

// ************************************
// Page data handling
// ************************************

export const getPageContent = async (config: IConsentPageData) => {
  const { translations, consents } = config

  return {
    ...config,
    sub: {
      title: getText('pageTitle', translations),
      subTitle: getTextV2({
        key: 'pageSubTitle',
        translations,
        includeMissing: false
      }),
      back: {
        text: getText('back', translations),
        link: Constants.paths.userPage
      }
    },
    description: getTextV2({
      key: 'description',
      translations,
      includeMissing: false
    }),
    detailCheckboxCollection: await getConsentCollectionData({
      ...config,
      marketingConsents: consents
    })
  }
}

// ************************************
// Handling
// ************************************

interface IGetDetailCheckboxCollectionData extends IConsentPageData {
  marketingConsents: IConsent[]
}

export const getConsentCollectionData = async (
  config: IGetDetailCheckboxCollectionData
): Promise<IDetailCheckboxCollectionBlockData[]> => {
  return Promise.all(
    config.translations.detailCheckboxRows.map(async (detailCheckboxRow: any) => {
      let items = await _getDetailCheckboxCollectionItems(detailCheckboxRow.detailCheckboxRows, config)

      return {
        id: detailCheckboxRow.blockId,
        title: detailCheckboxRow.title,
        items,
        description: detailCheckboxRow.content
      } as IDetailCheckboxCollectionBlockData
    })
  )
}

const _getDetailCheckboxCollectionItems = async (
  itemData: any[],
  config: IGetDetailCheckboxCollectionData
): Promise<IDetailCheckboxItemData[]> => {
  return Promise.all(
    itemData.map(async (item: any) => {
      let listData = await getPartners(item.checkId, config.translations)

      return {
        id: item.checkId,
        title: item.title,
        state: getConsentState(item.checkId, config.marketingConsents),
        description: item.content,
        listData
      }
    })
  )
}

export const getPartners = async (id: string, translations: any): Promise<IDetailCheckboxListData | undefined> => {
  let items: IDetailCheckboxListItem[] | undefined
  let rows = await getMarketingConsentText()

  if (rows?.length) {
    for (const row of rows) {
      if (row.partners?.length && row.consentId === id) {
        items = row.partners
        break
      }
    }
  }

  if (items?.length) {
    return {
      title: getTextV2({
        translations,
        key: `listTitle_${id}`,
        includeMissing: false
      }),
      items
    }
  }
}

export const formatConsentData = (consents: IConsent[] | undefined, translations: any, id?: string) => {
  const rows = translations.detailCheckboxRows
  let parsedRows: IDetailCheckboxItemData[] = []

  for (const row of rows) {
    if (row.blockId === 'Marketing') {
      for (const item of row.detailCheckboxRows) {
        const currentConsent = consents?.find(cons => cons.consentName === item.checkId)
        parsedRows.push({
          id: item.checkId,
          title: item.title,
          description: item.content,
          ...(currentConsent?.partners && {
            listData: {
              title: getTextV2({
                translations,
                key: `listTitle_${id}`,
                includeMissing: false
              }),
              items: currentConsent?.partners
            }
          }),
          state: currentConsent?.value ?? ConsentStateEnum.PENDING,
          location: 'single'
        })
      }
    }
  }
  return parsedRows
}
// ************************************
// Helpers
// ************************************

export const getConsentState = (id: string, marketingConsents: IConsent[]): ConsentState =>
  marketingConsents?.find(e => e.consentName === id)?.value ?? ConsentStateEnum.DECLINED
