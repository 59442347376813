import { BrandColors } from '@fjordkraft/fjordkraft.component.library'

export const BUTTON_TEMPLATE_CTA_INFO = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['background-shade-light-2'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['background-shade-light-2'] },
      icon: { color: BrandColors['text-shade-dark-3'] },
      hover: {
        background: {
          color: BrandColors['background-shade-light-3'],
          opacity: 0.8
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['background-shade-light-3'], opacity: 0.8 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 }
      },
      focus: {
        background: {
          color: BrandColors['background-shade-light-2'],
          opacity: 0.8
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['background-shade-light-2'], opacity: 0.8 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-2'],
          opacity: 0.3
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        border: { color: BrandColors['background-shade-light-2'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 }
      }
    },
    Dark: {
      background: { color: BrandColors['background-shade-light-2'] },
      text: { color: BrandColors['text-shade-dark-3'] },
      border: { color: BrandColors['background-shade-light-2'] },
      icon: { color: BrandColors['text-shade-dark-3'] },
      hover: {
        background: {
          color: BrandColors['background-shade-light-3'],
          opacity: 0.8
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['background-shade-light-3'], opacity: 0.8 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 }
      },
      focus: {
        background: {
          color: BrandColors['background-shade-light-2'],
          opacity: 0.8
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        border: { color: BrandColors['background-shade-light-2'], opacity: 0.8 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.8 },
        outline: { color: BrandColors['primary-shade-light-2'] }
      },
      disabled: {
        background: {
          color: BrandColors['background-shade-light-2'],
          opacity: 0.3
        },
        text: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 },
        border: { color: BrandColors['background-shade-light-2'], opacity: 0.3 },
        icon: { color: BrandColors['text-shade-dark-3'], opacity: 0.3 }
      }
    }
  }
}
