import { IMSPlankWall } from '../../../blocks'
import { Constants } from '../../../data'
import invoiceSvg from '../../../assets/art/benefits/usePoints-invoice.svg'
import webshopSvg from '../../../assets/art/benefits/usePoints-webshop.svg'
import { getPlankPrefab } from '../../../Prefabs'
import { IImagePlankPrefab } from '../../../Prefabs/Planks/ImagePlankPrefab/ImagePlankPrefab'
import { getText } from '../../../services'
import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { IDefaultProps } from '../../../models'

export interface IUsePointsPageData extends IDefaultProps {
  navigateFunc: Function
}

// ************************************
// PUBLIC
// ************************************

export const getPageContent = async (config: IUsePointsPageData) => {
  const { translations, navigateFunc } = config

  if (translations) {
    return {
      ...config,
      sub: {
        title: getText('usePointsPageTitle', translations),
        back: {
          text: getText('usePointsBack', translations),
          link: Constants.paths.benefitsPage
        }
      },
      listedOptions: getListedOptions(translations, navigateFunc)
    }
  }
}

// ************************************
// PRIVATE
// ************************************

const getListedOptions = (translation: any, navigateFunc: Function) => {
  let wall: IMSPlankWall = {
    planks: []
  }

  wall.planks.push(
    getPlankPrefab('Image', {
      left: {
        title: `${getText('plankLowerBillTitle', translation)}`,
        description: getText('plankLowerBillDescription', translation),
        image: {
          src: invoiceSvg,
          alt: 'faktura'
        }
      },
      right: {
        icon: IconType.ChevronRight
      },
      action: {
        onClick: () => navigateFunc(Constants.paths.usePointsInvoicePage)
      }
    } as IImagePlankPrefab)
  )
  wall.planks.push(
    getPlankPrefab('Image', {
      left: {
        title: getText('plankWebshopTitle', translation),
        description: getText('plankWebshopDescription', translation),
        image: {
          src: webshopSvg,
          alt: 'butikk'
        }
      },
      right: {
        icon: IconType.ChevronRight
      },
      action: {
        onClick: () => navigateFunc(Constants.paths.usePointsWebshopPage)
      }
    } as IImagePlankPrefab)
  )

  return wall
}
