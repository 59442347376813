import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import classNames from 'classnames'
import { animated, useTransition } from '@react-spring/web'
import {
  BaseComponent,
  Button,
  ContentGrid,
  IBaseComponent,
  IComponentTemplate,
  Icon,
  IconButtonTemplate,
  IconType
} from '@fjordkraft/fjordkraft.component.library'
import './Toast.scss'

export interface IToast extends Omit<IBaseComponent, 'template'> {
  id: string
  duration?: number
  lingerTime?: number
  stay?: boolean
  staticToast?: boolean
  content?: {
    top?: any
    middle?: any
    bottom?: any
  }
  closeButtonTemplate?: IComponentTemplate
  onClose?: () => void
  onAnimationComplete?: (id: string) => void
}

const toastId = uuid4()

export const Toast = (props: IToast) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id = toastId,
    className,
    brand,
    theme = 'Light',
    stay = false,
    render = true,
    staticToast = false,
    duration = 1000,
    lingerTime = 1000,
    content,
    closeButtonTemplate = IconButtonTemplate(theme),
    onClose,
    onAnimationComplete
  } = props
  const classPrefix = 'toast-component'

  // ************************************
  // Lifecycle
  // ************************************

  const [show, setShow] = useState<boolean>(true)
  const [renderToast, setRenderToast] = useState<boolean>(render)

  // ************************************
  // Animation
  // ************************************

  const fadeAnim = useTransition(show, {
    from: { opacity: 0, top: 52 },
    enter: { opacity: 1, top: 0 },
    leave: {
      // delay is how long the toast will stay on screen before it starts to animate out.
      opacity: 0,
      top: 52,
      delay: lingerTime,
      onRest: () => {
        // This callback is called when the leave animation is complete.
        // When leave is complete, we want to call the onAnimationComplete callback to cleanup the toast.
        if (!stay) {
          if (onAnimationComplete && id) {
            onAnimationComplete(id)
          }
        }
      }
    },
    onRest: () => {
      // This callback is called for every animation state change.
      // If the toast is not supposed to stay, we change the show state to false to trigger the leave animation.
      if (!stay) {
        setShow(false)
      }
    },
    config: {
      // duration is how long the animation will take to complete.
      duration
    }
  })

  const _renderHeader = () => {
    if (onClose && closeButtonTemplate) {
      return (
        <ContentGrid
          className={`${classPrefix}__header`}
          tagType='nav'
          direction='row'
          alignment='top-right'
          boxSizing='border-box'
          wrap={false}
        >
          {stay && (
            <Button
              action={{
                onClick: () => {
                  if (onClose) {
                    onClose()
                  }
                  setShow(false)
                  setRenderToast(false)
                }
              }}
              template={closeButtonTemplate}
              brand={brand}
            >
              <Icon
                type={IconType.Close}
                width={1}
                height={1}
                brand={brand}
              />
            </Button>
          )}
        </ContentGrid>
      )
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderBaseComponent = () => {
    return (
      <BaseComponent
        {...props}
        className={classNames(`${classPrefix}`, {
          [`${className}`]: className
        })}
      >
        {_renderHeader()}
        {content?.top}
        {content?.middle}
        {content?.bottom}
      </BaseComponent>
    )
  }

  const _animatedToast = () => {
    return fadeAnim(
      (styles, item) =>
        item && (
          <animated.section
            id={toastId}
            className={classNames(classPrefix, {
              [`${classPrefix}--not-interactable`]: !stay,
              [`${className}`]: className
            })}
            style={styles}
          >
            {_renderBaseComponent()}
          </animated.section>
        )
    )
  }

  // ************************************
  // Render
  // ************************************

  if (renderToast) {
    if (staticToast) {
      return _renderBaseComponent()
    } else {
      return _animatedToast()
    }
  } else {
    return <></>
  }
}
