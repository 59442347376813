import { format } from 'date-fns'
import { IDescriptionBlock, IDetailsDescriptionBlock } from '../../../../blocks'
import { ICardPoint, IStatePlank } from '../../../../components'
import { Constants } from '../../../../data'
import {
  IAdditonalAddonService,
  IAddonData,
  IAgreementMinimumTermTypeDefinition,
  ICustomerInstallation,
  ICustomerProductPrice,
  IDataPoint,
  IFuturePrice,
  IProductPrice,
  IServicePage,
  IServicePrices,
  IServiceStatusDescriptions,
  ServiceStatus
} from '../../../../models'
import {
  ITypedResponse,
  createString,
  daysToMonths,
  getClampedStatusFromInstallation,
  getSpecificAddonStateForInstallation,
  getText,
  getTextV2,
  narrowDownResultStatusForPage,
  narrowDownResultStatusForPlank,
  parseActiveExtraProducts,
  tNumber
} from '../../../../services'
import { IServiceBasePageData } from '../ServiceBasePageData'
import { BrandColors, getCounterTheme } from '@fjordkraft/fjordkraft.component.library'
import { getPlankPrefab, MS_ButtonTemplate } from '../../../../Prefabs'
import { typedGetRequest } from '../../../../contexts'

// ************************************
// PUBLIC
// ************************************

export const getCoreDetails = async (
  config: IServiceBasePageData,
  status: ServiceStatus
): Promise<IDetailsDescriptionBlock[]> => {
  const { translations, activeTheme, activeBrand, user, addonStates } = config
  const { installation } = user

  let dynamicInfoBlock: IDescriptionBlock | undefined = undefined
  let detailCards: IDetailsDescriptionBlock[] = []

  if (translations?.serviceDescriptions && translations.serviceDescriptions.length > 0) {
    for (let statusDesc of translations.serviceDescriptions) {
      let desc: IServiceStatusDescriptions | undefined = pointOutStateDescription(statusDesc, status)

      if (desc?.infoBlocks && desc.infoBlocks.length > 0) {
        for (let info of desc.infoBlocks) {
          if (info.blockId === Constants.services.dynamicInfoDetailsId) {
            dynamicInfoBlock = info
            break
          }
        }
      }
    }
  }

  let { points, hasCampaign } = await getCoreDetailPoints(config as IGetCoreDetailPoints, dynamicInfoBlock)
  let campaignPlanks: IStatePlank[] = await _getCampaignPlanks(config)
  let additionalAddonPoints = await getAdditionalAddonServicesPoints(config, status)
  let pointsToUse: ICardPoint[] = points

  if (additionalAddonPoints && additionalAddonPoints.length > 0) {
    pointsToUse = additionalAddonPoints.concat(pointsToUse)
  }

  if (installation) {
    const activeFromDate = getAddonActiveFutureFromDate(installation, addonStates, translations.productDefinitionId)
    if (activeFromDate) {
      pointsToUse.push({
        leftText: getText('activeFrom', translations),
        rightText: activeFromDate
      })
    }
  }

  if (dynamicInfoBlock && pointsToUse) {
    detailCards.push({
      title: dynamicInfoBlock.title,
      description: dynamicInfoBlock.descriptionText,
      cardTheme: hasCampaign ? getCounterTheme(activeTheme) : activeTheme,
      brand: activeBrand,
      points: {
        title: getText('pageTitle', translations),
        points: pointsToUse,
        planks: campaignPlanks
      }
    })
  }

  return detailCards
}

export const getAdditionalAddonServicesPoints = async (
  config: IServiceBasePageData,
  status: ServiceStatus
): Promise<ICardPoint[] | undefined> => {
  const { translations, user } = config
  const { installation } = user

  if (
    translations.serviceAdditionalAddonBlocks &&
    translations.serviceAdditionalAddonBlocks.length > 0 &&
    installation
  ) {
    return await _handleAdditionalAddonPoints(config, status)
  }
}

export const getAddonActiveFutureFromDate = (
  installation: ICustomerInstallation,
  addonStates: any,
  productDefinitionId: string
): string | undefined => {
  const addonState = getSpecificAddonStateForInstallation(productDefinitionId, installation?.meterId, addonStates)

  if (addonState?.state == 'ACTIVE_FUTURE' && addonState?.fromDate) {
    return format(new Date(addonState?.fromDate), 'dd.MM.yyyy')
  }
}

const _handleAdditionalAddonPoints = async (
  config: IServiceBasePageData,
  status: ServiceStatus
): Promise<ICardPoint[]> => {
  const { translations, services, user } = config
  const { installation } = user
  const { GETTYPED } = services

  let points: ICardPoint[] = []

  if (installation) {
    const activeExtraProductIds = parseActiveExtraProducts(installation, config.translations, config.addonStates)

    for (let additional of translations.serviceAdditionalAddonBlocks as IAdditonalAddonService[]) {
      if (additional.serviceId && additional.serviceId !== 'none') {
        let resp = await GETTYPED<IServicePrices>(`Services/prices/${additional.serviceId}/${installation.meterId}`)

        if (resp.data && resp.callState === 'success') {
          const subscribedToAdditionalProduct = activeExtraProductIds?.includes(parseInt(additional.serviceId))
          const subscribedToPrimaryProduct: boolean = narrowDownResultStatusForPlank(status) === 'ACTIVE'

          const customerPrices: ICardPoint[] | undefined = _getCustomerPricePoints(resp.data, config)
          const productPrices: ICardPoint[] | undefined = _getProductPricePoints(resp.data, config)

          const { points: epiPoints, minimumTermsPoints } = _getCombinedEpiServerAndMimimumTermsPoints(
            config.translations,
            additional.dataPoints,
            resp.data.minimumTermDefinitions,
            true
          )
          if (subscribedToPrimaryProduct) {
            if (subscribedToAdditionalProduct) {
              points = points.concat(epiPoints)
              if (customerPrices) {
                points = points.concat(customerPrices)
              }
              if (minimumTermsPoints) {
                points = points.concat(minimumTermsPoints)
              }
            } else {
              // If we are not subscribed to the additional product,
              // but we ARE subscribed to the primary product,
              // we don't show anything related to the additional.
            }
          } else if (!subscribedToPrimaryProduct) {
            points = points.concat(epiPoints)
            if (subscribedToAdditionalProduct) {
              points = points.concat(minimumTermsPoints)
            } else {
              if (productPrices) points = points.concat(productPrices)
              points = points.concat(minimumTermsPoints)
            }
          }
        } else {
          points.push({
            leftText: getText('noDataFound', translations),
            rightText: '',
            customization: { color: BrandColors['status-shade-light-3'] }
          })
        }
      }
    }
  }

  return points
}

const _getCustomerPricePoints = (data: IServicePrices, config: IServiceBasePageData): ICardPoint[] | undefined => {
  if (data?.customerPrices && data.customerPrices.length > 0) {
    let points: ICardPoint[] = []

    for (let value of data.customerPrices) {
      points.push({
        subDetail: true,
        leftText: value.name,
        rightText: `${tNumber(value.vatInclusivePrice, 'no-NO')} ${value.denomination}`
      })
    }

    return points
  }
}

const _getProductPricePoints = (data: IServicePrices, config: IServiceBasePageData): ICardPoint[] | undefined => {
  if (data?.productPrices && data.productPrices.length > 0) {
    let points: ICardPoint[] = []

    for (let value of data.productPrices) {
      points.push({
        subDetail: true,
        leftText: value.name,
        rightText: `${tNumber(value.vatInclusivePrice, 'no-NO')} ${value.denomination}`
      })
    }

    return points
  }
}

/**
 * This function combines EpiServer data points and minimum term definitions to create an array of card points.
 * Minimum term points can both be
 * @param translations - An object containing translation strings
 * @param dataPoints - Optional array of IDataPoint objects from EpiServer
 * @param minimumTerms - Optional array of IAgreementMinimumTermTypeDefinition objects
 * @param subDetail - Boolean flag to indicate if the points are sub-details (default: false)
 * @returns An array of ICardPoint objects
 */
const _getCombinedEpiServerAndMimimumTermsPoints = (
  translations: any,
  dataPoints?: IDataPoint[],
  minimumTerms?: IAgreementMinimumTermTypeDefinition[],
  subDetail: boolean = false
) => {
  let points: ICardPoint[] = []
  let minimumTermsPoints: ICardPoint[] = []

  // Handle minimum term definitions
  if (minimumTerms && minimumTerms.length > 0) {
    for (let term of minimumTerms) {
      if (term.type === 'START') {
        minimumTermsPoints.push({
          id: 'bindingTime',
          subDetail,
          leftText: getText('bindingTime', translations),
          rightText: createString(getText('bindingTimeTermLength', translations), {
            months: daysToMonths(term.termDays),
            days: term.termDays
          }),
          extraLeftText: createString(getText('bindingTimeEnds', translations), {
            date: format(new Date(term.expiresAt), 'dd.MM.yyyy')
          })
        })
      } else if (term.type === 'TERMINATION') {
        minimumTermsPoints.push({
          id: 'cancellationTime',
          subDetail,
          leftText: getText('cancellationTime', translations),
          rightText: createString(getText('cancellationTimeTermLength', translations), {
            months: daysToMonths(term.termDays),
            days: term.termDays
          }),
          extraLeftText: createString(getText('cancellationTimeEnds', translations), {
            date: format(new Date(term.expiresAt), 'dd.MM.yyyy')
          })
        })
      }
      if (term.breachFee) {
        minimumTermsPoints.push({
          id: 'breachFee',
          subDetail,
          leftText: getText('breachFee', translations),
          rightText: `${tNumber(term.breachFee, 'no-NO')} ${term.breachFeeDenomination}`
        })
      }
    }
  }

  // Handle EpiServer data points
  if (dataPoints && dataPoints.length > 0) {
    dataPoints.forEach((point: IDataPoint) => {
      switch (point.key) {
        case 'title':
          points.push({
            leftText: point.name,
            rightText: undefined,
            subDetail
          })
          break
        case 'bindingTime':
        case 'cancellationTime':
        case 'breachFee':
          if (!minimumTermsPoints?.find(term => term.id === point.key)) {
            minimumTermsPoints.push({
              id: point.key,
              leftText: point.name,
              rightText: `${point.amount} ${point.amountSuffix}`,
              subDetail
            })
          }
          break
        default:
          points.push({
            leftText: point.name,
            rightText: `${point.amount ? point.amount + ' ' : ''} ${point.amountSuffix}`,
            subDetail
          })
      }
    })
  }

  // add extra breach fee information text if breachfee is present
  if (minimumTermsPoints?.find(p => p.id === 'breachFee')) {
    const extraBreachFeeInformationText = getTextV2({
      key: 'breachFeeInformation',
      translations: translations,
      includeMissing: false
    })
    if (extraBreachFeeInformationText) {
      minimumTermsPoints.push({
        subDetail,
        extraLeftText: extraBreachFeeInformationText
      })
    }
  }

  return { points: points ?? [], minimumTermsPoints: minimumTermsPoints ?? [] }
}

// ************************************
// PRIVATE
// ************************************

const _getCampaignPlanks = async (config: IServiceBasePageData) => {
  const { translations, addonStates } = config
  let planks: IStatePlank[] = []

  if (addonStates) {
    if (translations.orderMethodType === 'installation') {
      planks = await _getCampaignOnOtherInstallationsPlanks(config)
    }
  }

  return planks
}

const _getCampaignOnOtherInstallationsPlanks = async (config: IServiceBasePageData): Promise<IStatePlank[]> => {
  const { services, translations, user, setInstallation, addonStates = [] } = config
  const { userData } = user
  const { GETTYPED } = services

  let planks: IStatePlank[] = []

  for (let addon of addonStates) {
    if (addon.id === translations.productDefinitionId) {
      for (let inst of userData.installations.filter(inst => inst.meterId !== user.installation?.meterId)) {
        let installationAddonState = narrowDownResultStatusForPage(addon.state[inst.meterId]?.state)
        let resp = await GETTYPED<IServicePrices>(
          `Services/prices/${translations.productDefinitionId}/${inst.meterId}?contentId=${translations.epiContentId}`
        )

        if (installationAddonState && installationAddonState === 'INACTIVE' && resp.data?.campaignEnd) {
          planks.push(
            getPlankPrefab('Action', {
              theme: getCounterTheme(config.activeTheme),
              right: {
                template: MS_ButtonTemplate(config.activeTheme, 'secondary')
              },
              left: {
                title: getText('plankCampaignAvailableTitle', translations),
                description: inst.address.streetAddress
              },
              action: {
                text: getText('plankCampaignAvailableValue', translations),
                onClick: () => {
                  if (setInstallation) {
                    setInstallation(inst)
                  }
                }
              }
            })
          )
        }
      }
    }
  }

  return planks
}

export interface IGetCoreDetailPoints {
  translations: any
  services: {
    GETTYPED: typedGetRequest
  }
  user: {
    installation?: ICustomerInstallation
  }
  addonStates?: IAddonData[]
}

export const getCoreDetailPoints = async (config: IGetCoreDetailPoints, infoBlock?: IDescriptionBlock) => {
  const { translations, services, user } = config
  const { installation } = user
  const { GETTYPED } = services

  let points: ICardPoint[] = []
  let data: IServicePrices | null = null
  let resp: ITypedResponse<IServicePrices> = { callState: 'idle', data: null }
  let hasCampaign: boolean = false

  if (translations.servicePageId !== 'trumf' && translations.servicePageId !== 'forutsigbar' && installation) {
    resp = await GETTYPED<IServicePrices>(
      `Services/prices/${translations.productDefinitionId}/${installation.meterId}?contentId=${translations.epiContentId}`
    )

    points.push({
      id: 'installationAddressPlank',
      leftText: getText('address', translations),
      rightText: installation?.address?.streetAddress ?? ''
    })

    if (resp.callState === 'success' && resp.data) {
      data = resp.data

      let campaignEnd: string | null | undefined = data?.campaignEnd
      hasCampaign = !!campaignEnd

      points = points.concat(
        _getCostPoints(
          translations,
          _getPriceRightTextParsed({
            config,
            data,
            end: campaignEnd,
            installation
          })
        )
      )

      const { points: additionalPoints, minimumTermsPoints } = _getCombinedEpiServerAndMimimumTermsPoints(
        config.translations,
        infoBlock?.dataPoints,
        resp.data.minimumTermDefinitions,
        false
      )

      points = points.concat(additionalPoints)
      points = points.concat(minimumTermsPoints)
    } else if (resp.callState === 'error') {
      points = [
        {
          leftText: getText('noDataFound', translations),
          rightText: '',
          customization: { color: BrandColors['status-shade-light-3'] }
        }
      ]
    }
  }

  return { points, hasCampaign }
}

interface IPriceRightTextParsed {
  config: IGetCoreDetailPoints
  data: IServicePrices | null
  end?: string | null
  installation?: ICustomerInstallation
}

const _getPriceRightTextParsed = (props: IPriceRightTextParsed) => {
  const { config, data, end, installation } = props
  const { translations, addonStates } = config

  let rightText: string = ''
  let campaignEnd: string | undefined = end ?? undefined
  let futureTextRight: string = ''
  let futurePriceStarts: string | undefined = undefined
  let status: 'ACTIVE' | 'INACTIVE' = getClampedStatusFromInstallation({
    installationOnly: translations.orderMethodType === 'installation',
    translations,
    installation,
    addonStates
  })

  if (status === 'ACTIVE' && data?.customerPrices && data.customerPrices.length > 0) {
    data.customerPrices.forEach((p: ICustomerProductPrice) => {
      rightText += `${tNumber(_getParsedCustomerPointPrice(p, end), 'no-NO')} ${p.denomination}\n`

      let { text, from } = _getFuturePriceTexts(p.futurePrices, p.denomination)

      futureTextRight += text
      futurePriceStarts = from
    })
  } else {
    data?.productPrices?.forEach((p: IProductPrice) => {
      rightText += `${tNumber(p.vatInclusivePrice, 'no-NO')} ${p.denomination}\n`

      let { text, from } = _getFuturePriceTexts(p.futurePrices, p.denomination)

      futureTextRight += text
      futurePriceStarts = from
    })
  }

  return { rightText, campaignEnd, futureTextRight, futurePriceStarts }
}

const _getParsedCustomerPointPrice = (priceData: ICustomerProductPrice, ends?: string | null) => {
  if (ends) {
    return priceData.vatInclusiveDiscountedPrice
  } else {
    return priceData.vatInclusivePrice
  }
}

const _getFuturePriceTexts = (futurePrices: IFuturePrice[], denomination: string) => {
  let text: string = ''
  let from: string = ''

  if (futurePrices && futurePrices.length > 0) {
    futurePrices.forEach((fp: IFuturePrice) => {
      text += `${tNumber(fp.vatInclusivePrice, 'no-NO')} ${denomination}\n`
      from = fp.from
    })
  }

  return { text, from }
}
const _getCostPoints = (
  servicePage: IServicePage,
  right: {
    rightText: string
    futureTextRight: string
    campaignEnd?: string
    futurePriceStarts?: string
  },
  isSub: boolean = false
) => {
  const { rightText, futureTextRight, campaignEnd, futurePriceStarts } = right
  let points: ICardPoint[] = []

  // Campaign
  if (campaignEnd) {
    let campaignEndsDate: Date = new Date(campaignEnd)

    points.push({
      subDetail: isSub,
      leftText: getText('campaignPrice', servicePage),
      rightText,
      extraLeftText: createString(getText('campaignPriceEnds', servicePage), {
        date: format(campaignEndsDate, 'dd.MM.yyyy')
      })
    } as ICardPoint)
  }

  // Future price
  if (futurePriceStarts) {
    let futurePriceStartsDate: Date = new Date(futurePriceStarts)

    points.push({
      subDetail: isSub,
      leftText: getText('futurePrice', servicePage),
      rightText: futureTextRight,
      extraLeftText: createString(getText('futurePriceStarts', servicePage), {
        date: format(futurePriceStartsDate, 'dd.MM.yyyy')
      })
    } as ICardPoint)
  }

  // General price
  if (rightText.length > 0 && !campaignEnd) {
    points.push({
      subDetail: isSub,
      leftText: getText('cost', servicePage),
      rightText
    } as ICardPoint)
  }

  return points
}

export const pointOutStateDescription = (desc: IServiceStatusDescriptions, status: ServiceStatus) => {
  switch (status) {
    case 'ACTIVE':
    case 'TERMINATING':
    case 'ACTIVE_FUTURE':
      return desc.status === 'subscribed' ? desc : undefined
    case 'INACTIVE':
    case 'TERMINATED':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
    case 'ACTIVATING':
      return desc.status === 'unsubscribed' ? desc : undefined
  }
}
