import React, { useEffect, useMemo, useState } from 'react'
import {
  ContentGrid,
  getCounterTheme,
  IconType,
  StyleGrid,
  useQueryParams
} from '@fjordkraft/fjordkraft.component.library'
import { Header, Footer, DynamicEpiContentBlock, CustomerServiceInformationBlock, IWebToast } from '../../../blocks'
import {
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationOverlayWrapperContext,
  useApplicationServicehandlerContext,
  useConsentContext
} from '../../../contexts'
import classnames from 'classnames'
import { ConsentPopupModal, CustomerInfoModal, ICustomerInfoModalData } from '../../../modals'
import { Outlet, useLocation } from 'react-router-dom'
import { HomePageData } from '../../../services'
import { GetToastPrefab, MessageToastTemplate, ToastPrefabsType } from '../../../Prefabs'
import {
  ConsentStateEnum,
  ICustomerInstallation,
  IHomePage,
  IOperatingMessage,
  IServicePage,
  MarketingConsentType
} from '../../../models'
import { subDays } from 'date-fns'
import { Constants } from '../../../data'
import { ApplicationUserEditWrapper } from '../../DatahandlerWrappers/ApplicationUserEditWrapper/ApplicationUserEditWrapper'
import { SmoothLoader } from '../../../components'
import { DefaultPageContext } from '../../../contexts/variations/DefaultPageContext'
import { ApplicationReviewWrapper } from '../../DatahandlerWrappers/ApplicationReviewWrapper/ApplicationReviewWrapper'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import './DefaultPageLayout.scss'
import { getAddonStates } from '../../HomePage/HomePageData'
import _ from 'lodash'
import { Toastify } from '../../../components/Toastify/Toastify'

export interface ICustomerInfo {
  firstName: string
  lastName: string
  phone: string
  email: string
  address: {
    streetAddress: string
    postalCode: string
    postalLocation: string
  }
}

export const DefaultPageLayout = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'default-page-layout'
  const { activeBrand, activeTheme, desktopView } = useApplicationContext()
  const { translation, epiChildren, userData, installation, setInstallation, updateCustomerData } =
    useApplicationCoreDataContext()
  const { GET, GETTYPED, customerServiceFeature, error, user } = useApplicationServicehandlerContext()
  const { setToasts } = useApplicationOverlayWrapperContext()
  const { isGuest, setChosenHost, setHostIdForCustomerDataRequests } = useApplicationGuestsAndHostsContext()
  const { search, pathname } = useLocation()
  const queryParams = new URLSearchParams(search)
  const meterIdParam = queryParams.get('meterId')
  const { useNewConsents, consents, showConsentModalForCustomer, pageHasPopup } = useConsentContext()
  const { get: getHostIdParam, clear: clearHostIdParam } = useQueryParams('hostId', false)

  useEffect(() => {
    fetchAddonStates()
  }, [installation])

  // ************************************
  // Extra / Helpers
  // ************************************

  const ScrollIntoAppView = () => {
    let ele = document.getElementById(`${classPrefix}-id`)

    if (ele) {
      ele.scrollIntoView({ behavior: 'auto' })
    }
  }

  const ScrollIntoMainView = () => {
    let ele = document.getElementById(`${classPrefix}-main-id`)

    if (ele) {
      ele.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const shouldShowConsentModal = () => {
    return !!useNewConsents && !!showConsentModalForCustomer && pageHasPopup(pathname) && !isGuest
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [mainContentClamping, setMainContentClamping] = useState<'clamp' | 'stretch' | 'scale'>('clamp')
  const [rootPageData, setRootPageData] = useState<IHomePage>()
  const [customerInfoModal, setCustomerInfoModal] = useState<ICustomerInfoModalData>()
  const [activePageId, setActivePageId] = useState<string>()
  const [contentLoading, setContentLoading] = useState<boolean>(true)
  const [canShowCustomerInfoModal, setCanShowCustomerInfoModal] = useState<boolean>(true)
  const [showConsentModal, setConsentModal] = useState<boolean>(shouldShowConsentModal())
  const [addonStates, setAddonStates] = useState<IServicePage[]>()

  useEffect(() => {
    setConsentModal(shouldShowConsentModal())
  }, [useNewConsents, showConsentModalForCustomer, isGuest])

  useEffect(() => {
    // If the ?hostId= query parameter is set, we automatically set the chosenHost to that id after the page has loaded.
    if (userData && getHostIdParam()) {
      const chosenHost = userData.guestRelationships?.hosts.find(host => host.customerId == getHostIdParam())
      if (chosenHost) {
        setChosenHost(chosenHost)
        setHostIdForCustomerDataRequests(chosenHost.customerId)
      }
      clearHostIdParam()
    }
  }, [])

  useEffect(() => {
    if (userData) {
      if (meterIdParam) {
        _assignInstallationBasedOnUrlParam(meterIdParam, userData.installations)
      } else if ((!meterIdParam && !installation) || isGuest) {
        setInstallation(userData.installations[0])
      }
    }
  }, [meterIdParam, userData, isGuest])

  useEffect(() => {
    if (userData && consents) {
      _checkForCustomerDetailsPopup()
    }
  }, [userData, consents])

  useEffect(() => {
    if (epiChildren) {
      if (!rootPageData) {
        let rootPage: IHomePage = HomePageData(epiChildren)
        setRootPageData(rootPage)
      }
    }
  }, [epiChildren, translation])

  const contextData = useMemo(() => {
    return {
      contentLoading,
      setContentLoading,
      mainContentClamping,
      setMainContentClamping,
      ScrollIntoAppView,
      ScrollIntoMainView
    }
  }, [mainContentClamping, contentLoading])

  useEffect(() => {
    if (translation?.pageId && translation.pageId !== activePageId) {
      setActivePageId(translation.pageId)
      _handleOperatingMessages(translation?.pageId)
    }
  }, [translation, error])

  // ************************************
  // Installasjon assignment
  // ************************************

  const _assignInstallationBasedOnUrlParam = (meterId: string, installations: ICustomerInstallation[]) => {
    if (installations?.length > 0) {
      let inst: ICustomerInstallation | undefined = _.find(installations, (installation: ICustomerInstallation) => {
        return meterId === `${installation?.meterId}`
      })

      if (inst) {
        setInstallation(inst)
      } else if (!inst && !installation) {
        setInstallation(installations[0])
      }
    }
  }

  // ************************************
  // Operating Messages / Driftsmeldinger
  // ************************************

  const _handleOperatingMessages = async (pageId: string) => {
    let resp = await GET(`ServiceMessages/${pageId}`)
    let toasts: IWebToast[] = []

    if (resp.data && resp.data.length > 0 && resp.callState === 'success') {
      resp.data.forEach((message: IOperatingMessage, index: number) => {
        let id = `${message.title}_${message.description}_${index}`

        toasts.push({
          ...{
            stay: true,
            message,
            onClose: () => {
              localStorage.setItem(id, 'staticToast')
            },
            ...GetToastPrefab(ToastPrefabsType.MessageToast, {
              id,
              theme: getCounterTheme(activeTheme),
              brand: activeBrand,
              title: message.title,
              description: message.description,
              icon: IconType.WarningBadge,
              template: MessageToastTemplate(getCounterTheme(activeTheme), desktopView),
              action: message.linkUrl
                ? {
                    link: message.linkUrl,
                    text: message.linkName
                  }
                : undefined
            })
          }
        } as IWebToast)
      })
    }

    setToasts(toasts)
  }

  // ************************************
  // Helpers :: userData Popup
  // ************************************

  const fetchAddonStates = async () => {
    const availableServicePages = await getAddonStates(
      epiChildren,
      customerServiceFeature,
      user,
      GETTYPED,
      GET,
      userData,
      installation
    )
    setAddonStates(availableServicePages)
  }

  const _checkForCustomerDetailsPopup = async (): Promise<void> => {
    if (!userData?.customerId) return
    let lastUpdateDateResp = await GETTYPED<string>(`Customers/email/lastUpdateDate`)

    const oldConsent =
      consents?.find(e => e.consentName === MarketingConsentType.Legacy1)?.value === ConsentStateEnum.ACCEPTED

    let showPopup: boolean = false
    let hasVisitedPreviously: boolean = sessionStorage.getItem('customerEmailVisited') === 'true'

    if (lastUpdateDateResp?.callState && !isGuest) {
      if (lastUpdateDateResp.callState === 'success' && consents?.length) {
        showPopup = _checkTimeSinceLastPopup(lastUpdateDateResp.data)
      } else {
        showPopup = !hasVisitedPreviously
      }

      if (showPopup && oldConsent != undefined && oldConsent != null) {
        setCustomerInfoModal({
          translations: HomePageData(epiChildren),
          consent: oldConsent
        })
      }
    }
  }

  const _checkTimeSinceLastPopup = (date: string | null): boolean => {
    let hasVisitedPreviously: boolean = sessionStorage.getItem('customerEmailVisited') === 'true'

    if (date) {
      let showNextPopup: boolean = false
      let lastDate = new Date(date)
      let nowDate = new Date()
      let check: Date | typeof NaN = subDays(nowDate, Constants.popupFrequencyDays)

      if (isNaN(lastDate.valueOf())) {
        showNextPopup = true
      } else if (isNaN(check.valueOf())) {
        showNextPopup = false
      } else {
        showNextPopup = check > lastDate
      }

      return showNextPopup
    } else {
      return !hasVisitedPreviously
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ApplicationUserEditWrapper>
      <ApplicationReviewWrapper>
        <StyleGrid
          id={`${classPrefix}-id`}
          className={classnames(`${classPrefix}__wrapper`, {
            [`${classPrefix}__wrapper--${activeTheme}`]: activeTheme
          })}
          brand={activeBrand}
          direction='column'
          alignment='top-center'
          gap={2}
          boxSizing='border-box'
          spaceBetween
        >
          {rootPageData && addonStates && (
            <Header
              rootPageData={rootPageData}
              navigating={(path: string) => {
                if (path !== pathname) {
                  setContentLoading(true)
                }
              }}
            />
          )}
          <SmoothLoader loadingState={contentLoading} />
          <DefaultPageContext.Provider value={contextData}>
            <ContentGrid
              id={`${classPrefix}-main-id`}
              className={classnames(classPrefix, {
                [`${classPrefix}--${mainContentClamping}`]: mainContentClamping
              })}
              direction='column'
              alignment='top-center'
              gap={2}
              boxSizing='border-box'
              tagType='main'
            >
              {customerServiceFeature && (
                <CustomerServiceInformationBlock
                  brand={activeBrand}
                  theme={activeTheme}
                />
              )}
              <Outlet />
              {translation?.bottomEditorialContent?.length > 0 && !contentLoading && (
                <DynamicEpiContentBlock
                  className={`${classPrefix}__dynamic-content__bottom`}
                  theme={activeTheme}
                  brand={activeBrand}
                  epiItems={translation.bottomEditorialContent}
                  scaling={mainContentClamping}
                />
              )}
            </ContentGrid>
            {customerInfoModal && userData && canShowCustomerInfoModal && (
              <CustomerInfoModal
                {...customerInfoModal}
                closeModal={async (updateCustomer: boolean, resp) => {
                  setCustomerInfoModal(undefined)

                  sessionStorage.setItem('customerEmailVisited', 'true')

                  if (updateCustomer && resp?.callState === 'success') {
                    updateCustomerData(true)
                    setCanShowCustomerInfoModal(false)
                  }
                }}
              />
            )}
            {showConsentModal && epiChildren['HeadlessConsentPageType']?.data && (
              <ConsentPopupModal
                translations={epiChildren['HeadlessConsentPageType'].data}
                onClose={() => {
                  sessionStorage.setItem(Constants.keys.consentPopupClosed, 'true')
                  setConsentModal(false)
                }}
              />
            )}
          </DefaultPageContext.Provider>
          {rootPageData && <Footer rootPageData={rootPageData} />}
          <Toastify />
        </StyleGrid>
      </ApplicationReviewWrapper>
    </ApplicationUserEditWrapper>
  )
}
