import { GenericToastPrefab, IGenericToastPrefab } from './GenericToastPrefab'
import { MessageToastPrefab, IMessageToastPrefab } from './MessageToastPrefab'
import { StatusToastPrefab, IStatusToastPrefab } from './StatusToastPrefab'
import { IToast } from '../../components/Toaster'

// ************************************
// Interfaces and types
// ************************************

export enum ToastPrefabsType {
  Generic,
  MessageToast,
  StatusToast
}
export type ToastPrefabInterface = IGenericToastPrefab | IMessageToastPrefab | IStatusToastPrefab

// ************************************
// Getter
// ************************************

export const GetToastPrefab = (type: ToastPrefabsType, data: ToastPrefabInterface): IToast => {
  switch (type) {
    case ToastPrefabsType.Generic:
      return GenericToastPrefab(data as IGenericToastPrefab) // Invoice status (paid/unpaid invoice)
    case ToastPrefabsType.MessageToast:
      return MessageToastPrefab(data as IMessageToastPrefab) // Service messages, from servicemessages API-call, black toast with close button
    case ToastPrefabsType.StatusToast:
      return StatusToastPrefab(data as IStatusToastPrefab) // Status on managed power agreement (negative/positive result)
  }
}
