import React from 'react'
import { SubPageLayout } from '../Base/SubPageLayout/SubPageLayout'
import { Outlet } from 'react-router-dom'
import './EnergyConsumptionPage.scss'

export const EnergyConsumptionPage = () => {
  // ************************************
  // Render
  // ************************************

  return (
    <SubPageLayout autoAdjust={false}>
      <Outlet />
    </SubPageLayout>
  )
}
